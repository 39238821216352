import React, { FormEvent, useEffect, useState } from 'react'
import { FormCard } from 'components/form-card/FormCard'
import { Box } from '@mui/material'
import { TextFieldItem } from 'components/form-card/TextFieldItem'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import genericStyle from 'common/utils/generic.module.css'
import { getUserContainer } from 'container/user-module'
import { USER_SERVICE_KEY, IUserService } from 'modules/users'
import { emptyFamiliarData, FamiliarDataDTO, fromModel } from 'modules/users/models/FamiliarDataDTO'
import { ROUTE_MEDICAL_DATA } from 'routes/routes-constants'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

type EditorProps = {
  id?: string
}

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

export function FamiliarDataEditor(props: EditorProps) {
  const { t } = useTranslation()
  const { selectedUserCircle } = useCircleConfiguration()
  const navigate = useNavigate()
  const [familiarData, setFamiliarData] = useState<FamiliarDataDTO>(
    emptyFamiliarData(selectedUserCircle?.user.id ?? '')
  )

  useEffect(() => {
    if (props.id) {
      userService.getInformationByID(props.id).subscribe((res) => {
        if (res) {
          setFamiliarData(fromModel(res))
        }
      })
    }
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setFamiliarData(Object.assign({ ...familiarData }, { [e.target.name]: e.target.value }))

  const saveFamiliarData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const newInformation = Object.assign({ ...familiarData }, { date: new Date() })

    if (props.id) {
      userService.updateInformation(newInformation).subscribe(() => goBack())
    } else {
      userService.addInformation(newInformation).subscribe(() => goBack())
    }
  }

  const goBack = () => navigate(`${ROUTE_MEDICAL_DATA}`)

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <form onSubmit={(e) => saveFamiliarData(e)}>
          <Box mb={3}>
            <TextFieldItem
              field="information"
              value={familiarData.information}
              label={'information'}
              type={'text'}
              handleChange={handleInput}
              rows={10}
              required={true}
            />
          </Box>

          <Box display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={goBack}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={(e) => {
                saveFamiliarData(e)
              }}
            />
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
