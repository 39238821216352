export enum GrowthMonitoringType {
  Weight = 1,
  Height,
  CranialPerimeter,
}

export const growthMonitoringTypes = (): Record<GrowthMonitoringType, string> => ({
  [GrowthMonitoringType.Weight]: 'Weight',
  [GrowthMonitoringType.Height]: 'Height',
  [GrowthMonitoringType.CranialPerimeter]: 'CranialPerimeter',
})

export const growthMonitoringUnitTypes = (): Record<GrowthMonitoringType, string> => ({
  [GrowthMonitoringType.Weight]: 'kg',
  [GrowthMonitoringType.Height]: 'cm',
  [GrowthMonitoringType.CranialPerimeter]: 'cm',
})
