import React from 'react'
import styles from '../../../../patient-data/symptoms/epilepticalCrisis/ModalNewCrisis.module.css'
import style from '../../../add-new-circle/4-forms/Modal.module.css'

interface CheckboxItemProps {
  name: string
  isChecked: boolean
  onCheckChange: () => void
  label: string
}

export const CheckboxItem: React.FC<CheckboxItemProps> = ({
  name,
  isChecked,
  onCheckChange,
  label,
}) => (
  <label className={style.checkboxContainer}>
    <input
      type="checkbox"
      name={name}
      className={styles.check}
      checked={isChecked}
      onChange={onCheckChange}
    />
    {label}
  </label>
)
