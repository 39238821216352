import { v4 as uuidv4 } from 'uuid'
import { AllCalendarEvent } from './AllCalendarEvent'
import { EventCategory } from '../enums/EventCategory'

export interface CrisisDetailsForCalendarDTO {
  epilepticalMood: number
  medication: number
  crisisType: number
  color: string
  observations: string
  comment: string
  duration: number
  zone: number
  awareness: number
  movement: number
  vision: number
  skinColor: number
  symptom: number
  mouth: number
  afterDuration: number
  afterSymptom: string[]
}
export interface AllCalendarEventDTO {
  id: string | undefined
  title: string
  description: string
  value: string
  idIcon: string
  startDate: Date
  finishDate: Date
  startCron: Date
  finishCron: Date
  repeatingUntil: Date | undefined
  repeating: string
  place: string
  eventCategory: EventCategory
  files: string[]
  ownerID: string
  creatorID: string
  users: string[]
  crisisDetails: CrisisDetailsForCalendarDTO
}

export function emptyEventDTO(date: Date | undefined) {
  return {
    id: uuidv4() ?? '',
    title: '',
    description: '',
    value: '',
    idIcon: '',
    startDate: date ?? new Date(),
    finishDate: date ?? new Date(),
    startCron: date ?? new Date(),
    finishCron: date ?? new Date(),
    repeatingUntil: undefined,
    repeating: '',
    place: '',
    eventCategory: EventCategory.Others,
    crisisDetails: undefined,
  }
}

export function fromModel(e: AllCalendarEvent): AllCalendarEventDTO {
  return {
    id: e.id,
    title: e.title,
    description: e.description,
    value: e.value,
    idIcon: e.idIcon,
    startDate: new Date(e.startDate),
    finishDate: new Date(e.finishDate),
    startCron: e.startCron,
    finishCron: e.finishCron,
    repeatingUntil: e.repeatingUntil,
    repeating: e.repeating,
    place: e.place,
    eventCategory: e.eventCategory,
    files: e.files,
    ownerID: e.ownerID,
    creatorID: e.creatorID,
    users: e.users,
    crisisDetails: e.crisisDetails,
  }
}

export function fromArrayModel(e: AllCalendarEvent[]): AllCalendarEventDTO[] {
  return e?.map((e) => fromModel(e))
}

export function toModel(d: AllCalendarEventDTO): AllCalendarEvent {
  return new AllCalendarEvent(d)
}
