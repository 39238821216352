import { Box } from '@mui/material'
import { NotificationsCardPatient } from './notification-card-patient/NotificationsCardPatient'
import { ChatsCard } from './chats-card/ChatsCard'
import { CirclesCard } from './circles-card/CirclesCard'
import { EducationalContentCard } from './educational-content-card/EducationalContentCard'
import { ActiveQueriesCard } from './active-query-card/ActiveQueriesCard'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import { MonthView } from 'features/calendar/MonthView'
import { useCalendarEvents } from 'hooks/calendarEvents/useCalendarEvents'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import { ROUTE_WEARABLES } from 'routes/routes-constants'
import { ACTIVE_PLAN_CIRCLE_ID, EPILEPSY_CIRCLE_ID } from 'common/const'
import { NumericStatsCard } from './numeric-stats-card/NumericStatsCard'
import { EpilepsyCard } from './epilepsy-card/EpilepsyCard'
import { MonitoredActivityCard } from './monitored-activity-card/MonitoredActivityCard'
import { ActivePlanCard } from './active-plan-card/ActivePlanCard'
import { emptyCalendarEvents } from 'common/utils/calendar-events/CalendarEventsContext'
import style from './DashboardPatient.module.css'

export const DashboardPatientMobile = (props: RouteProps) => {
  const { t } = useTranslation()
  const { calendarEvents } = useCalendarEvents()
  const { selectedUserCircle } = useCircleConfiguration()

  const [section, setSection] = useState(-1)
  const [numberPendingChats, setNumberPendingChats] = useState<number>(0)
  const [numberNewResources, setNumberNewResources] = useState<number>(0)

  const navigate = useNavigate()
  const wearables = () => {
    navigate(ROUTE_WEARABLES)
  }
  const vp = emptyCalendarEvents(calendarEvents)

  return (
    <Box px={2} bgcolor={'white'}>
      <DashboardSectionCard label={'calendar'}>
        <MonthView {...vp} />
      </DashboardSectionCard>
      <DashboardSectionCard
        label={'generalSection'}
        onClick={() => setSection(-1)}
        showMore={section !== -1}
      >
        {section === -1 && (
          <Box className={style.sectionList}>
            <AppButton
              label={t('notifications')}
              theme={ButtonTheme.borderOrangeTextBlack}
              handler={(e) => setSection(0)}
              type={'button'}
              fullWidth
            />
            <AppButton
              label={t('yourCircles')}
              theme={ButtonTheme.borderOrangeTextBlack}
              handler={(e) => setSection(1)}
              type={'button'}
              fullWidth
            />
            <AppButton
              label={t('pendingChats')}
              theme={ButtonTheme.borderOrangeTextBlack}
              handler={(e) => setSection(2)}
              type={'button'}
              fullWidth
            />
            <AppButton
              label={t('educationalContent')}
              theme={ButtonTheme.borderOrangeTextBlack}
              handler={(e) => setSection(3)}
              type={'button'}
              fullWidth
            />
            <AppButton
              label={t('activeQuerys')}
              theme={ButtonTheme.borderOrangeTextBlack}
              handler={(e) => setSection(4)}
              type={'button'}
              fullWidth
            />
          </Box>
        )}
        {section === 0 && (
          <NotificationsCardPatient
            ProfessionalNotifications={false}
            SetNumberNewResources={setNumberNewResources}
          />
        )}
        {section === 1 && <CirclesCard />}
        {section === 2 && <ChatsCard setNumberPendingChats={setNumberPendingChats} />}
        {section === 3 && <EducationalContentCard />}
        {section === 4 && <ActiveQueriesCard />}
      </DashboardSectionCard>
      {selectedUserCircle?.circle.id === ACTIVE_PLAN_CIRCLE_ID && (
        <>
          <DashboardSectionCard label={'wearables'} showMore={true} onClick={wearables}>
            <MonitoredActivityCard />
          </DashboardSectionCard>
          <DashboardSectionCard label={'activePlan'} marginTop={50}>
            <ActivePlanCard />
          </DashboardSectionCard>
        </>
      )}

      {selectedUserCircle?.circle.id === EPILEPSY_CIRCLE_ID && (
        <DashboardSectionCard label={'epilepsya'} marginTop={50}>
          <EpilepsyCard />
        </DashboardSectionCard>
      )}

      <NumericStatsCard
        numberPendingChats={numberPendingChats}
        numberNewResources={numberNewResources}
      />
    </Box>
  )
}
