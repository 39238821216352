import greenPainIcon from 'assets/newCircle/green_pain_icon.svg'
import bluePainIcon from 'assets/newCircle/blue_pain_icon.svg'
import darkbluePainIcon from 'assets/newCircle/darkblue_pain_icon.svg'
import yellowPainIcon from 'assets/newCircle/yellow_pain_icon.svg'
import orangePainIcon from 'assets/newCircle/orange_pain_icon.svg'
import redPainIcon from 'assets/newCircle/red_pain_icon.svg'
import bloodPlusIcon from 'assets/newCircle/blood_plus_icon.svg'
import bloodPlusPlusIcon from 'assets/newCircle/blood_plus_plus_icon.svg'
import bloodPlusPlusPlusIcon from 'assets/newCircle/blood_plus_plus_plus_icon.svg'
import pooIcon from 'assets/newCircle/poo_icon.svg'
import bloodScaleIcon from 'assets/newCircle/blood_icon.svg'
import epilepticIcon from 'assets/newCircle/epileptic_icon.svg'
import painScaleIcon from 'assets/newCircle/pain_scale_icon.svg'

export interface SymptomPicture {
  picture: string
  alt: string
}

const icons: SymptomPicture[] = [
  { picture: bloodScaleIcon, alt: 'BloodScale' },
  { picture: epilepticIcon, alt: 'Epilectic' },
  { picture: painScaleIcon, alt: 'PainScale' },
  { picture: pooIcon, alt: 'Poo' },
]

const painScale: SymptomPicture[] = [
  { picture: greenPainIcon, alt: 'Green Pain Scale Icon' },
  { picture: bluePainIcon, alt: 'Blue Pain Scale Icon' },
  { picture: darkbluePainIcon, alt: 'Darkblue Pain Scale Icon' },
  { picture: yellowPainIcon, alt: 'Yellow Pain Scale Icon' },
  { picture: orangePainIcon, alt: 'Orange Pain Scale Icon' },
  { picture: redPainIcon, alt: 'Red Pain Scale Icon' },
]

const bloodScale: SymptomPicture[] = [
  { picture: bloodPlusIcon, alt: 'Blood Plus Icon' },
  { picture: bloodPlusPlusIcon, alt: 'Blood Plus Plus Icon' },
  { picture: bloodPlusPlusPlusIcon, alt: 'Blood Plus Plus Plus Icon' },
]
export const getIconFromAlt = (alt: string): SymptomPicture[] => {
  if (alt === 'PainScale') {
    return painScale
  }
  if (alt === 'BloodScale') {
    return bloodScale
  }

  const returnIcons: SymptomPicture[] = []

  const findIcon = icons.find((icon) => icon.alt === alt)
  if (findIcon) returnIcons.push(findIcon)

  return returnIcons
}

export const getAllSymptomIcons = (): SymptomPicture[] => {
  return icons
}
