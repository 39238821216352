import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { Box, Input } from '@mui/material'
import { Message } from 'modules/messenger/models/Message'
import { File as F } from 'modules/files/models/File'
import Picker from 'emoji-picker-react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import style from './Sender.module.css'
import { AddFile } from '../files/AddFile'
import { createFiles, removeFile } from 'common/utils/file'
import { ListAddedFiles } from '../files/ListAddedFiles'
import { LoadingFile } from 'components/loading-spinner/LoadingSpinner'
import { getUserContainer } from 'container/user-module'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { useIsRelated } from 'hooks/relatedUsers/getRelatedUsers'
import senderIcon from 'assets/buttons/sender.svg'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

type SenderProps = {
  handler: (message: Message, files: F[]) => void
  conversationId: string
}

export function Sender(props: SenderProps): JSX.Element {
  const loggedUser = loggedUserService.get()
  const { t } = useTranslation()
  const [text, setText] = useState<string>('')
  const [files, setFiles] = useState<F[]>([])
  const [openEmojiDialog, setOpenEmojiDialog] = useState<boolean>(false)
  const [messageCurrentPosition, setMessageCurrentPosition] = useState<number>(0)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const { selectedUserCircle } = useCircleConfiguration()

  useEffect(() => {
    if (inputMessage.current !== null) {
      inputMessage.current.focus()
      inputMessage.current.setSelectionRange(messageCurrentPosition, messageCurrentPosition)
    }
  }, [text, messageCurrentPosition])

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, 100)
      })
    }, 500)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const inputMessage = useRef<HTMLTextAreaElement>(null)

  const handlerChangeAttachFile = async (event: ChangeEvent<HTMLInputElement>) => {
    setOpenEmojiDialog(false)
    if (!event.target.files || !loggedUser) {
      return
    }

    const articleId = null
    let messageId = null
    const eventId = null
    const isVisible = true
    let folder = 'mensajeria'
    const isUploaded = false
    const parent = 'Article'
    const userCircleID = selectedUserCircle?.id

    if (props.conversationId) {
      messageId = props.conversationId
      folder = 'mensajeria'
    }

    setIsSending(true)
    const fs = await createFiles(
      event,
      loggedUser.id,
      true,
      folder,
      articleId,
      messageId,
      eventId,
      isVisible,
      isUploaded,
      parent,
      userCircleID
    ).finally(() => {
      setIsSending(false)
    })
    setFiles([...files, ...fs])
  }

  const handlerClickRemoveFile = async (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const fs = await removeFile(event, files, true)
    setFiles(fs)
  }

  const handlerChangeMessage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setText(event.target.value)
    setMessageCurrentPosition(event.target.selectionStart ?? 0)
  }

  const handlerClickKeyUpMessage = (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const position = (event.target as HTMLTextAreaElement).selectionStart
    if (position !== undefined) {
      setMessageCurrentPosition(position)
    }
  }

  const handlerSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (text) {
      createNewMessage(text)
    }

    if (files.length > 0) {
      files.forEach((f) => {
        createNewMessage(`url*${f.id}*${f.name}`)
      })
    }

    setText('')
    setFiles([])
    setMessageCurrentPosition(0)
  }

  function createNewMessage(messageText: string) {
    if (!loggedUser) {
      return
    }
    const newMessage = new Message({
      author: loggedUser.id,
      conversationID: props.conversationId,
      createdAt: new Date(),
      files: [],
      id: uuidv4(),
      isPinned: false,
      message2Users: [],
      parentID: '',
      reactions: [],
      rootID: '',
      text: messageText,
    })
    props.handler(newMessage, files)
  }

  const onEmojiClick = (event: React.MouseEvent<Element, MouseEvent>, emojiObject: any) => {
    setText(
      () =>
        text?.substring(0, messageCurrentPosition) +
        emojiObject.emoji +
        text?.substring(messageCurrentPosition)
    )
    setMessageCurrentPosition(
      text?.substring(0, messageCurrentPosition).length + emojiObject.emoji.length
    )
    setOpenEmojiDialog(false)
  }

  return (
    <>
      <Box
        className={style.divSender}
        display="flex"
        flexDirection="column"
        style={{ visibility: useIsRelated() ? 'visible' : 'hidden' }}
      >
        <Box display="flex">
          <form className={style.formSendMessage} onSubmit={handlerSubmit}>
            <Box className={style.divMessage}>
              <Input
                className={style.inputMessage}
                multiline={true}
                placeholder={t('writeMessage')}
                disableUnderline={true}
                required={files.length === 0}
                value={text}
                inputRef={inputMessage}
                onChange={handlerChangeMessage}
                onClick={handlerClickKeyUpMessage}
                onKeyUp={handlerClickKeyUpMessage}
              />
              {window.innerWidth > 599 && (
                <AddFile
                  handlerChangeAttachFile={handlerChangeAttachFile}
                  lengthFiles={files.length}
                  isOnlyIcon={true}
                />
              )}
              {window.innerWidth < 599 && (
                <Box className={style.rowContainer}>
                  <AddFile
                    handlerChangeAttachFile={handlerChangeAttachFile}
                    lengthFiles={files.length}
                    isOnlyIcon={true}
                  />
                  <Box
                    className={style.divSendFile}
                    display="flex"
                    justifyContent="center"
                    alignItems="stretch"
                    width="10%"
                  >
                    <label className={style.btnSendFile}>
                      <input type="submit" />
                      <img className={style.iconSender} src={senderIcon} />
                    </label>
                  </Box>
                </Box>
              )}
              {window.innerWidth > 599 && (
                <Box
                  className={style.divSendFile}
                  display="flex"
                  justifyContent="center"
                  alignItems="stretch"
                  marginRight="2%"
                >
                  <label className={style.btnSendFile}>
                    <input type="submit" />
                    <img className={style.iconSender} src={senderIcon} />
                  </label>
                </Box>
              )}
            </Box>
          </form>
        </Box>
        {isSending ? (
          <>
            <LoadingFile progress={progress} />
          </>
        ) : (
          <ListAddedFiles
            files={files}
            handlerClickRemoveFile={handlerClickRemoveFile}
            scrollToBottom={true}
          />
        )}
      </Box>
      {openEmojiDialog ? (
        <div className={style.emojiPicker}>
          <Picker onEmojiClick={onEmojiClick} />
        </div>
      ) : null}
    </>
  )
}
