import { Select, MenuItem } from '@mui/material'
import { Role } from '../../modules/users/models/Role'
import { RolesType } from '../../common/enums/RolesType'
import { useTranslation } from 'react-i18next'
import { Participant } from './add-new-circle/1-definition/Participant'

interface CustomSelectProps {
  roles: Role[]
  value: string
  onChange: (value: string, id: number) => void
  index: number
  id?: number
  participant?: Participant[]
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  roles,
  participant,
  value,
  onChange,
  index,
  id,
}) => {
  const { t } = useTranslation()
  return (
    <Select
      MenuProps={{
        MenuListProps: {
          sx: {
            'li.MuiButtonBase-root': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              fontFamily: 'Poppins',
              paddingLeft: '10px',
            },
          },
        },
      }}
      SelectDisplayProps={{
        style: {
          fontFamily: 'Poppins',
        },
      }}
      fullWidth
      variant="outlined"
      value={value}
      style={{ backgroundColor: '#f5f2f2', height: '40px' }}
      onChange={(e) => {
        onChange(e.target.value, id ?? 0)
      }}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {roles &&
        roles.length > 0 &&
        roles.map(
          (role) =>
            role.name !== RolesType.Patient && (
              <MenuItem
                key={role.id}
                value={role.name}
                disabled={participant?.some((participant) => participant.role === role.name)}
              >
                {t(role.name)}
              </MenuItem>
            )
        )}
    </Select>
  )
}

export default CustomSelect
