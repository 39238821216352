import useMediaQuery from '@mui/material/useMediaQuery'
import { RouteProps } from 'react-router-dom'
import { DashboardPatientMobile } from './DashboardPatientMobile'
import { DashboardPatient } from './DashboardPatient'

export const DashboardLayout = (props: RouteProps) => {
  const isSmallScreen = useMediaQuery('(max-width:599px)')

  return (
    <>
      {!isSmallScreen && <DashboardPatient {...props} />}
      {isSmallScreen && <DashboardPatientMobile {...props} />}
    </>
  )
}
