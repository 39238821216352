import { Box, Divider } from '@mui/material'
import style from './CalendarModalList.module.css'
import { useTranslation } from 'react-i18next'
import {
  dateToDateTimeWithoutSecondsString,
  dateToTimeWithoutSecondsString,
} from 'common/utils/date'
import { EventCategory, eventCategoryTypes } from 'modules/calendar/enums/EventCategory'
import { reduceString } from 'common/utils/strings'
import InfoIcon from 'assets/neuropediatria_icon/icono-info.svg'
import { AllCalendarEventDTO } from 'modules/calendar/models/AllCalendarEventDTO'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'

const eventCategories = eventCategoryTypes()

type CalendarModalProps = {
  handleClose: () => void
  events: AllCalendarEventDTO[] | undefined
}

export const CalendarListModal = (props: CalendarModalProps) => {
  const { t } = useTranslation()

  const getCategoryName = (n: number) => {
    const array = Array(Object.values(eventCategories))
    return array[0][n - 1]
  }

  const color = (c: string) => {
    let a
    switch (c) {
      case '#92d6c3':
        a = 'Verde'
        break
      case '#6acafa':
        a = 'Azul'
        break
      case '#ffa2de':
        a = 'Rosa'
        break
      case '#ffd539':
        a = 'Amarillo'
        break
      case '#ffb229':
        a = 'Ámbar'
        break
      case '#ff8543':
        a = 'Naranja'
        break
      case '#d70c0b':
        a = 'Rojo'
        break
      case '#020202':
        a = 'Negro'
        break
    }
    return a
  }

  if (!props.events) {
    return null
  }

  return (
    <Box className={style.modalView}>
      {props.events.map((event) => {
        const hours = `${dateToTimeWithoutSecondsString(event.startDate)} -
  ${dateToTimeWithoutSecondsString(event.finishDate)}`
        return (
          <Box key={event.id}>
            <Box className={style.eventContainer}>
              <Box className={style.labelTitle}>
                {event.title === 'train' ? t(event.title) : event.title}
              </Box>
              {event.eventCategory === EventCategory.EpilepticCrisis && (
                <>
                  <Box>
                    <div className={style.divRow}>
                      <label
                        className={
                          event.crisisDetails.epilepticalMood === 1
                            ? style.dayLabelg
                            : event.crisisDetails.epilepticalMood === 2
                              ? style.dayLabely
                              : event.crisisDetails.epilepticalMood === 3
                                ? style.dayLabelr
                                : style.dayLabelg
                        }
                      >
                        {event.crisisDetails.epilepticalMood === 1
                          ? 'Día bueno'
                          : event.crisisDetails.epilepticalMood === 2
                            ? 'Día regular'
                            : event.crisisDetails.epilepticalMood === 3
                              ? 'Día malo'
                              : ''}
                      </label>
                    </div>
                  </Box>

                  <Box>
                    <Box className={style.rowContainer}>
                      <div className={style.titleLabel}>{t('date')}:</div>
                      <div>
                        {new Date(event.startDate).toLocaleDateString('es', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })}
                      </div>
                    </Box>
                    <Box className={style.rowContainer}>
                      <div className={style.titleLabel}>Medicación de rescate administrada:</div>
                      <div className={style.labelMedication}>{event.crisisDetails.medication}</div>
                    </Box>
                  </Box>

                  <Divider style={{ backgroundColor: '#68b3e0', margin: 8 }} />
                  <Box className={style.divRow}>
                    <div className={style.labelSintomaResponsive}>
                      <div className={style.titleLabel}>{t('medium')} :</div>
                      <div className={style.mediaDetails}>
                        <div
                          className={style.color}
                          style={{ backgroundColor: event.crisisDetails.color }}
                        >
                          {color(event.crisisDetails.color)}
                        </div>
                        <img alt="infoIcon" src={InfoIcon} height={24} width={24} />
                      </div>
                    </div>

                    <div className={style.labelSintomaResponsive}>
                      <div className={style.titleLabel}>Hora inicio:</div>
                      <div className={style.labelResponsive}>
                        {new Date(event.startDate).getHours().toString().padStart(2, '0')}:
                        {new Date(event.startDate).getMinutes().toString().padStart(2, '0')}h
                      </div>
                    </div>

                    <div className={style.labelSintomaResponsive}>
                      <div className={style.titleLabel}>Hora fin:</div>
                      <div className={style.labelResponsive}>
                        {new Date(event.finishDate).getHours().toString().padStart(2, '0')}:
                        {new Date(event.finishDate).getMinutes().toString().padStart(2, '0')}h
                      </div>
                    </div>

                    <div>
                      <strong>Observaciones: </strong>
                      <div>{reduceString(event.crisisDetails.observations, 70)}</div>
                    </div>
                  </Box>
                </>
              )}

              {event.eventCategory !== EventCategory.EpilepticCrisis && (
                <>
                  <Box className={style.rowContainer}>
                    <div className={style.titleLabel}>{t('eventCategory')}</div>
                    <div className={style.label}>
                      {getCategoryName(event.eventCategory) === 'Embarazo'
                        ? 'Cita Medica'
                        : getCategoryName(event.eventCategory)}
                    </div>
                  </Box>
                  <Box className={style.rowContainer}>
                    <div className={style.titleLabel}>{t('Fecha de inicio')}</div>
                    <div className={style.label}>
                      {dateToDateTimeWithoutSecondsString(event.startDate)}
                    </div>
                  </Box>
                  <Box className={style.rowContainer}>
                    <div className={style.titleLabel}>{t('Fecha de fin')}</div>
                    <div className={style.label}>
                      {dateToDateTimeWithoutSecondsString(event.finishDate)}
                    </div>
                  </Box>

                  {event.eventCategory === EventCategory.Others && (
                    <>
                      <Box>
                        <div className={style.titleLabel}>{t('hour')}</div>
                        <div className={style.label}>{hours}</div>
                      </Box>

                      <Box>
                        <div className={style.titleLabel}>{t('place')}</div>
                        <div className={style.label}>{event.place}</div>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
        )
      })}
      <div className={style.actionBtns}>
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'submit'}
          label={t('close')}
          handler={props.handleClose}
        />
      </div>
    </Box>
  )
}
