import { v4 as uuidv4 } from 'uuid'
import { File as F } from 'modules/files/models/File'
import React, { ChangeEvent } from 'react'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { fromModel } from 'modules/files/models/FileDTO'
import { getSettingContainer } from 'container/setting-module'
import { SettingService } from 'modules/settings/services/SettingService'
import { SETTING_SERVICE_KEY } from 'modules/settings'
import { SettingType } from 'modules/settings/enums/SettingType'
import { Query, QueryParam } from '../api/Query'
import { SettingQuery } from 'modules/settings/models/Setting'
import { FILE_VALID_EXTENSIONS, UNITS_SIZE } from 'common/const'

const filesContainer = getFileContainer()
const filesService = filesContainer.get<FileService>(FILE_SERVICE_KEY)

const settingContainer = getSettingContainer()
const settingService = settingContainer.get<SettingService>(SETTING_SERVICE_KEY)

export async function createFiles(
  event: ChangeEvent<HTMLInputElement>,
  userID: string,
  uploadFileDirectly: boolean,
  destinationFolder: string,
  articleId: string | null,
  messageId: string | null,
  eventId: string | null,
  isVisible: boolean | true,
  isUploaded: boolean | false,
  parent?: string | null,
  userCircleID?: string | undefined,
  circles?: string
): Promise<F[]> {
  const fs: F[] = []
  const fileList = event.target.files

  const res = await settingService
    .getFilteredItems(
      new Query<SettingQuery>({
        query: [new QueryParam<SettingQuery>('settingType', SettingType.File)],
      })
    )
    .toPromise()
  let maxSize = 0
  res && res.length > 0 && (maxSize = res[0].maxValue)

  if (fileList !== null) {
    for (let i = 0; i < fileList.length; i++) {
      const data64 = btoa(
        new Uint8Array(await fileList[i].arrayBuffer()).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      )

      const destinationFolderAlt =
        destinationFolder === 'Template' || destinationFolder === 'Tutorial'

      const newFile = new F({
        specialities: destinationFolderAlt ? (articleId ?? '') : '',
        id: uuidv4(),
        data: data64,
        name: fileList[i].name,
        extension: fileList[i].name.split('.').pop() ?? '',
        basePath: destinationFolder,
        size: fileList[i].size,
        mimeType: fileList[i].type,
        createdAt: new Date(),
        miniPreview: '',
        order: i + 1,
        creator: userID,
        userCircleID: userCircleID!, // check this out later,
        isSystem: destinationFolderAlt ? undefined : destinationFolder,
        articleId: destinationFolderAlt ? null : (articleId ?? null),
        messageId: messageId ?? null,
        eventId: destinationFolderAlt ? null : (eventId ?? null),
        isVisible,
        isUploaded,
        createdByDoctor: false,
        target: destinationFolderAlt ? destinationFolder : undefined,
        circles: circles ?? '',
        parent,
      })

      if (newFile.size > maxSize) {
        const readableSize = formatSize(maxSize.toString())
        alert('El tamaño maximo de fichero es de: ' + readableSize)
        return fs
      }
      if (!FILE_VALID_EXTENSIONS.includes(newFile.extension)) {
        alert('La extensión no es compatible.')
        return fs
      }

      if (uploadFileDirectly) {
        const uploadFile = fromModel(newFile)
        const newFileUploaded = await filesService
          .add(uploadFile)
          .toPromise()
          .then((res) => {
            if (!res) alert('El fichero no es compatible.')
            return res
          })
        if (newFileUploaded) fs.push(newFileUploaded)
      } else {
        fs.push(newFile)
      }
    }
  }

  event.target.value = ''

  return fs
}

function formatSize(x: string) {
  let l = 0
  let n = parseInt(x, 10) || 0
  while (n >= 1024 && ++l) {
    n = n / 1024
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + UNITS_SIZE[l]
}

export async function removeFile(
  event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  files: F[],
  removeFileDirectly: boolean
) {
  const fileId = event.currentTarget.id
  const fileToRemove = await filesService.getByID(fileId).toPromise()
  const fsCopy = [...files]

  if (fileToRemove && removeFileDirectly) {
    const res = await filesService.delete(fileToRemove).toPromise()
    if (res) {
      fsCopy.splice(
        fsCopy.findIndex((f) => f.id === fileToRemove.id),
        1
      )
    }
  } else {
    fsCopy.splice(
      fsCopy.findIndex((f) => f.id === fileId),
      1
    )
  }

  return fsCopy
}

export function viewFile(file: F) {
  const f = atob(file.data)
  const length = f.length
  const ab = new ArrayBuffer(length)
  const ua = new Uint8Array(ab)
  for (let i = 0; i < length; i++) {
    ua[i] = f.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: file.mimeType })

  /* if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, file.name)
    return
  } */

  const blobURL = URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = blobURL
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL)
  }, 100)
}

export async function viewImageFile(file: F) {
  const f = atob(file.data)
  const length = f.length
  const ab = new ArrayBuffer(length)
  const ua = new Uint8Array(ab)
  for (let i = 0; i < length; i++) {
    ua[i] = f.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: file.mimeType })
  const blobURL = URL.createObjectURL(blob)

  return blobURL
}

export function downloadFile(name: string, mimeType: string, data: string) {
  const f = atob(data)
  const length = f.length
  const ab = new ArrayBuffer(length)
  const ua = new Uint8Array(ab)
  for (let i = 0; i < length; i++) {
    ua[i] = f.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeType })

  /* if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, name)
    return
  } */
  const blobURL = URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = blobURL
  link.download = name
  link.setAttribute('download', name)

  if (!link.download) {
    link.setAttribute('target', '_blank')
  }
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL)
  }, 100)
}

export function getFile(name: string, mimeType: string, data: string) {
  const f = atob(data)
  const length = f.length
  const ab = new ArrayBuffer(length)
  const ua = new Uint8Array(ab)
  for (let i = 0; i < length; i++) {
    ua[i] = f.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeType })

  const blobURL = URL.createObjectURL(blob)
  // const link = document.createElement('a')

  // link.href = blobURL
  // link.download = name
  // link.setAttribute('download', name)
  //
  // if (!link.download) {
  //   link.setAttribute('target', '_blank')
  // }
  // document.body.appendChild(link)
  // link.click()
  // document.body.removeChild(link)
  //
  // setTimeout(() => {
  //   window.URL.revokeObjectURL(blobURL)
  // }, 100)
  return blobURL
}
