import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { FormEvent, useEffect, useMemo } from 'react'
import { Conversation } from 'modules/messenger/models/Conversation'
import close from 'assets/esfera/buttons/delete.svg'
import { Header } from 'components/header/Header'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import style from './ModalTeleconsultationForm.module.css'
import TextField from '@mui/material/TextField'

type ModalEpilepticCrisisProps = {
  conversation?: Conversation
  handleCloseEpilepticCrisisModal: () => void
  saveConversation: (e: FormEvent<HTMLFormElement>) => void
  message: string
  handleMessage: (me: string) => void
  handleObject: (obj: ObjectForm) => void
  object: ObjectForm
  errorMessage: string
}

interface ObjectForm {
  whatHappen: string[]
  currentTreatment: string
  timeWithoutCrisis: string
  symptom: string[]
  typeCrisis: string
  durationCrisis: string
  healthcare: string
  medicationAdministered: string
  timeWithoutAwareness: string
  nextAppointment: Date | null
  dontKnowAppointment: string
}

export const ModalTeleconsultationForm = (props: ModalEpilepticCrisisProps): JSX.Element => {
  const { t } = useTranslation()
  const [firstRadioButton, setFirstRadioButton] = React.useState<string>()
  const [secondRadioButton, setSecondRadioButton] = React.useState<string>()
  const [thirdQuestionText, setThirdQuestionText] = React.useState<string>()
  const [thirdRadioButton, setThirdRadioButton] = React.useState<string>()
  const [fourthRadioButton, setFourthRadioButton] = React.useState<string>()
  const [textFourthQuestion, setTextFourthQuestion] = React.useState<string>()
  const [textFifthQuestion, setTextFifthQuestion] = React.useState<string>()

  const isValid = useMemo(
    () =>
      ((firstRadioButton === 'Sí' && Boolean(secondRadioButton)) || firstRadioButton === 'No') &&
      Boolean(thirdQuestionText) &&
      ((thirdRadioButton === 'Sí' && Boolean(textFourthQuestion)) || thirdRadioButton === 'No') &&
      Boolean(fourthRadioButton) &&
      Boolean(textFifthQuestion),
    [
      firstRadioButton,
      secondRadioButton,
      thirdQuestionText,
      thirdRadioButton,
      fourthRadioButton,
      textFourthQuestion,
      textFifthQuestion,
    ]
  )

  useEffect(() => {
    if (firstRadioButton === 'No') {
      setSecondRadioButton(undefined)
    }
    if (thirdRadioButton === 'No') {
      setTextFourthQuestion(undefined)
    }
  }, [firstRadioButton, thirdRadioButton])

  const handleSaveMessage = () => {
    let messageString = ''

    // Primera pregunta
    messageString += '<b>' + t('anyCrisesSinceYourLastVisit?') + ': </b>'
    messageString += firstRadioButton + '\n'

    // Segunda pregunta
    if (firstRadioButton === 'Sí') {
      messageString += '<b>' + t('haveYouCollectedInApplicationCalendar?') + ': </b>'
      messageString += secondRadioButton + '\n'
    }

    // Tercera pregunta
    messageString += '<b>' + t('whatMedicationAreYoucurrentlyOn?') + ': </b>'
    messageString += thirdQuestionText + '\n'

    // Cuarta pregunta
    messageString += '<b>' + t('haveYouHadAnyProblemsWithMedication?') + ': </b>'
    if (thirdRadioButton === 'Sí') {
      messageString += 'Si, ' + textFourthQuestion + '\n'
    } else {
      messageString += thirdRadioButton + '\n'
    }

    // Quinta pregunta pregunta
    messageString += '<b>' + t('haveYouHadBloodTest?') + ': </b>'
    messageString += fourthRadioButton + '\n'

    // Quinta pregunta
    messageString +=
      '<b>' + t('doYouHaveQuestionsOrNeedToDiscussSomethingWithYourDoctor?') + ': </b>'
    messageString += textFifthQuestion + '\n'

    props.handleMessage(messageString)
  }

  return (
    <Box className={style.newConversationContainer}>
      <Box className={style.headerBox}>
        <Header
          label={t(props.conversation ? 'editConversation' : 'teleconsultHeader')}
          icon={close}
          onClick={props.handleCloseEpilepticCrisisModal}
        />
      </Box>
      <form onSubmit={props.saveConversation} className={style.formContainer}>
        <Box className={style.boxAnswer}>
          <h3 className={style.titleForm}>{t('anyCrisesSinceYourLastVisit?')}*</h3>
          <RadioGroup
            className={style.radioGroup}
            aria-label="gender"
            name="gender1"
            value={firstRadioButton}
            onChange={(e) => {
              setFirstRadioButton(e.target.value)
            }}
          >
            <FormControlLabel
              className={style.label}
              value={t('Sí')}
              control={<Radio color="secondary" />}
              label={t('Sí')}
            />
            <FormControlLabel
              className={style.label}
              value={t('No')}
              control={<Radio color="secondary" />}
              label={t('No')}
            />
          </RadioGroup>
        </Box>
        {firstRadioButton === 'Sí' && (
          <Box className={style.boxAnswer}>
            <h3 className={style.titleForm}>{t('haveYouCollectedInApplicationCalendar?')}*</h3>
            <RadioGroup
              className={style.radioGroup}
              aria-label="gender"
              name="gender1"
              value={secondRadioButton}
              onChange={(e) => {
                setSecondRadioButton(e.target.value)
              }}
            >
              <FormControlLabel
                className={style.label}
                value={t('Sí')}
                control={<Radio color="secondary" />}
                label={t('Sí')}
              />
              <FormControlLabel
                className={style.label}
                value={t('No')}
                control={<Radio color="secondary" />}
                label={t('No')}
              />
            </RadioGroup>
          </Box>
        )}

        <Box className={style.boxAnswer}>
          <h3 className={style.titleForm}>{t('whatMedicationAreYoucurrentlyOn?')}*</h3>
          <TextField
            className={style.field}
            defaultValue={undefined}
            type="text"
            value={thirdQuestionText}
            InputProps={{
              inputProps: { min: 1 },
            }}
            onChange={(e) => {
              setThirdQuestionText(e.target.value)
            }}
            id="outlined-basic"
            variant="outlined"
            size={'medium'}
          />
        </Box>

        <Box className={style.boxAnswer}>
          <h3 className={style.titleForm}>{t('haveYouHadAnyProblemsWithMedication?')}*</h3>
          <RadioGroup
            className={style.radioGroup}
            aria-label="gender"
            name="gender1"
            value={thirdRadioButton}
            onChange={(e) => {
              setThirdRadioButton(e.target.value)
            }}
          >
            <FormControlLabel
              className={style.label}
              value={t('Sí')}
              control={<Radio color="secondary" />}
              label={t('Sí')}
            />
            <FormControlLabel
              className={style.label}
              value={t('No')}
              control={<Radio color="secondary" />}
              label={t('No')}
            />
          </RadioGroup>
        </Box>

        {thirdRadioButton === 'Sí' && (
          <Box className={style.boxAnswer}>
            <TextField
              className={style.field}
              defaultValue={undefined}
              type="text"
              value={textFourthQuestion}
              InputProps={{
                inputProps: { min: 1 },
              }}
              onChange={(e) => {
                setTextFourthQuestion(e.target.value)
              }}
              id="outlined-basic"
              variant="outlined"
              size={'medium'}
            />
          </Box>
        )}

        <Box className={style.boxAnswer}>
          <h3 className={style.titleForm}>{t('haveYouHadBloodTest?')}*</h3>
          <RadioGroup
            className={style.radioGroup}
            aria-label="gender"
            name="gender1"
            value={fourthRadioButton}
            onChange={(e) => {
              setFourthRadioButton(e.target.value)
            }}
          >
            <FormControlLabel
              className={style.label}
              value={t('Sí')}
              control={<Radio color="secondary" />}
              label={t('Sí')}
            />
            <FormControlLabel
              className={style.label}
              value={t('No')}
              control={<Radio color="secondary" />}
              label={t('No')}
            />
          </RadioGroup>
        </Box>

        <Box className={style.boxAnswer}>
          <h3>{t('doYouHaveQuestionsOrNeedToDiscussSomethingWithYourDoctor?')}*</h3>
          <TextField
            className={style.field}
            defaultValue={undefined}
            type="text"
            value={textFifthQuestion}
            InputProps={{
              inputProps: { min: 1 },
            }}
            onChange={(e) => {
              setTextFifthQuestion(e.target.value)
            }}
            id="outlined-basic"
            variant="outlined"
            size={'medium'}
          />
        </Box>

        <Box className={style.actionBtns}>
          <AppButton
            label={t('save')}
            type={'submit'}
            disabled={!isValid}
            theme={ButtonTheme.newEvent}
            handler={(e) => {
              e.preventDefault()
              props.handleCloseEpilepticCrisisModal()
              handleSaveMessage()
            }}
          />
        </Box>
      </form>
    </Box>
  )
}
