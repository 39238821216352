import { createContext, useContext } from 'react'
import { Role } from '../../modules/users/models/Role'

export type GlobalContent = {
  role: Role[]
  setRole: (r: Role[]) => void
}

export const GlobalRoleContext = createContext<GlobalContent>({
  role: [], // set a default value
  setRole: () => {},
})

export const useGlobalContext = () => {
  const context = useContext(GlobalRoleContext)
  if (context === undefined) {
    throw new Error('useGlobalRoleContext must be used within a GlobalRoleContextProvider')
  }
  return context
}
