import { Box, useMediaQuery } from '@mui/material'
import {
  ROUTE_DENTIST_CONFIG,
  ROUTE_NOTIFICATIONS_CONFIG,
  ROUTE_PATIENT_CALENDAR_CONFIG,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_QUERY_CONFIG,
  ROUTE_SPORTCENTRE_CONFIG,
  ROUTE_CITY_HALL_CONFIG,
  ROUTE_LEGAL_ADVICE,
} from 'routes/routes-constants'
import notifications from 'assets/configuration/notifications.svg'
import changePassword from 'assets/configuration/changePassword.svg'
import patientData from 'assets/left_menu/circulos.svg'
import calendar from 'assets/left_menu/calendar.svg'
import legalAdvice from 'assets/configuration/legalAdvice.svg'
import consulta from 'assets/left_menu/icono-consulta.svg'
import { useTranslation } from 'react-i18next'
import { version } from '../../../package.json'
import style from './Configuration.module.css'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { Permission } from 'common/permission'
import { ConfigurationItem } from './ConfigurationItem'

export type ConfigurationProps = {}
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function Configuration(props: ConfigurationProps): JSX.Element {
  const { t } = useTranslation()
  const externalPass = process.env.REACT_APP_EXTERNAL_PASS
  const isMobile = useMediaQuery('(max-width: 768px)')

  const items = [
    {
      label: 'Cambiar contraseña',
      route: ROUTE_CHANGE_PASSWORD,
      show: externalPass === 'true',
      img: changePassword,
      separator: true,
    },
    {
      label: t('notificationsConfig'),
      route: ROUTE_NOTIFICATIONS_CONFIG,
      show: loggedUserService.userCan(Permission.configureNotifications),
      img: notifications,
      separator: true,
    },
    {
      label: t('patientCalendarConfig'),
      route: ROUTE_PATIENT_CALENDAR_CONFIG,
      show: loggedUserService.userCan(Permission.configurePatientCalendar),
      img: calendar,
      separator: true,
    },
    {
      label: t('dentistConfig'),
      route: ROUTE_DENTIST_CONFIG,
      show: loggedUserService.userCan(Permission.configureDentists),
      img: patientData,
      separator: true,
    },
    {
      label: t('configQuery'),
      route: ROUTE_QUERY_CONFIG,
      show: loggedUserService.userCan(Permission.configurePatientCalendar),
      img: consulta,
      separator: true,
    },
    {
      label: t('sportCentreConfig'),
      route: ROUTE_SPORTCENTRE_CONFIG,
      show: loggedUserService.userCan(Permission.configActivePlans),
      img: consulta,
      separator: true,
    },
    {
      label: t('cityHallConfig'),
      route: ROUTE_CITY_HALL_CONFIG,
      show: loggedUserService.userCan(Permission.configActivePlans),
      img: consulta,
      separator: true,
    },
    {
      label: t('legalAdvice'),
      route: ROUTE_LEGAL_ADVICE,
      show: true,
      img: legalAdvice,
      separator: true,
    },
  ]

  return (
    <Box className={style.mainContainer}>
      <Box className={style.configurationList}>
        {items
          .filter((f) => f.show)
          .map((item, i) => {
            return (
              <ConfigurationItem
                key={i}
                isMobile={isMobile}
                imgSrc={item.img}
                label={t(item.label)}
                route={item.route}
                addSeparator={item.separator}
              />
            )
          })}
        {!isMobile && <h3 className={style.version}>Version {version}</h3>}
      </Box>
    </Box>
  )
}
