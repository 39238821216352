import { Message } from '../../messenger_consulta/models/Message'
import { ClosedStatus } from '../enums/ClosedStatus'

export type TeleConsultaProps = {
  id: string | undefined
  name: string
  description: string
  patient: string
  professional: string
  messages: Message[] | undefined
  unreadMessages: number
  closed: ClosedStatus
  userCircleID: string
  date: Date
  userGender: TeleConsultaUserGender
}

export enum TeleConsultaUserGender {
  female = 1,
  male = 2,
}

export class TeleConsulta {
  private readonly _id: string | undefined
  private _name: string
  private _description: string
  private _patient: string
  private _professional: string
  private _messages: Message[]
  private _unreadMessages: number
  private _closed: ClosedStatus
  private _circleID: string | undefined
  private _userGender: TeleConsultaUserGender
  private _userCircleID: string
  private _date: Date

  constructor(p: TeleConsultaProps) {
    this._id = p.id
    this._name = p.name
    this._description = p.description
    this._professional = p.professional
    this._patient = p.patient
    this._messages = p.messages ?? []
    this._unreadMessages = p.unreadMessages
    this._closed = p.closed
    this._circleID = p.userCircleID
    this._userCircleID = p.userCircleID
    this._date = p.date
    this._userGender = p.userGender
  }

  get id(): string | undefined {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get description(): string {
    return this._description
  }

  set description(value: string) {
    this._description = value
  }

  get patient(): string {
    return this._patient
  }

  set patient(value: string) {
    this._patient = value
  }

  get professional(): string {
    return this._professional
  }

  set professional(value: string) {
    this._professional = value
  }

  get messages(): Message[] {
    if (this._messages) {
      return [...this._messages]
    }

    return this._messages
  }

  addMessage(value: Message) {
    if (this._messages) {
      this._messages.push(value)
    } else {
      this._messages = [value]
    }
  }

  removeMessage(id: string) {
    this._messages = this._messages.filter((m) => m.id !== id)
  }

  get unreadMessages(): number {
    return this._unreadMessages
  }

  set unreadMessages(value: number) {
    this._unreadMessages = value
  }

  get closed(): ClosedStatus {
    return this._closed
  }

  set closed(value: ClosedStatus) {
    this._closed = value
  }

  get userCircleID(): string {
    return this._userCircleID
  }

  set userCircleID(value: string) {
    this._userCircleID = value
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }

  get userGender(): TeleConsultaUserGender {
    return this._userGender
  }

  set userGender(value: TeleConsultaUserGender) {
    this._userGender = value
  }
}

/*
export class TeleConsultaQuery extends Query<TeleConsulta> {
    userID: string
    circleID: string
    closed : ClosedStatus

}
*/
