import {
  Alert,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProfessionalType } from 'modules/users/models/ProfessionalType'
import style from './ConfigResource.module.css'
import { LoggedUser } from 'modules/users/models/LoggedUser'
import { FileDTO } from 'modules/files/models/FileDTO'
import { CircleDTO } from 'modules/circle/models/CircleDTO'
import { MANAGER_TYPES } from 'common/const'
import { Roles } from 'modules/users/enums/Roles'

type ConfigResourceSectionProps = {
  resource: FileDTO
  pathologies: CircleDTO[]
  propsId: string | undefined
  professionalType: ProfessionalType | undefined
  professionalSpecialities: ProfessionalType[]
  currentCirclePathology: string[] | undefined
  loggedUser: LoggedUser | undefined
  circles: CircleDTO[]
  handleSelectPathology: (e: string) => void
  handleDeletePathology: (type: string, p: string) => void
  handleSelectCircle: (event: SelectChangeEvent<string[]>) => void
  preview?: boolean
  weekDayRecovered?: string[]
  isFormEdit?: boolean
}

export function ConfigResourceSection({
  professionalType,
  professionalSpecialities,
  handleSelectPathology,
  handleDeletePathology,
  currentCirclePathology,
  loggedUser,
  circles,
  handleSelectCircle,
}: ConfigResourceSectionProps) {
  const { t } = useTranslation()
  const isExternProfessional = loggedUser?.roles?.some((role) => role === Roles.ProfessionalExtern)
  const isCaseManager = MANAGER_TYPES.some((role) => loggedUser?.roles?.includes(role))

  return (
    <Card className={style.cardMain}>
      {!isExternProfessional && (
        <CardContent className={style.cardContent}>
          <Box className={style.boxContainer}>
            <p className={style.titleConfig}>{t('selectSpecialty')}</p>
            <Divider className={style.divider} />
            <Box className={style.boxSelect}>
              <Select
                MenuProps={{
                  MenuListProps: {
                    sx: {
                      'li.MuiButtonBase-root': {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        fontFamily: 'Poppins',
                        paddingLeft: '10px',
                      },
                    },
                  },
                }}
                SelectDisplayProps={{
                  style: {
                    paddingLeft: '60px',
                    fontFamily: 'Poppins',
                  },
                }}
                fullWidth
                variant="outlined"
                className={style.select}
                inputProps={{ 'aria-label': 'Without label' }}
                disabled={!isCaseManager}
                value={
                  !isCaseManager
                    ? professionalSpecialities.length > 0
                      ? professionalSpecialities[0].name
                      : ''
                    : (professionalType?.name ?? '')
                }
                onChange={(e) => handleSelectPathology(e.target.value)}
              >
                {professionalSpecialities.length > 0 &&
                  professionalSpecialities.map((k) => (
                    <MenuItem key={k.id} value={k.name}>
                      {k.name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            {!isCaseManager && professionalSpecialities.length === 0 && (
              <Alert className={style.alertError} severity="error">
                {t('selectSpecialtyError')}
              </Alert>
            )}
          </Box>
          <Box className={style.boxContainer}>
            {professionalType && circles.length > 0 && (
              <>
                <p className={style.titleConfig}>{t('selectCircle')}</p>
                <Divider className={style.divider} />
                <Box className={style.boxSelect}>
                  <Select
                    MenuProps={{
                      MenuListProps: {
                        sx: {
                          'li.MuiButtonBase-root': {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            fontFamily: 'Poppins',
                            paddingLeft: '10px',
                          },
                        },
                      },
                    }}
                    SelectDisplayProps={{
                      style: {
                        paddingLeft: '20px',
                        fontFamily: 'Poppins',
                      },
                    }}
                    fullWidth
                    multiple
                    variant="outlined"
                    className={style.select}
                    value={currentCirclePathology ?? []}
                    onChange={handleSelectCircle}
                  >
                    {Object.values(circles).map((k) => (
                      <MenuItem key={k.id} value={k.name}>
                        {k.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </>
            )}
          </Box>
          <Box className={style.chipList}>
            {currentCirclePathology &&
              currentCirclePathology.length > 0 &&
              currentCirclePathology
                .filter((f) => f.length > 0)
                .map((p, index) => (
                  <Chip
                    key={p + index}
                    sx={{
                      '& .MuiChip-deleteIcon': {
                        color: '#fff',
                      },
                    }}
                    className={style.chip}
                    label={<span className={style.chipText}>{p}</span>}
                    onDelete={() => handleDeletePathology('circle', p)}
                  />
                ))}
          </Box>
        </CardContent>
      )}
    </Card>
  )
}
