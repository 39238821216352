import { RouteProps } from '../../routes/AppRouter'
import { Circles as C } from '../../features/circles'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function Circles(props: RouteProps) {
  return (
    <>
      <CurrentNavHeaderEsfera title={props.title ?? ''} />
      <C />
    </>
  )
}
