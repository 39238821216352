import { Alert, Box, Modal, TextField, Checkbox, Select, MenuItem, Grid } from '@mui/material'
import React, { useEffect, useState, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import exitModdal from 'assets/newCircle/exit_icon.svg'
import style from './ModalAddtreatments.module.css'
import { TextFieldItem } from 'components/form-card/TextFieldItem'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { getPatientDataContainer } from 'container/patient-data-module'
import { TREATMENT_SERVICE_KEY } from 'modules/patient-data'
import { TreatmentService } from 'modules/patient-data/services/TreatmentService'
import {
  emptyTreatmentDTO,
  fromModel,
  TreatmentDTO,
} from 'modules/patient-data/models/TreatmentDTO'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { TreatmentAdministration } from 'modules/patient-data/models/Treatment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import checkboxCuadrado from 'assets/newCircle/checkbox-cuadrado.svg'
import checkBoxChecked from 'assets/newCircle/checkbox-cuadrado-ok.svg'
import checkBoxDeshabilitado from 'assets/newCircle/checkbox-deshabilitado.svg'
import { emptyList, ItemList } from 'common/models/ItemList'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { UserConfig } from 'modules/notifications/models/UserConfig'
import { getNotificationContainer } from 'container/notification-module'
import { UserConfigService } from 'modules/notifications/services/UserConfigService'
import { USER_CONFIG_SERVICE_KEY } from 'modules/notifications'
import { SourceType } from 'modules/notifications/enums/SourceType'
import { TransportType } from 'common/enums/TransportType'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

enum TreatmentsErrors {
  NO_NAME = 'treatmentsMustHaveAName',
  INVALID_DURATION = 'invalidTreatmentDuration',
  INVALID_FREQUENCY = 'invalidTreatmentFrequency',
  INVALID_DOSE = 'invalidTreatmentDose',
  INVALID_DOSE_UNIT = 'invalidTreatmentDoseUnit',
  INVALID_DATE = 'invalidTreatmentDates',
  INVALIDADMINISTRATIONMETHOD = 'invalidAdministrationMethod',
}

type TreatmentsEditorProps = {
  id?: string
  open: boolean
  onClose: () => void
}

type ReminderType = {
  name: string
  value: number
}

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const patientDataContainer = getPatientDataContainer()
const treatmentService = patientDataContainer.get<TreatmentService>(TREATMENT_SERVICE_KEY)
const userConfigService = getNotificationContainer().get<UserConfigService>(USER_CONFIG_SERVICE_KEY)

export const ModalAddTreatments = (props: TreatmentsEditorProps) => {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()
  const { selectedUserCircle } = useCircleConfiguration()

  const [startDateError, setStartDateError] = useState<string>('')
  const [endDateError, setEndDateError] = useState<string>('')

  const [errorMessage, setErrorMessage] = useState<string>('')

  const [treatment, setTreatment] = useState<TreatmentDTO>(
    emptyTreatmentDTO(selectedUserCircle?.id ?? '')
  )
  const [sendReminder, setSendReminder] = useState<boolean>(false)
  const reminderArr: ReminderType[] = [
    {
      name: 5 + ' ' + t('minuteShort') + ' ' + t('before'),
      value: 5,
    },
    {
      name: 15 + ' ' + t('minuteShort') + ' ' + t('before'),
      value: 15,
    },
    {
      name: 30 + ' ' + t('minuteShort') + ' ' + t('before'),
      value: 30,
    },
    {
      name: 1 + ' ' + t('hour').toLowerCase() + ' ' + t('before'),
      value: 60,
    },
    {
      name: 5 + ' ' + t('hours').toLowerCase() + ' ' + t('before'),
      value: 300,
    },
  ]
  const [userConfigs, setUserConfigs] = useState<ItemList<UserConfig>>(emptyList<UserConfig>())

  const isChecked = (st: SourceType, tt: TransportType): boolean =>
    !!userConfigs.items.find((uc) => uc.sourceType === st && uc.transportType === tt)

  useEffect(() => {
    userConfigService.getListByUserID(loggedUser?.id ?? '').subscribe((res) => setUserConfigs(res))
  }, [])

  useEffect(() => {
    // NO PROP ID
    if (!props?.id) {
      // setTreatment(emptyTreatmentDTO(selectedUserCircle?.id ?? ''))
      setTreatment(
        Object.assign(
          { ...emptyTreatmentDTO(selectedUserCircle?.id ?? '') },
          {
            circleID: selectedUserCircle?.user.id,
            creatorID: loggedUser?.id,
            userCircleID: selectedUserCircle?.id,
          }
        )
      )
    } else {
      treatmentService.getByID(props.id).subscribe((res) => {
        if (res) {
          setTreatment(fromModel(res))
        }
      })
    }
  }, [props.open])

  useEffect(() => {
    if (!treatment.endDate) return
    if (treatment.date > treatment.endDate)
      setTreatment(Object.assign({ ...treatment }, { endDate: new Date() }))
  }, [treatment])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setTreatment(Object.assign({ ...treatment }, { [e.target.name]: e.target.value }))

  const handleNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // convert value to integer
    const int = parseInt(e.target.value)
    setTreatment(Object.assign({ ...treatment }, { [e.target.name]: +int }))
  }

  const handleDate = (event: any) => {
    const duration = dayjs(treatment.endDate).diff(dayjs(event.toDate()), 'day') + 1

    event && setTreatment(Object.assign({ ...treatment }, { date: event.toDate() }, { duration }))
  }
  const handleEndDate = (event: any) => {
    const duration = dayjs(event.toDate()).diff(dayjs(treatment.date), 'day') + 1
    event &&
      setTreatment(Object.assign({ ...treatment }, { endDate: event.toDate() }, { duration }))
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    // calculate duration of treatment counting only days
    const duration =
      dayjs(treatment.endDate).startOf('day').diff(dayjs(treatment.date).startOf('day'), 'day') + 1

    if (!event.target.checked) {
      setTreatment(
        Object.assign({ ...treatment }, { endDate: treatment.date }, { currently: false, duration })
      )
    }

    if (event.target.checked) {
      setTreatment(
        Object.assign({ ...treatment }, { endDate: treatment.date }, { currently: true, duration })
      )
    }
  }
  // esta propiedad es para el checkox de actualmente
  const { currently } = treatment

  const validateTreatment = () => {
    if (!treatment.name) {
      setErrorMessage(TreatmentsErrors.NO_NAME)
      return false
    }

    if (!currently && (Number.isNaN(treatment.duration) || treatment.duration < 1)) {
      setErrorMessage(TreatmentsErrors.INVALID_DURATION)
      return false
    }

    if (Number.isNaN(treatment.frequency) || treatment.frequency < 1) {
      setErrorMessage(TreatmentsErrors.INVALID_FREQUENCY)
      return false
    }

    if (!treatment.actualDose) {
      setErrorMessage(TreatmentsErrors.INVALID_DOSE)
      return false
    }

    if (!treatment.actualDoseUnit) {
      setErrorMessage(TreatmentsErrors.INVALID_DOSE_UNIT)
      return false
    }

    if (treatment.endDate && treatment.date > treatment.endDate && !currently) {
      setErrorMessage(TreatmentsErrors.INVALID_DATE)
      return false
    }

    if (treatment.administrationMethod === -1) {
      setErrorMessage(TreatmentsErrors.INVALIDADMINISTRATIONMETHOD)
      return false
    }

    if (treatment.newDose !== 0 && !treatment.newDoseUnit) {
      setErrorMessage(TreatmentsErrors.INVALID_DOSE_UNIT)
      return false
    }

    setErrorMessage('')
    return true
  }

  const saveTreatment = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!validateTreatment()) {
      return
    }
    if (props.id) {
      treatmentService.update(treatment).subscribe(() => props.onClose)
    } else {
      treatmentService.add(treatment).subscribe(props.onClose)
    }
    setTreatment(emptyTreatmentDTO(selectedUserCircle?.id ?? ''))
    props.onClose()
  }

  const handlerSendReminder = () => {
    setSendReminder(!sendReminder)
    setTreatment(
      Object.assign(
        { ...treatment },
        {
          reminderApp: false,
          reminderSMS: false,
          reminderEmail: false,
          reminderTime: 0,
        }
      )
    )
  }

  return (
    <Modal open={props.open} onClose={props.onClose} className={style.modalOverFlow}>
      <Box className={style.modalContainer}>
        <Box className={style.contentHeaderExitIcon}>
          <img onClick={props.onClose} src={exitModdal} />
        </Box>
        <Box className={style.contentHeader}>
          <p className={style.titleModalTreatments}>{t('createEditTreatment')}</p>
        </Box>

        <Box className={style.mainModalContainer}>
          <Box className={style.inputFormTreatments}>
            <label>{t('name')}*</label>
            <TextFieldItem
              field="name"
              value={treatment.name}
              label={''}
              type={'text'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
              maxLength={100}
            />
          </Box>
          <Box className={style.boxesForm}>
            <Box className={style.boxesForm2}>
              <label>{t('startDateWithoutOf')}</label>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <DateTimePicker
                  className={style.inputDate}
                  key={'date'}
                  PopperProps={{
                    sx: {
                      '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize',
                      },
                      '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                        fontFamily: 'Poppins',
                      },
                      '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                        fontFamily: 'Poppins',
                      },
                      '& .css-3eghsz-PrivatePickersYear-button': {
                        fontFamily: 'Poppins',
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: <CalendarMonthOutlinedIcon />,
                  }}
                  components={{
                    OpenPickerIcon: CalendarMonthOutlinedIcon,
                  }}
                  onError={(reason, value) => {
                    switch (reason) {
                      case 'invalidDate':
                        setStartDateError(t('invalidDateMessage'))
                        break
                      case 'maxDate':
                        setStartDateError(t('maxDateMessageTreatments'))
                        break
                    }
                  }}
                  renderInput={(props) => (
                    <TextField
                      sx={{
                        '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': {
                          paddingRight: '17px',
                        },
                        '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                          fontFamily: 'Poppins',
                        },
                      }}
                      variant={'outlined'}
                      helperText={props.error && startDateError}
                      {...props}
                    />
                  )}
                  inputFormat="DD/MM/YYYY HH:mm"
                  onChange={handleDate}
                  value={treatment.date}
                  maxDate={new Date()}
                  maxDateTime={new Date()}
                />
              </LocalizationProvider>
            </Box>
            <Box className={style.boxesForm2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <label className={style.labelDate}>{t('endDateWithoutOf')}</label>
                <DateTimePicker
                  className={style.inputDate}
                  key={'endDate'}
                  PopperProps={{
                    sx: {
                      '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize',
                      },
                    },
                  }}
                  onError={(reason, value) => {
                    switch (reason) {
                      case 'invalidDate':
                        setEndDateError(t('invalidDateMessage'))
                        break
                      case 'minDate':
                        setEndDateError(t('minDateMessage'))
                        break
                    }
                  }}
                  InputProps={{
                    endAdornment: <CalendarMonthOutlinedIcon />,
                  }}
                  components={{
                    OpenPickerIcon: CalendarMonthOutlinedIcon,
                  }}
                  renderInput={(props) => (
                    <TextField
                      sx={{
                        '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': {
                          paddingRight: '17px',
                        },
                        '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                          fontFamily: 'Poppins',
                        },
                      }}
                      variant={'outlined'}
                      helperText={props.error && endDateError}
                      {...props}
                    />
                  )}
                  inputFormat="DD/MM/YYYY HH:mm"
                  disabled={currently}
                  minDate={dayjs(treatment.date)}
                  value={treatment.endDate}
                  onChange={handleEndDate}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <label>
                <Checkbox checked={currently} onChange={handleCheck} name="box" />
                {t('noEndDate')}
              </label>
            </Box>
          </Box>

          <Box className={style.boxesForm}>
            <Box className={style.boxesForm2} style={currently ? { opacity: 0 } : {}}>
              <label>{t('duration')}</label>
              <TextField
                className={style.inputDuration}
                id={'duration'}
                key={'duration'}
                name={'duration'}
                value={treatment.duration}
                type={'number'}
                onChange={handleNumber}
                required={true}
                variant="outlined"
                disabled={true}
              />
            </Box>

            <Box className={style.boxesForm2}>
              <label>{t('frequency')}*</label>
              <TextField
                className={style.inputDuration}
                id={'frequency'}
                key={'frequency'}
                name={'frequency'}
                inputProps={{ min: 1, pattern: '[0-9]*', step: 1 }}
                value={treatment.frequency}
                type={'number'}
                onChange={handleNumber}
                required={true}
                variant="outlined"
              />
            </Box>
          </Box>
          <p className={style.highValue}>{t('highValue')}</p>
          <Grid container className={style.doseBox}>
            <Grid item className={style.containerCol4}>
              <label className={style.doseLabel}>{t('actualDose')}*</label>
            </Grid>
            <Grid item className={style.containerCol4}>
              <TextField
                className={style.doseField}
                id={'actualDose'}
                key={'actualDose'}
                name={'actualDose'}
                value={treatment.actualDose}
                type={'number'}
                onChange={handleNumber}
                required={true}
                variant="outlined"
                inputProps={{ min: 1 }}
              />
            </Grid>
            <Grid item className={style.containerCol4}>
              <Select
                labelId="demo-select-small-label"
                className={style.doseField2}
                id="demo-select-small"
                value={treatment.actualDoseUnit === '' ? '0' : treatment.actualDoseUnit}
                onChange={(event) => {
                  setTreatment({ ...treatment, actualDoseUnit: event.target.value })
                }}
              >
                {/* Placeholder */}
                <MenuItem value="0" disabled>
                  Unidad
                </MenuItem>
                <MenuItem value="g">g</MenuItem>
                <MenuItem value="mg">mg</MenuItem>
                <MenuItem value="ml">ml</MenuItem>
              </Select>
            </Grid>
            <Grid item className={style.containerCol4}>
              <label className={style.checkboxContainer}>
                <Checkbox
                  style={{ width: '25px', height: '25px' }}
                  icon={<img src={checkboxCuadrado} className={style.check} />}
                  checkedIcon={<img src={checkBoxChecked} className={style.check} />}
                  name="doseChange"
                  onChange={(e) => {
                    setTreatment(
                      Object.assign({ ...treatment }, { changeOfDose: e.target.checked ? 1 : 0 })
                    )
                  }}
                  checked={treatment.changeOfDose === 1}
                />
                {t('doseChange')}
              </label>
            </Grid>
            {treatment.changeOfDose === 1 && (
              <>
                <Grid item className={style.containerCol4}>
                  <label className={style.doseLabel}>{t('newDose')}*</label>
                </Grid>
                <Grid item className={style.containerCol4}>
                  <TextField
                    id="newDose"
                    key="newDose"
                    name="newDose"
                    value={treatment.newDose}
                    type="number"
                    onChange={handleNumber}
                    required={true}
                    variant="outlined"
                    inputProps={{ min: 1 }}
                  />
                </Grid>
                <Grid item className={style.containerCol4}>
                  <Select
                    labelId="demo-select-small-label2"
                    style={{ width: '128px' }}
                    id="demo-select-small2"
                    value={treatment.newDoseUnit === '' ? '0' : treatment.newDoseUnit}
                    onChange={(event) => {
                      setTreatment({ ...treatment, newDoseUnit: event.target.value })
                    }}
                  >
                    {/* Placeholder */}
                    <MenuItem value="0" disabled>
                      Unidad
                    </MenuItem>
                    <MenuItem value="g">g</MenuItem>
                    <MenuItem value="mg">mg</MenuItem>
                    <MenuItem value="ml">ml</MenuItem>
                  </Select>
                </Grid>
              </>
            )}
            <Grid item className={style.containerCol3}>
              <label className={style.radioContainer}>
                <input
                  type="radio"
                  name="administeredOnHouse"
                  className={style.check}
                  disabled={false}
                  onChange={(e) => {
                    setTreatment({
                      ...treatment,
                      administrationMethod: TreatmentAdministration.HOME,
                    })
                  }}
                  checked={treatment.administrationMethod === TreatmentAdministration.HOME}
                />
                {t('administeredOnHouse')}
              </label>
            </Grid>
            <Grid item className={style.containerCol3}>
              <label className={style.radioContainer}>
                <input
                  type="radio"
                  name="pickOnHostpitalAndHome"
                  className={style.check}
                  disabled={false}
                  onChange={(e) => {
                    setTreatment({
                      ...treatment,
                      administrationMethod: TreatmentAdministration.PICK_HOSPITAL_AND_HOME,
                    })
                  }}
                  checked={
                    treatment.administrationMethod ===
                    TreatmentAdministration.PICK_HOSPITAL_AND_HOME
                  }
                />
                {t('pickOnHostpitalAndHome')}
              </label>
            </Grid>
            <Grid item className={style.containerCol3}>
              <label className={style.radioContainer}>
                <input
                  type="radio"
                  name="administeredOnHospital"
                  className={style.check}
                  disabled={false}
                  onChange={(e) => {
                    setTreatment({
                      ...treatment,
                      administrationMethod: TreatmentAdministration.HOSPITAL,
                    })
                  }}
                  checked={treatment.administrationMethod === TreatmentAdministration.HOSPITAL}
                />
                {t('administeredOnHospital')}
              </label>
            </Grid>
            <Grid item className={style.containerCol3}>
              <label className={style.radioContainer}>
                <input
                  type="radio"
                  name="sendReminders"
                  className={style.check}
                  onClick={(e) => handlerSendReminder()}
                  checked={sendReminder}
                />
                {t('sendReminders')}
              </label>
            </Grid>
            <Grid item className={style.containerCol6}>
              <Select
                labelId="demo-select-small-label"
                style={{ width: '100%' }}
                id="demo-select-small"
                value={treatment.reminderTime}
                name="reminderTime"
                onChange={(event) =>
                  setTreatment(
                    Object.assign({ ...treatment }, { reminderTime: event.target.value })
                  )
                }
                disabled={!sendReminder}
              >
                <MenuItem value={0} disabled>
                  Cada...
                </MenuItem>
                {reminderArr.map((reminder) => (
                  <MenuItem value={reminder.value} key={reminder.value}>
                    {reminder.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item className={style.containerCol6}>
              <label className={style.checkboxContainer}>
                <Checkbox
                  style={{ width: '25px', height: '25px' }}
                  icon={<img src={checkboxCuadrado} className={style.check} />}
                  checkedIcon={<img src={checkBoxChecked} className={style.check} />}
                  indeterminateIcon={<img src={checkBoxDeshabilitado} className={style.check} />}
                  name="reminderApp"
                  indeterminate={
                    !sendReminder || !isChecked(SourceType.TreatmentCreated, TransportType.App)
                  }
                  onClick={(e) =>
                    setTreatment(
                      Object.assign({ ...treatment }, { reminderApp: !treatment.reminderApp })
                    )
                  }
                  checked={treatment.reminderApp}
                />
                {t('app')}
              </label>
            </Grid>
            <Grid item className={style.containerCol6}>
              <label className={style.checkboxContainer}>
                <Checkbox
                  style={{ width: '25px', height: '25px' }}
                  icon={<img src={checkboxCuadrado} className={style.check} />}
                  checkedIcon={<img src={checkBoxChecked} className={style.check} />}
                  indeterminateIcon={<img src={checkBoxDeshabilitado} className={style.check} />}
                  name="reminderEmail"
                  indeterminate={
                    !sendReminder || !isChecked(SourceType.TreatmentCreated, TransportType.Email)
                  }
                  onClick={(e) =>
                    setTreatment(
                      Object.assign({ ...treatment }, { reminderEmail: !treatment.reminderEmail })
                    )
                  }
                  checked={treatment.reminderEmail}
                />
                {t('mail')}
              </label>
            </Grid>
            <Grid item className={style.containerCol6}>
              <label className={style.checkboxContainer}>
                <Checkbox
                  style={{ width: '25px', height: '25px' }}
                  icon={<img src={checkboxCuadrado} className={style.check} />}
                  checkedIcon={<img src={checkBoxChecked} className={style.check} />}
                  indeterminateIcon={<img src={checkBoxDeshabilitado} className={style.check} />}
                  name="reminderEmail"
                  indeterminate={
                    !sendReminder || !isChecked(SourceType.TreatmentCreated, TransportType.SMS)
                  }
                  disabled={!sendReminder}
                  onClick={(e) =>
                    setTreatment(
                      Object.assign({ ...treatment }, { reminderSMS: !treatment.reminderSMS })
                    )
                  }
                  checked={treatment.reminderSMS}
                />
                {t('sms')}
              </label>
            </Grid>
            <Alert className={style.alert} severity="warning" key="infoMessage" id="infoMessage">
              {t('onlyNotificationConfigUser')}
            </Alert>
          </Grid>
          {errorMessage && (
            <Box my={3}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}
          <Box gap={1} my={1} display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={props.onClose}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={saveTreatment}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
