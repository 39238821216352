import React, { useEffect, useRef, useState } from 'react'
import { Message } from '../../modules/messenger_consulta/models/Message'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import style from './Messages.module.css'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { Query, QueryParam } from '../../common/api/Query'
import { User, UserQuery } from '../../modules/users/models/User'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { viewFile } from '../../common/utils/file'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Roles } from '../../modules/users/enums/Roles'
import { Description } from '@mui/icons-material'
import dayjs from 'dayjs'

const userContainer = getUserContainer()
const usersService = userContainer.get<UserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const filesService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

type MessagesProps = {
  dayOfTeleconsultation: Date
  messages: Message[]
  handleTeleconsultationForm: () => void
}

export function Messages(props: MessagesProps): JSX.Element {
  const loggedUser = loggedUserService.get()

  const [messagesAuthor, setMessagesAuthor] = useState<User[]>()

  const divMessagesScroll = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divMessagesScroll.current !== null) {
      divMessagesScroll.current.scrollTop = divMessagesScroll.current.scrollHeight
    }
    if (props.messages.length === 0) {
      return
    }
    usersService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<UserQuery>(
              'ids',
              props.messages.map((m) => m.author)
            ),
          ],
        })
      )
      .subscribe((res) => {
        setMessagesAuthor(res.items)
      })
  }, [props.messages])

  const getCorrectMessage = (text: string) => {
    const result = text.split('*')
    return result.length === 1 ? (
      <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: text }} />
    ) : (
      <>
        <Description />
        <Link to={'.'} onClick={() => viewResource(result[1])}>
          {result[2]}
        </Link>
      </>
    )
  }

  const viewResource = (id: string) => {
    filesService.downloadByID(id).subscribe((res) => {
      if (res) {
        viewFile(res)
      }
    })
  }

  return (
    <div className={getDivMessagesScrollStyle(props.messages.length)} ref={divMessagesScroll}>
      <>
        <Box
          key={'autogenerated'}
          className={style.messageContainer}
          display="flex"
          flexDirection="column"
          alignItems={'flex-start'}
        >
          <Box className={style.messageContentReceived2}>
            <p>
              Tiene una cita programada para el día{' '}
              {dayjs(props.dayOfTeleconsultation).format('YYYY-MM-DD')}, haga click en el siguiente{' '}
              {''}
              <span onClick={props.handleTeleconsultationForm} className={style.messageLink}>
                enlace
              </span>
              {''} para rellenar el cuestionario antes de su cita.
            </p>
          </Box>
        </Box>
        {props.messages.map((m) => (
          <Box
            key={m.id}
            className={style.messageContainer}
            display="flex"
            flexDirection="column"
            alignItems={m.author === loggedUser?.id ? 'flex-end' : 'flex-start'}
          >
            {m.isPinned ? (
              <Box component="span" style={{ marginBottom: '3px' }}>
                Respuesta automática
              </Box>
            ) : (
              <Box component="span" style={{ color: '#166bb2', marginBottom: '3px' }}>
                {m.author !== loggedUser?.id && !loggedUser?.roles.includes(Roles.Professional)
                  ? 'Enfermero/a'
                  : ''}
              </Box>
            )}
            <Box className={getMessageContentStyle(loggedUser?.id ?? '', m.author)}>
              <p className={style.author}>{messagesAuthor?.find((u) => u.id === m.author)?.name}</p>
              <p>{getCorrectMessage(m.text)}</p>
            </Box>
          </Box>
        ))}
      </>
    </div>
  )
}

const getMessageContentStyle = (loggedUsername: string, author: string) =>
  `${style.messageContent} ${
    author === loggedUsername ? style.messageContentSent : style.messageContentReceived
  }`

const getDivMessagesScrollStyle = (messagesLength: number) =>
  `${style.divMessagesScroll} ${messagesLength === 0 ? style.divMessagesScrollEmpty : ''}`
