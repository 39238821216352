import Switch, { SwitchProps, SwitchClassKey } from '@mui/material/Switch'
import { Theme } from '@mui/material/styles'
import { createStyles, withStyles } from '@mui/styles'

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string
}

interface Props extends SwitchProps {
  classes: Styles
}

export const SwitchCustom = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 64,
      height: 36,
      padding: 0,
      margin: theme.spacing(2),
    },
    switchBase: {
      padding: 1,
      '&.Mui-checked': {
        transform: 'translateX(28px)',
        color: theme.palette.common.white,
        '& + .MuiSwitch-track': {
          backgroundColor: '#0563a6',
          opacity: 1,
          border: 'none',
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#0563a6',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 34,
      height: 34,
    },
    track: {
      borderRadius: 52 / 2,
      border: `1px solid #efefef`,
      backgroundColor: '#d1d1d1',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})

export const SwitchCloseQuery = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&.Mui-checked': {
        transform: 'translateX(24px)',
        color: theme.palette.common.white,
        '& + .MuiSwitch-track': {
          backgroundColor: '#0563a6',
          opacity: 1,
          border: 'none',
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#0563a6',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 52 / 2,
      border: `1px solid #efefef`,
      backgroundColor: '#d1d1d1',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})
