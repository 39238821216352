import { Box } from '@mui/material'
import style from '../../NavigationEsfera.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { LinkType } from '../../NavigationEsfera'
import { useTranslation } from 'react-i18next'
import { CircleSubMenu } from './menu'
import { useSelectedMenuContext } from 'common/utils/menu-selected-context/MenuSelectedContext'
import { getLinkStyle, getListItemStyle } from 'pages/layout/stylesFunctions'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

type CircleSubMenuContainerProps = {
  links: LinkType[]
  anchorEl: HTMLElement | null
}

export const CircleSubMenuContainer = ({ links, anchorEl }: CircleSubMenuContainerProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { sMenu } = useSelectedMenuContext()
  const { selectedUserCircle } = useCircleConfiguration()
  if (!selectedUserCircle) return null

  return (
    <CircleSubMenu
      anchorEl={anchorEl}
      style={style.subMenuContainer}
      // eslint-disable-next-line react/no-children-prop
      children={
        <>
          <h2>{t('circles')}</h2>
          <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
            {links
              .filter((l) => l.isVisible && l.subMenu && !l.subMenuArticle)
              .map((l) => (
                <Link
                  onClick={() => {
                    navigate(l.route)
                  }}
                  key={l.title}
                  to={l.route}
                  className={getLinkStyle(
                    sMenu,
                    style.circleSubMenuActive,
                    ...l.additionalRoutes,
                    l.route
                  )}
                  id={l.route}
                >
                  <div className={style.menuDiv}>
                    <Box
                      style={{ padding: '0 60px 0 60px' }}
                      key={l.title}
                      className={getListItemStyle(
                        false,
                        sMenu || '',
                        style.circleSubMenuItem,
                        style.circleSubMenuItemActive,
                        ...(l?.additionalRoutes || ''),
                        l?.route || ''
                      )}
                    >
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Box style={{ margin: 8, minWidth: 40 }}>
                          {l.route === sMenu ? (
                            <img src={l.activeIcon} alt={t(l.altSrc)} />
                          ) : (
                            <img src={l.icon} alt={t(l.altSrc)} />
                          )}
                        </Box>
                        <Box>
                          <h3 className={style.titleNameSubMenu}>{t(l.title)}</h3>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Link>
              ))}
          </Box>
        </>
      }
    />
  )
}
