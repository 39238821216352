import { PriorityLevel } from '../enums/PriorityLevel'
import { Message } from './Message'
import { Query } from 'common/api/Query'
import { DeliveryStatus } from '../enums/DeliveryStatus'
import { TransportType } from 'common/enums/TransportType'
import { SourceType } from '../enums/SourceType'

export type NotificationParams = {
  id?: string
  segmentedID?: string
  priorityLevel?: PriorityLevel
  senderID: string
  recipientID: string
  userCircleID: string
  deliveryStatus: DeliveryStatus
  transportType: TransportType
  sourceType: SourceType
  message: string
  createdAt: Date
  sendAt: Date
  endDate: Date
  frequency: number
  lastSendAt: Date
}

export class Notification {
  private readonly _id: string | undefined
  private readonly _segmentedID: string | undefined
  private readonly _senderID: string
  private readonly _recipientID: string
  private readonly _userCircleID: string
  private readonly _transportType: TransportType
  private readonly _sourceType: SourceType
  private readonly _message: string
  private readonly _createdAt: Date
  private readonly _sendAt: Date
  private readonly _endDate: Date
  private readonly _frequency: number
  private readonly _lastSendAt: Date

  private _priorityLevel: PriorityLevel
  private _deliveryStatus: DeliveryStatus

  constructor(p: NotificationParams) {
    this._id = p.id
    this._segmentedID = p.segmentedID
    this._priorityLevel = p.priorityLevel ?? PriorityLevel.Normal
    this._message = p.message
    this._senderID = p.senderID
    this._recipientID = p.recipientID
    this._userCircleID = p.userCircleID
    this._deliveryStatus = p.deliveryStatus
    this._transportType = p.transportType
    this._sourceType = p.sourceType
    this._createdAt = new Date()
    this._sendAt = p.sendAt
    this._endDate = p.endDate
    this._frequency = p.frequency
    this._lastSendAt = p.lastSendAt
  }

  get id(): string | undefined {
    return this._id
  }

  get segmentedID(): string | undefined {
    return this._segmentedID
  }

  get senderID(): string {
    return this._senderID
  }

  get recipientID(): string {
    return this._recipientID
  }

  get userCircleID(): string {
    return this._userCircleID
  }

  get message(): string {
    return this._message
  }

  get priorityLevel(): PriorityLevel {
    return this._priorityLevel
  }

  set priorityLevel(value: PriorityLevel) {
    this._priorityLevel = value
  }

  get deliveryStatus(): DeliveryStatus {
    return this._deliveryStatus
  }

  set deliveryStatus(value: DeliveryStatus) {
    this._deliveryStatus = value
  }

  get transportType(): TransportType {
    return this._transportType
  }

  get sourceType(): SourceType {
    return this._sourceType
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get sendAt(): Date {
    return this._sendAt
  }

  get endDate(): Date {
    return this._endDate
  }

  get frequency(): number {
    return this._frequency
  }

  get lastSendAt(): Date {
    return this._lastSendAt
  }
}

export class NotificationQuery extends Query<Message> {}
