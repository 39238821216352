import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, Grid } from '@mui/material'
import styles from './PatientData.module.css'
import { useNavigate } from 'react-router-dom'
import icoCartilla from 'assets/esfera/patient-data/book.svg'
import icoCartillaHover from 'assets/esfera/patient-data/hover-book.svg'
import icoEvolutivo from 'assets/esfera/patient-data/evolutionary-control.svg'
import icoEvolutivoHover from 'assets/esfera/patient-data/evolutionary-control-hover.svg'
import icoSintomaHover from 'assets/esfera/patient-data/disease-hover.svg'
import icoTratamientoHover from 'assets/esfera/patient-data/medication-hover.svg'
import icoVacunasHover from 'assets/esfera/patient-data/calendar-hover.svg'
import icoSaludbucalHover from 'assets/esfera/patient-data/dentist-hover.svg'
import icoWearablesHover from 'assets/esfera/patient-data/wearable-hover.svg'
import icoSintoma from 'assets/esfera/patient-data/disease.svg'
import icoTratamiento from 'assets/esfera/patient-data/medication.svg'
import icoVacunas from 'assets/esfera/patient-data/calendar.svg'
import icoSaludbucal from 'assets/esfera/patient-data/dentist.svg'
import icoWearables from 'assets/esfera/patient-data/wearable.svg'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { Permission } from 'common/permission'
import iconTraining from 'assets/active_plan_icons/training.svg'
import iconTrainingHover from 'assets/active_plan_icons/training_hover.svg'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

type PatientDataItem = {
  name: string
  permission: Permission | null
  icon: string
  hoverIcon: string
}

export function PatientData() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { selectedUserCircle } = useCircleConfiguration()
  const dataToRender = selectedUserCircle?.circle.patientConfig

  const patientDataItems: PatientDataItem[] = [
    {
      name: 'medicalData',
      permission:
        Permission.seePatientData && dataToRender?.healthyChildChart
          ? Permission.seePatientData
          : null,
      icon: icoCartilla,
      hoverIcon: icoCartillaHover,
    },
    {
      name: 'evolutionControl',
      permission:
        Permission.seeEvolutionControl && dataToRender?.evolutionaryControl
          ? Permission.seeEvolutionControl
          : null,
      icon: icoEvolutivo,
      hoverIcon: icoEvolutivoHover,
    },
    {
      name: 'symptoms',
      permission:
        Permission.seePatientData && dataToRender?.symptoms ? Permission.seePatientData : null,
      icon: icoSintoma,
      hoverIcon: icoSintomaHover,
    },
    {
      name: 'treatments',
      permission:
        Permission.seePatientData && dataToRender?.treatments ? Permission.seePatientData : null,
      icon: icoTratamiento,
      hoverIcon: icoTratamientoHover,
    },
    {
      name: 'vaccinationSchedule',
      permission:
        Permission.seeVaccinationCalendar && dataToRender?.vacCalendar
          ? Permission.seeVaccinationCalendar
          : null,
      icon: icoVacunas,
      hoverIcon: icoVacunasHover,
    },
    {
      name: 'oralHealth',
      permission:
        Permission.seeDentists && dataToRender?.oralHealth ? Permission.seeDentists : null,
      icon: icoSaludbucal,
      hoverIcon: icoSaludbucalHover,
    },
    {
      name: 'wearables',
      permission: Permission.seeWearable && dataToRender?.wearables ? Permission.seeWearable : null,
      icon: icoWearables,
      hoverIcon: icoWearablesHover,
    },
    {
      name: 'activePlan',
      permission:
        Permission.seeActivePlan && dataToRender?.activePlan ? Permission.seeActivePlan : null,
      icon: iconTraining,
      hoverIcon: iconTrainingHover,
    },
  ]

  const handleOnClick = (type: string) => navigate(`./${type}`)
  const [activeItemName, setActiveItemName] = useState<string | null>(null)

  const checkPermission = (patientData: PatientDataItem): boolean => {
    return loggedUserService.userCan(patientData.permission as string)
  }

  return (
    <Box className={styles.centerBlock2}>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {patientDataItems
          .filter((i) => checkPermission(i))
          .map((item) => (
            <Grid item xs={6} sm={6} md={3} lg={2} key={item.name}>
              {' '}
              <Card
                className={styles.hoverCard}
                variant="outlined"
                onClick={() => handleOnClick(item.name)}
                onMouseEnter={() => setActiveItemName(item.name)}
                onMouseLeave={() => setActiveItemName(null)}
              >
                <div className={styles.iconBlock}>
                  <img
                    src={activeItemName === item.name ? item.hoverIcon : item.icon}
                    alt={item.name}
                  />
                </div>
                <div>
                  <h2 className={styles.textBlock} style={{ whiteSpace: 'pre-line' }}>
                    {t(item.name)}
                    <hr className={styles.horizontalLine} />
                  </h2>
                </div>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}
