import { useTranslation } from 'react-i18next'
import { Box, Modal, TextField } from '@mui/material'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import React, { useState } from 'react'
import { TextFieldItem } from 'components/form-card/TextFieldItem'
import {
  emptyPatientSymptomDTO,
  PatientSymptomDTO,
} from 'modules/patient-data/models/PatientSymptomDTO'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers'
import { FormCard } from 'components/form-card/FormCard'
import { Header } from 'components/header/Header'
import slightBleeding from 'assets/newCircle/blood_plus_icon.svg'
import moderateBleeding from 'assets/newCircle/blood_plus_plus_icon.svg'
import hemorrhage from 'assets/newCircle/blood_plus_plus_plus_icon.svg'
import green_pain_icon from 'assets/newCircle/green_pain_icon.svg'
import yellow_pain_icon from 'assets/newCircle/yellow_pain_icon.svg'
import red_pain_icon from 'assets/newCircle/red_pain_icon.svg'
import blue_pain_icon from 'assets/newCircle/blue_pain_icon.svg'
import darkblue_paint_icon from 'assets/newCircle/darkblue_pain_icon.svg'
import orange_pain_icon from 'assets/newCircle/orange_pain_icon.svg'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import style from '../Editor.module.css'
import closeIcon from 'assets/esfera/buttons/delete.svg'
import { PatientSymptomService } from 'modules/patient-data/services/PatientSymptomService'
import { PATIENT_SYMPTOM_SERVICE_KEY } from 'modules/patient-data'
import { getPatientDataContainer } from 'container/patient-data-module'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

type EventModalProps = {
  open: boolean
  close: () => void
  onClick: (name: string) => void
  id?: string
  name: string
  idIcon: string
}

const patientSymptomService = getPatientDataContainer().get<PatientSymptomService>(
  PATIENT_SYMPTOM_SERVICE_KEY
)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const RectalBleedingAndPainScaleModal: React.FC<EventModalProps> = ({
  open,
  close,
  onClick,
  name,
  idIcon,
}) => {
  const { t } = useTranslation()
  const [patientSymptom, setPatientSymptom] = useState<PatientSymptomDTO>(emptyPatientSymptomDTO())
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const [selectedOption, setSelectedOption] = useState<string>('')
  const loggedUser = loggedUserService.get()
  const { selectedUserCircle } = useCircleConfiguration()

  const handleStartDate = (event: any) =>
    event &&
    setPatientSymptom(Object.assign({ ...patientSymptom }, { symptomStartDate: event.toDate() }))

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPatientSymptom(Object.assign({ ...patientSymptom }, { comment: e.target.value }))
  }

  const handleImageClick = (name: string) => {
    setSelectedOption(name)
    onClick(name)
  }

  const handleSave = () => {
    const idSymptom = selectedUserCircle?.circle?.symptoms.find(
      (s) => s.idIcon === idIcon
    )?.idSymptom
    const newPatientSymptom = patientSymptom
    newPatientSymptom.symptomID = idSymptom ?? ''
    newPatientSymptom.creatorID = loggedUser?.id ?? ''
    newPatientSymptom.finishDate = patientSymptom.symptomStartDate
    newPatientSymptom.userID = selectedUserCircle?.user?.id ?? ''
    newPatientSymptom.userCircleID = selectedUserCircle?.id ?? ''
    newPatientSymptom.idIcon = selectedOption

    patientSymptomService.add(newPatientSymptom).subscribe(() => {
      close()
    })
  }
  const optionStyles = {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 'bold',
    maxWidth: '100px',
  }

  const rectalBleedingIcons = {
    icons: (
      <Box
        sx={{
          border: '1px solid #68B3E0',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          textAlign: 'center',
          padding: '10px 20px 10px 20px',
        }}
      >
        <Box>
          <Button onClick={() => handleImageClick('hemorrhage')}>
            <img src={hemorrhage} alt="Hemorrhage" />
          </Button>
          <Typography
            sx={{
              ...optionStyles,
              color: selectedOption === 'hemorrhage' ? '#f18b24' : '#68B3E0',
            }}
          >
            {t('hemorrhage')}
          </Typography>
        </Box>
        <Box>
          <Button onClick={() => handleImageClick('moderateBleeding')}>
            <img src={moderateBleeding} alt="Moderate Bleeding" />
          </Button>
          <Typography
            sx={{
              ...optionStyles,
              color: selectedOption === 'moderateBleeding' ? '#f18b24' : '#68B3E0',
            }}
          >
            {t('moderateBleeding')}
          </Typography>
        </Box>

        <Box>
          <Button onClick={() => handleImageClick('lightBleeding')}>
            <img src={slightBleeding} alt="Slight Bleeding" />
          </Button>
          <Typography
            sx={{
              ...optionStyles,
              color: selectedOption === 'lightBleeding' ? '#f18b24' : '#68B3E0',
            }}
          >
            {t('slightBleeding')}
          </Typography>
        </Box>
      </Box>
    ),
  }

  const painIcons = {
    icons: (
      <Box
        sx={{
          border: '1px solid #68B3E0',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'space-around',
          textAlign: 'center',
          padding: '10px 20px 10px 20px',
          flexWrap: 'wrap',
        }}
      >
        <Box>
          <Button onClick={() => handleImageClick('withoutPain')}>
            <img src={green_pain_icon} alt="withoutPain" />
          </Button>
          <Typography
            sx={{
              ...optionStyles,
              color: selectedOption === 'withoutPain' ? '#f18b24' : '#68B3E0',
            }}
          >
            {t('withoutPain')}
          </Typography>
        </Box>

        <Box>
          <Button onClick={() => handleImageClick('mildPain')}>
            <img src={blue_pain_icon} alt="mildPain" />
          </Button>
          <Typography
            sx={{
              ...optionStyles,
              color: selectedOption === 'mildPain' ? '#f18b24' : '#68B3E0',
            }}
          >
            {t('mildPain')}
          </Typography>
        </Box>

        <Box>
          <Button onClick={() => handleImageClick('moderatePain')}>
            <img src={darkblue_paint_icon} alt="moderatePain" />
          </Button>
          <Typography
            sx={{
              ...optionStyles,
              color: selectedOption === 'moderatePain' ? '#f18b24' : '#68B3E0',
            }}
          >
            {t('moderatePain')}
          </Typography>
        </Box>

        <Box>
          <Button onClick={() => handleImageClick('severePain')}>
            <img src={yellow_pain_icon} alt="severePain" />
          </Button>
          <Typography
            sx={{
              ...optionStyles,
              color: selectedOption === 'severePain' ? '#f18b24' : '#68B3E0',
            }}
          >
            {t('severePain')}
          </Typography>
        </Box>

        <Box>
          <Button onClick={() => handleImageClick('verySeverePain')}>
            <img src={orange_pain_icon} alt="verySeverePain" />
          </Button>
          <Typography
            sx={{
              ...optionStyles,
              color: selectedOption === 'verySeverePain' ? '#f18b24' : '#68B3E0',
            }}
          >
            {t('verySeverePain')}
          </Typography>
        </Box>

        <Box>
          <Button onClick={() => handleImageClick('maximumPain')}>
            <img src={red_pain_icon} alt="maximumPain" />
          </Button>
          <Typography
            sx={{
              ...optionStyles,
              color: selectedOption === 'maximumPain' ? '#f18b24' : '#68B3E0',
            }}
          >
            {t('maximumPain')}
          </Typography>
        </Box>
      </Box>
    ),
  }

  return (
    <Modal className={style.modal} open={open} onClose={close}>
      <Box className={style.modalContainer}>
        <FormCard>
          <>
            <Box className={style.exitIcon}>
              <img className={style.icon} src={closeIcon} alt={'close icon'} onClick={close} />
            </Box>
            <Header label={t('symptoms')} />
            <>
              <Box className={style.datePickerContainer} mt={2} mb={2}>
                <Box>
                  <p>{name}</p>
                </Box>
                <Box className={style.datePickerOnlyOne}>
                  <span style={{ fontFamily: 'Poppins', marginRight: '24px' }}>{t('date')}</span>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                    <Box sx={{ width: '180px' }}>
                      <DateTimePicker
                        PopperProps={{
                          sx: {
                            '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                              fontFamily: 'Poppins',
                              textTransform: 'capitalize',
                            },
                            '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                              fontFamily: 'Poppins',
                            },
                            '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                              fontFamily: 'Poppins',
                            },
                            '& .css-3eghsz-PrivatePickersYear-button': {
                              fontFamily: 'Poppins',
                            },
                          },
                        }}
                        onError={(reason, value) => {
                          switch (reason) {
                            case 'invalidDate':
                              setDateTimePickerError(t('invalidDateMessage'))
                              break
                            case 'minDate':
                              setDateTimePickerError(t('minDateMessage'))
                              break
                          }
                        }}
                        renderInput={(props) => (
                          <TextField
                            sx={{
                              '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': {
                                paddingRight: '17px',
                              },
                              '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                                fontFamily: 'Poppins',
                              },
                            }}
                            variant={'standard'}
                            helperText={props.error && DateTimePickerError}
                            {...props}
                          />
                        )}
                        inputFormat="DD/MM/YYYY HH:mm"
                        onChange={handleStartDate}
                        value={patientSymptom.symptomStartDate}
                      />
                    </Box>
                  </LocalizationProvider>
                </Box>
              </Box>

              <Box className={style.commentTextField}>
                {idIcon === 'BloodScale' ? rectalBleedingIcons.icons : painIcons.icons}
              </Box>

              <Box className={style.commentTextField}>
                <Box mb={1}>
                  <p>{t('comments')}</p>
                </Box>
                <TextFieldItem
                  field="comment"
                  value={patientSymptom.comment}
                  label={''}
                  type={'text'}
                  handleChange={handleInput}
                  rows={5}
                  required={false}
                />
              </Box>

              <Box className={style.buttonsContainer}>
                <AppButton
                  theme={ButtonTheme.whiteAndBlue}
                  type={'button'}
                  label={t('cancel')}
                  handler={close}
                />
                <AppButton
                  label={t('save')}
                  theme={ButtonTheme.NewPrimaryResponsive}
                  handler={handleSave}
                  type={'button'}
                />
              </Box>
            </>
          </>
        </FormCard>
      </Box>
    </Modal>
  )
}
