import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_MESSENGER } from 'routes/routes-constants'
import { Query, QueryParam } from 'common/api/Query'
import { Conversation } from 'modules/messenger/models/Conversation'
import { getMessengerContainer } from 'container/messenger-module'
import { ConversationService } from 'modules/messenger/services/ConversationService'
import { CONVERSATION_SERVICE_KEY } from 'modules/messenger'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { listItemTextStyle } from '../notification-card-patient/NotificationsCardStyleMui'
import style from './ChatsCard.module.css'
import maleIcon from '@assets/chatIcons/male.svg'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import { ConversationDTO } from 'modules/messenger/models/ConversationDTO'
import {
  UserCircleQuery,
  UserCircleWithCircleAndUser,
} from 'modules/user-circle/models/UserCircleWithCircleAndUser'
import { emptyList, ItemList } from 'common/models/ItemList'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from 'modules/user-circle'
import { getUserCircleContainer } from 'container/user-circle-module'

type ChatsCardProps = {
  setNumberPendingChats: (number: number) => void
}

const messengerContainer = getMessengerContainer()
const conversationsService = messengerContainer.get<ConversationService>(CONVERSATION_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)

const notificationsPerPage = 7

export const ChatsCard: React.FC<ChatsCardProps> = (props) => {
  const loggedUser = loggedUserService.get()
  const [conversationCollection, setConversationCollection] = useState<ConversationDTO[]>([])
  const { selectedUserCircle, setSelectedUserCircle } = useCircleConfiguration()

  const [page] = useState<number>(1)
  const navigate = useNavigate()
  const [userCircles, setUserCircles] =
    useState<ItemList<UserCircleWithCircleAndUser>>(emptyList<UserCircleWithCircleAndUser>())

  useEffect(() => {
    if (!loggedUser) return
    conversationsService
      .getFilteredItems(
        new Query({
          pager: { offset: (page - 1) * notificationsPerPage, limit: notificationsPerPage },
          query: [
            new QueryParam<Conversation>('userID', loggedUser.id),
            new QueryParam<Conversation>('userCircleID', selectedUserCircle?.id ?? ''),
          ],
        })
      )
      .subscribe((res) => {
        setConversationCollection(res)
        props.setNumberPendingChats(res.length)
        /* const conversationUsersArray: ConversationUser[] = []
        res.forEach((conversation) => {
          conversationUsersArray.push(...conversation.users)
        }) */
        // setConversationUsers(conversationUsersArray)
      })

    // llamada principal para llamar a los userCircles

    const query = new Query<UserCircleQuery>({
      pager: { offset: (page - 1) * notificationsPerPage, limit: notificationsPerPage },
      query: [new QueryParam('includesUserID', loggedUser.id)],
      sort: [{ field: 'userID', desc: true }],
    })

    fetchUserCircles(query)
  }, [selectedUserCircle?.id])

  // implement if they explicitly ask us that they want to differentiate the icons by gender.
  // In the backend change the checkoutDoctor to bring the gender

  /* useEffect(() => {
    const fetchGender = async () => {
      const adminUser = conversationUsers.find((cuser) => cuser.isAdmin)
      if (adminUser) {
        const ownerID = adminUser.userID
        console.log('ownerID', ownerID)
        const res = await userService.getByID(ownerID).toPromise()
        if (res) {
          console.log('resGender', res)
          setGender(res.gender === 1 ? 'female' : 'male')
        }
      }
    }
    fetchGender()
  }, [conversationCollection]) */

  const fetchUserCircles = (query: Query<UserCircleQuery>) => {
    try {
      userCircleService.getFilteredListWithCircleAndUser(query as any).subscribe((res) => {
        if (!res) return
        setUserCircles(res)
      })
    } catch (e) {
      console.error(e)
    }
  }

  // TODO: arreglar el tipo de conversation param, debe de ser tipo Conversation
  const handlerGotToChats = (conversation: any) => {
    const { userCircleID, id } = conversation
    const filterUser = userCircles.items.filter((u) => u.id === userCircleID)[0]

    try {
      setSelectedUserCircle(filterUser)
      // navegamos
      navigate(ROUTE_MESSENGER, { state: { conversationId: id } })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DashboardSectionCard
      label={'pendingChats'}
      showMore={Boolean(selectedUserCircle?.circle)}
      onClick={() => handlerGotToChats}
    >
      {conversationCollection.length > 0 && (
        <List>
          {conversationCollection.map((conversation) => (
            <ListItem
              sx={{ paddingLeft: 0, paddingRight: 0 }}
              key={conversation.id}
              onClick={() => handlerGotToChats(conversation)}
              className={style.chatContainer}
            >
              <ListItemAvatar>
                <Avatar
                  src={maleIcon}
                  style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0' }}
                />
              </ListItemAvatar>
              <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                {conversation.name}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </DashboardSectionCard>
  )
}
