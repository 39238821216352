import JsPDF from 'jspdf'
import { renderToStaticMarkup, renderToString } from 'react-dom/server'
import html2canvas from 'html2canvas'

export async function PrintPDFComponent(component: any) {
  debugger;
  convertToBuffer(component).then((resp) => {
    debugger;
    // mergePdfs(resp).then(resp => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const url = window.URL.createObjectURL(new Blob([resp], { type: 'application/pdf' }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'Plan Activa' + '.pdf')
    document.body.appendChild(link)
    link.click()
    // })
  })
}

async function convertToBuffer(component: any) {
  return await new Promise((resolve) => {
    const div = document.createElement('div')
    const obj = component
    const doc = new JsPDF('p', 'px', 'a1')
    const idRandom = 'printPDF-' + Math.floor(Math.random() * 9999)
    const queryRandom = '#' + idRandom
    div.id = idRandom
    div.style.display = 'hidden'
    div.style.width = '1000px'
    div.style.height = '600px'
    div.innerHTML = renderToStaticMarkup(obj)
    const promise = new Promise((resolve) => {
      resolve(renderToString(obj))
    })

    debugger;
    html2canvas(div).then((canvas) => {
      debugger;
      document.body.appendChild(canvas)
    })

    promise
      .then(async (resp) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        div.innerHTML = resp
        document.body.appendChild(div)
        if (div.innerHTML !== '') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // Verificamos si document.querySelector(queryRandom) no es null
          // https://stackoverflow.com/questions/43032004/queryselector-in-typescript
          const element = document.querySelector(queryRandom) as HTMLElement
          if (element) {
            await doc
              .html(element, { margin: 30 })
              .then(() => {
                // for (let j = 1; j <= doc.getNumberOfPages(); j++) { // }
                return doc.output('arraybuffer')
              })
              .then((obj) => {
                document.body.removeChild(div)
                return resolve(obj)
              })
          } else {
            console.error(`No se encontró ningún elemento con el selector: ${queryRandom}`)
          }
        }
      })
      .catch((error) => {
        alert(error)
      })
  })
}
