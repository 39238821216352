import { CalendarNotificationDTO, Notification } from '../../modules/calendar/models/Notification'
import { Box, Checkbox, Chip, useMediaQuery } from '@mui/material'
import styles from './Notification.module.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import icoPlus from '../../assets/events_icons/ico-plus.svg'
import { dateToDateTimeWithoutSecondsString } from '../../common/utils/date'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TransportType } from '../../common/enums/TransportType'
import { useDebounce } from 'hooks/debounce/useDebounce'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

type NotificationSelectProps = {
  value: string
  notifications?: CalendarNotificationDTO[]
  cleanNotifications?: boolean
  handlerChange: (notifications: CalendarNotificationDTO) => void
  removeNotification: (date: Date, id: string) => void
  type: string
}

type DateList = {
  id: string
  date: Date
}

export function Select(props: NotificationSelectProps) {
  const { t } = useTranslation()
  const [notifications, setNotifications] = useState<CalendarNotificationDTO[]>(
    props.notifications ?? []
  )

  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [dateList, setDateList] = useState<DateList[]>([])
  const [isFirst, setIsFirst] = useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLast, setIsLast] = useState<number>(1)
  const [date, setDate] = useState<Date>(new Date())
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const isXS = useMediaQuery('(max-width: 599px)')

  function createNotification(date: Date, type: string): CalendarNotificationDTO {
    if (type === 'Email') {
      return new Notification({ transportType: TransportType.Email, notifyAt: date })
    } else if (type === 'SMS') {
      return new Notification({ transportType: TransportType.SMS, notifyAt: date })
    } else {
      return new Notification({ transportType: TransportType.App, notifyAt: date })
    }
  }
  function addNotification(date: Date, type: string) {
    const newNotification = createNotification(date, type)
    const updatedNotifications = [...notifications, newNotification]
    props.handlerChange(newNotification)
    setNotifications(updatedNotifications)
  }

  /*  function addNotification(date: Date, type: string) {
    let notification

    if (type === 'Email') {
      notification = new Notification({ transportType: TransportType.Email, notifyAt: new Date() })
    } else if (type === 'SMS') {
      notification = new Notification({ transportType: TransportType.SMS, notifyAt: new Date() })
    } else {
      notification = new Notification({ transportType: TransportType.App, notifyAt: new Date() })
    }

    setNotifications([...notifications, notification])
  } */

  /*  function notificationChange(newNotification: Notification) {
    notifications[notifications.findIndex((n) => n.id === newNotification.id)] = newNotification
    setNotifications([...notifications])
  }

  function removeNotification(id: string) {
    setNotifications(notifications.filter((n) => n.id !== id))
  }

  function handleNotifyAtChange(event: MaterialUiPickersDate, i: number) {
    if (event) {
      const newDateList = dateList
      newDateList[i] = event.toDate()
      setDateList([...newDateList])
    }
  } */

  /*  useEffect(() => {
    props.handlerChange(notifications)
  }, [notifications]) */

  useEffect(() => {
    if (dateList.length === 1) {
      setIsFirst(true)
    } else {
      setIsFirst(false)
    }
  }, [isFirst, dateList])

  /* function handleCheckboxChange(v: string) {
    // TODO: call notificationChange
  }

  function handleAddNewDate(i: number, type: string) {
    if (dateList.length === i + 1) setIsLast(dateList.length)
    const date = new Date()
    setDateList([...dateList, date])

    // TODO: call addNotification
    addNotification(date, props.value)
  }

  function handleRemoveDate(i: number, type: string) {
    setDateList([...dateList.slice(0, dateList.length - 1)])
    if (dateList.length === i + 1) setIsLast(dateList.length)

    // TODO: call removeNotification
  } */

  useEffect(() => {
    if (!isChecked) setDateList([])
  }, [isChecked])

  const handleChecked = () => setIsChecked(!isChecked)

  const handleDate = useDebounce((e) => {
    if (e) {
      const newDate = e.toDate()
      setDate(newDate)
      const newDateNotificationToSend = { id: props.value, date: newDate }
      setDateList((prevDateList) =>
        [...prevDateList, newDateNotificationToSend].sort(
          (a, b) => a.date.getTime() - b.date.getTime()
        )
      )
    }
    // adding notificiation to send to BE
    addNotification(date, props.value)
  }, 950)

  const transformDate = (d: Date) =>
    new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0, 0)

  /*  const addDate = () => {
    if (dateList.some((d) => transformDate(d).getTime() - transformDate(date).getTime() === 0)) {
      return
    }
    setDateList([...dateList, date].sort((a, b) => a.getTime() - b.getTime()))
  } */

  const addDate = () => {
    if (
      dateList.some((d) => transformDate(d.date).getTime() - transformDate(date).getTime() === 0)
    ) {
      return
    }
    const newDateList = {
      id: props.value,
      date,
    }
    setDateList([...dateList, newDateList].sort((a, b) => a.date.getTime() - b.date.getTime()))
  }

  /*  const removeDate = (dateToRemove: Date) => setDateList(dateList.filter((d) => d !== dateToRemove)) */

  const removeDate = (dateToRemove: Date) => {
    setDateList(dateList.filter((d) => d.date !== dateToRemove))
  }

  return (
    <>
      <Box className={styles.row}>
        <Checkbox style={{ height: '25px', width: '25px' }} onChange={handleChecked} />
        <p className={styles.notificationTitle2}>{props.value}</p>
        {!isXS && (
          <p className={styles.notifyAt + ' ' + (!isChecked ? styles.notifyAtDisabled : '')}>
            {t('notifyAt')}
          </p>
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
          <DateTimePicker
            PopperProps={{
              sx: {
                '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                  fontFamily: 'Poppins',
                  textTransform: 'capitalize',
                },
                '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                  fontFamily: 'Poppins',
                },
                '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                  fontFamily: 'Poppins',
                },
                '& .css-3eghsz-PrivatePickersYear-button': {
                  fontFamily: 'Poppins',
                },
              },
            }}
            key={'date'}
            className={styles.datePicker2}
            onError={(reason, value) => {
              switch (reason) {
                case 'invalidDate':
                  setDateTimePickerError(t('invalidDateMessage'))
                  break
                case 'minDate':
                  setDateTimePickerError(t('minDateMessage'))
                  break
              }
            }}
            renderInput={(props) => (
              <TextField
                sx={{
                  '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': {
                    paddingRight: '17px',
                    padding: '8px',
                  },
                  '.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                    fontFamily: 'Poppins',
                    padding: '8px',
                  },
                }}
                size={'small'}
                helperText={props.error && DateTimePickerError}
                {...props}
              />
            )}
            inputFormat="DD/MM/YYYY HH:mm"
            onChange={handleDate}
            value={date}
            minDate={dayjs(new Date())}
            disabled={!isChecked}
            label={''}
          />
        </LocalizationProvider>
        {isChecked && (
          <img
            className={styles.addDate}
            onClick={() => {
              addNotification(date, props.value)
              addDate()
            }}
            src={icoPlus}
            alt={'icoPlus'}
          />
        )}
      </Box>
      <Box className={styles.containerNotification}>
        {dateList.length > 0 && (
          <Box display={'inline-table'} className={styles.chipBox}>
            {dateList.map((d) => (
              <Chip
                key={d.id}
                className={styles.chip}
                label={dateToDateTimeWithoutSecondsString(d.date)}
                onDelete={() => {
                  props.removeNotification(d.date, d.id)
                  removeDate(d.date)
                }}
              />
            ))}
          </Box>
        )}
      </Box>
    </>
  )
}
