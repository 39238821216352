import React, { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import {
  emptyUserCircleWithCircleAndUserDTO,
  UserCircleWithCircleAndUserDTO,
} from 'modules/user-circle/models/UserCircleWithCircleAndUserDTO'
import { UserCircleWithRelaters } from 'modules/user-circle/models/UserCircleWithRelaters'
import { getUserContainer } from 'container/user-module'
import { CSRF_KEY, ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { getUserCircleContainer } from 'container/user-circle-module'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from 'modules/user-circle'
import { UserCircleWithCircleAndUser } from 'modules/user-circle/models/UserCircleWithCircleAndUser'
import { emptyList, ItemList } from 'common/models/ItemList'
import { useGlobalContext } from '../GlobalRoleContext'
import { Query, QueryParam } from 'common/api/Query'
import { UserCircleQuery } from 'modules/user-circle/models/UserCircle'

const SELECTED_USER_CIRCLE_KEY = 'selected user circle'

const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

type NewCircleContextProviderProps = {
  children: ReactNode
}

export type CircleContextProps = {
  selectedUserCircle?: UserCircleWithCircleAndUserDTO
  setSelectedUserCircle: Dispatch<SetStateAction<UserCircleWithCircleAndUserDTO | undefined>>
  selectedUserCircleParticipants: UserCircleWithRelaters | undefined
  setSelectedUserCircleParticipants: Dispatch<SetStateAction<UserCircleWithRelaters | undefined>>
  userCircles: ItemList<UserCircleWithCircleAndUser>
}

export const NewCircleConfigContext = React.createContext<CircleContextProps>({
  selectedUserCircle: emptyUserCircleWithCircleAndUserDTO(),
  setSelectedUserCircle: () => {},
  selectedUserCircleParticipants: undefined,
  setSelectedUserCircleParticipants: () => {},
  userCircles: emptyList(),
})

export const NewCircleContextProvider: React.FC<NewCircleContextProviderProps> = ({ children }) => {
  const { role } = useGlobalContext()
  const loggedUser = loggedUserService.get()
  const [selectedUserCircle, setSelectedUserCircle] = useState<
    UserCircleWithCircleAndUserDTO | undefined
  >(undefined)
  const [selectedUserCircleParticipants, setSelectedUserCircleParticipants] = useState<
    UserCircleWithRelaters | undefined
  >(undefined)

  const [userCircles, setUserCircles] = useState<ItemList<UserCircleWithCircleAndUser>>(emptyList())

  // Store in local to restore when reload
  useEffect(() => {
    loadCircle()
  }, [])

  useEffect(() => {
    loggedUserService.getCSRFToken().subscribe((res) => {
      if (res) {
        sessionStorage.setItem(CSRF_KEY, res)
      }
      userCircleService
        .getByUserIDWithRelaters(selectedUserCircle?.user.id ?? '')
        .subscribe((userCircles) => {
          if (!userCircles) return
          const activeUserCircleParticipants = userCircles.find(
            (userCircle) => userCircle.circleID === selectedUserCircle?.circle.id
          )
          setSelectedUserCircleParticipants(activeUserCircleParticipants)
        })
    })
  }, [selectedUserCircle])

  useEffect(() => {
    if (!loggedUser || role.length === 0) return
    const query = new Query<UserCircleQuery>({
      pager: { offset: 0, limit: 100 },
      query: [new QueryParam('includesUserID', loggedUser.id)],
      sort: [{ field: 'userID', desc: true }],
    })
    userCircleService.getFilteredListWithCircleAndUser(query).subscribe((res) => {
      if (!res) return
      const newRes = filterCirclesByRole(res.items)
      setUserCircles(newRes)
      if (newRes.count === 1) {
        setSelectedUserCircle(newRes.items[0].toDTO())
      }
    })
  }, [loggedUser, role])

  useEffect(() => {
    saveCircle()
  }, [selectedUserCircle])

  const filterCirclesByRole = (
    res: UserCircleWithCircleAndUser[]
  ): ItemList<UserCircleWithCircleAndUser> => {
    const data = role.some((r) => r.name === 'patient')
      ? res.filter((item) => item.user.id === loggedUser?.id)
      : res.filter((item) => item.user.id !== loggedUser?.id)
    return {
      count: data.length,
      items: data,
      raw: res,
    }
  }

  const saveCircle = () => {
    if (selectedUserCircle) {
      localStorage.setItem(SELECTED_USER_CIRCLE_KEY, JSON.stringify(selectedUserCircle))
    }
  }

  const loadCircle = () => {
    const localUserCircle = JSON.parse(
      localStorage.getItem(SELECTED_USER_CIRCLE_KEY) ?? 'null'
    ) as UserCircleWithCircleAndUserDTO

    if (localUserCircle) {
      setSelectedUserCircle(localUserCircle)
    }
  }

  return (
    <NewCircleConfigContext.Provider
      value={{
        selectedUserCircle,
        setSelectedUserCircle,
        selectedUserCircleParticipants,
        setSelectedUserCircleParticipants,
        userCircles,
      }}
    >
      {children}
    </NewCircleConfigContext.Provider>
  )
}

// custom hook creation for the momment, move later on
export const useCircleConfiguration = () => {
  const context = useContext(NewCircleConfigContext)
  if (context === undefined) {
    throw new Error('useCircleConfiguration must be used within a NewCircleConfigContextProvider')
  }
  return context
}
