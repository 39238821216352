import { Avatar, Box, Skeleton } from '@mui/material'
import styles from './ShortDescription.module.css'
import womanIcon from 'assets/chatIcons/woman.svg'

export function ShortDescriptionSkeleton(): JSX.Element {
  return (
    <Box className={styles.conversation} display="flex">
      <Box className={styles.avatarWrapper} flexDirection="column">
        <Avatar
          src={womanIcon}
          style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0' }}
        />
      </Box>
      <Box className={styles.conversationBlock}>
        <Skeleton />
      </Box>
    </Box>
  )
}
