import { emptyUserDTO, User } from 'modules/users/models/User'
import { Treatment } from './Treatment'
import { v4 as uuidv4 } from 'uuid'

export interface TreatmentDTO {
  id: string | undefined
  name: string
  date: Date
  endDate: Date | undefined
  duration: number
  frequency: number
  actualDose: number
  newDose: number | undefined
  actualDoseUnit: string
  newDoseUnit: string | undefined
  changeOfDose: number
  reason: string | undefined
  creatorID: string
  creatorName: string
  userCircleID: string
  administrationMethod: number
  reminderTime: number
  reminderApp: boolean
  reminderEmail: boolean
  reminderSMS: boolean
  currently: boolean
}

export function emptyTreatmentDTO(userCircleID: string) {
  return {
    id: uuidv4(),
    name: '',
    date: new Date(),
    endDate: new Date(),
    duration: 1,
    frequency: 0,
    actualDose: 0,
    newDose: 0,
    actualDoseUnit: '',
    newDoseUnit: '',
    changeOfDose: 0,
    reason: '',
    creatorID: '',
    creatorName: '',
    userCircleID,
    administrationMethod: -1,
    reminderTime: 0,
    reminderApp: false,
    reminderEmail: false,
    reminderSMS: false,
    currently: false,
  }
}

export function fromModel(d: Treatment): TreatmentDTO {
  return {
    id: d.id,
    name: d.name,
    date: d.date,
    endDate: d.endDate,
    duration: d.duration,
    frequency: d.frequency,
    actualDose: d.actualDose,
    newDose: d.newDose,
    actualDoseUnit: d.actualDoseUnit,
    newDoseUnit: d.newDoseUnit,
    changeOfDose: d.changeOfDose,
    reason: d.reason,
    creatorID: d.creatorID,
    creatorName: d.creatorName,
    userCircleID: d.userCircleID,
    administrationMethod: d.administrationMethod,
    reminderTime: d.reminderTime,
    reminderApp: d.reminderApp,
    reminderEmail: d.reminderEmail,
    reminderSMS: d.reminderSMS,
    currently: d.currently,
  }
}

export function toModel(d: TreatmentDTO): Treatment {
  return new Treatment(d)
}
