import { useTranslation } from 'react-i18next'
import React, { FormEvent, useEffect, useState } from 'react'
import { FormCard } from 'components/form-card/FormCard'
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import {
  emptyGrowthMonitoringDTO,
  fromModel,
  GrowthMonitoringDTO,
} from 'modules/patient-data/models/GrowthMonitoringDTO'
import { getPatientDataContainer } from 'container/patient-data-module'
import { GrowthMonitoringService } from 'modules/patient-data/services/GrowthMonitoringService'
import { GROWTH_MONITORING_API_KEY } from 'modules/patient-data/container'
import { useNavigate } from 'react-router-dom'
import { ROUTE_MEDICAL_DATA } from 'routes/routes-constants'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import {
  GrowthMonitoringType,
  growthMonitoringTypes,
  growthMonitoringUnitTypes,
} from 'modules/patient-data/enums/GrowthMonitoringType'
import genericStyle from 'common/utils/generic.module.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

const patientDataContainer = getPatientDataContainer()
const growthMonitoringService =
  patientDataContainer.get<GrowthMonitoringService>(GROWTH_MONITORING_API_KEY)

type GrowthMonitoringEditorProps = {
  id?: string
}

const growthTypes = growthMonitoringTypes()
const growthUnitTypes = growthMonitoringUnitTypes()

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 180,
    },
    label: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '16px',
    },
  })
)

export const GrowthMonitoringEditor = (props: GrowthMonitoringEditorProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const classes = useStyles()

  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const { selectedUserCircle } = useCircleConfiguration()

  const [growthMonitoring, setGrowthMonitoring] = useState<GrowthMonitoringDTO>(
    emptyGrowthMonitoringDTO()
  )

  useEffect(() => {
    if (props.id) {
      growthMonitoringService.getByID(props.id).subscribe((res) => {
        if (res) {
          const dm = fromModel(res)
          setGrowthMonitoring(dm)
        }
      })
    }
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setGrowthMonitoring(Object.assign({ ...growthMonitoring }, { [e.target.name]: e.target.value }))

  const handleType = (event: SelectChangeEvent<GrowthMonitoringType>) => {
    const growthType = +(event.target.value as number)
    setGrowthMonitoring(Object.assign({ ...growthMonitoring }, { type: growthType }))
  }

  const handleDate = (e: any) => {
    if (e) {
      setGrowthMonitoring(Object.assign({ ...growthMonitoring }, { date: e.toDate() }))
    }
  }

  const goBack = () => navigate(ROUTE_MEDICAL_DATA)

  const saveGrowthMonitoring = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const newGrowth = Object.assign(
      { ...growthMonitoring },
      { userID: selectedUserCircle?.user.id, value: parseFloat(growthMonitoring.value + '') }
    )
    if (props.id) {
      growthMonitoringService.update(newGrowth).subscribe(() => goBack())
    } else {
      growthMonitoringService.add(newGrowth).subscribe(() => goBack())
    }
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <form onSubmit={saveGrowthMonitoring}>
          <FormControl className={classes.formControl}>
            <Box mb={3}>
              <p style={{ margin: 'auto auto 5px 0' }}>{t('selectType')}</p>
              <Select
                key={'category'}
                id={'category'}
                variant={'outlined'}
                value={growthMonitoring.type}
                onChange={handleType}
                style={{ width: '220px', fontFamily: 'Poppins, sans-serif' }}
                required
              >
                {Object.keys(growthTypes).map((k) => (
                  <MenuItem
                    style={{ fontFamily: 'Poppins, sans-serif', display: 'flex' }}
                    key={k}
                    value={k}
                  >
                    {t(growthTypes[k as unknown as GrowthMonitoringType])}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </FormControl>
          <Box mb={3}>
            <p style={{ margin: 'auto auto 5px 0' }}>
              {t('value')} ({growthUnitTypes[growthMonitoring.type]})
            </p>
            <TextField
              key={'value'}
              id={'value'}
              name={'value'}
              variant={'standard'}
              sx={{
                '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                  fontFamily: 'Poppins',
                },
              }}
              value={growthMonitoring.value}
              type={'number'}
              inputProps={{ min: '1', step: '0.01' }}
              style={{ width: '220px', fontFamily: 'Poppins, sans-serif' }}
              onChange={handleInput}
              rows={undefined}
              required={true}
            />
          </Box>
          <Box mb={3}>
            <p style={{ margin: 'auto auto 16px 0' }}>{t('date')}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
              <DatePicker
                key={'startDate'}
                onError={(reason, value) => {
                  switch (reason) {
                    case 'invalidDate':
                      setDateTimePickerError(t('invalidDateMessage'))
                      break
                    case 'minDate':
                      setDateTimePickerError(t('minDateMessage'))
                      break
                  }
                }}
                renderInput={(props) => (
                  <TextField
                    size={'small'}
                    helperText={props.error && DateTimePickerError}
                    {...props}
                  />
                )}
                inputFormat="DD/MM/YYYY"
                value={growthMonitoring.date}
                onChange={(e) => handleDate(e)}
                maxDate={new Date()}
              />
            </LocalizationProvider>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={goBack}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={(e) => {
                saveGrowthMonitoring(e)
                navigate(ROUTE_MEDICAL_DATA)
              }}
            />
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
