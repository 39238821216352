import { Alert, Box, Modal } from '@mui/material'
import { Header } from 'components/header/Header'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import style from '../../AddNewCircle.module.css'
import exitModal from 'assets/newCircle/exit_icon.svg'
import addButton from 'assets/esfera/buttons/add-icon.svg'
import AddNewResource from './AddNewResource'
import { emptyFileDTO, FileDTO, fromModel } from 'modules/files/models/FileDTO'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { Query, QueryParam } from 'common/api/Query'
import { IsDirectoryType } from 'modules/files/enums/IsDirectoryType'
import { getFileContainer } from 'container/file-module'
import { FILE_SERVICE_KEY, IFileService } from 'modules/files'
import { CircleDTO, emptyCircleDTO } from 'modules/circle/models/CircleDTO'
import { getCircleContainer } from 'container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from 'modules/circle'
import { FileQuery } from 'modules/files/models/File'
import { createFiles } from 'common/utils/file'
import { LoadingSpinnerMini } from 'components/loading-spinner/LoadingSpinner'
interface Props {
  open: boolean
  onClose: () => void
  resourceID?: string
  handleEditResource?: () => void
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const resourcesService = getFileContainer().get<IFileService>(FILE_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

const ModalAddNewResource: React.FC<Props> = ({ open, onClose, resourceID }) => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [files, setFiles] = useState<FileDTO[]>([])
  const [errorMessage] = useState<string>()
  const [circleDirectories, setCircleDirectories] = useState<FileDTO[]>([])
  // get circleDraftId from local storage
  const circleDraftId = localStorage.getItem('circleDraftID')
  const [circleDraft, setCircleDraft] = useState<CircleDTO>(emptyCircleDTO())

  useEffect(() => {
    if (!circleDraftId || circleDraft.name) return
    circleService.getByCircleDraftID(circleDraftId).subscribe((circle) => {
      if (!circle) {
        return
      }
      setCircleDraft(circle)
    })
  }, [])

  useEffect(() => {
    if (!circleDraft.name) return
    resourcesService
      .getFilteredItems(
        new Query<FileQuery>({
          query: [
            new QueryParam<FileQuery>('isDir', IsDirectoryType.Directory.toString()),
            new QueryParam<FileQuery>('circles', circleDraft.name),
          ],

          sort: [{ field: 'name' }],
        })
      )
      .subscribe((files) => {
        setCircleDirectories(files.items.map((file) => fromModel(file)))
      })

    if (!resourceID) return
    resourcesService.getByID(resourceID).subscribe((resource) => {
      if (!resource) return
      setFiles([fromModel(resource)])
    })
  }, [open])

  const handlerChangeAttachFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !loggedUser) {
      return
    }

    const articleId = null
    const messageId = null
    const eventId = null
    const isVisible = true
    const folder = ''
    const isUploaded = false
    const parent = null
    const userCircleID = circleDraft.id

    setIsLoading(true)
    const fs = await createFiles(
      event,
      loggedUser.id,
      false,
      folder,
      articleId,
      messageId,
      eventId,
      isVisible,
      isUploaded,
      parent,
      userCircleID
    ).finally(() => {
      setIsLoading(false)
    })

    const fsToDTO = fromModel(fs[0])
    const selectedFile = emptyFileDTO(loggedUser?.id, '', true)

    selectedFile.name = fsToDTO.name.toLowerCase()
    selectedFile.circles = circleDraft.name
    selectedFile.userCircleID = circleDraftId ?? ''
    selectedFile.specialities = circleDraft.specialities.join(', ') ?? ''
    selectedFile.isCircleConfResource = true

    setFiles([...files, selectedFile])
  }

  const handleAddResource = () => {
    document.getElementById('fileInput')!.click()
  }

  const handleSave = async () => {
    // Case of editing existing resource-patientView

    if (resourceID) {
      const updatePromises = files.map(
        async (file) =>
          await new Promise((resolve) => {
            resourcesService.update(file).subscribe((response) => {
              resolve(response)
            })
          })
      )

      await Promise.all(updatePromises)

      setFiles([])
      onClose()
      return
    }

    // In case of new resource-patientView
    const addPromises = files.map(
      async (file) =>
        await new Promise((resolve) => {
          resourcesService.add(file).subscribe((response) => {
            resolve(response)
          })
        })
    )

    await Promise.all(addPromises)
    setFiles([])
    onClose()
  }

  const handleDeleteResource = (index: number) => {
    setFiles((prevState) => {
      const newState = [...prevState]
      newState.splice(index, 1)
      return newState
    })
  }
  const handleOnclose = () => {
    setFiles([]) // reset files state
    onClose()
  }

  const handleCheckBoxSelected = (index: number, checkboxSelected: number) => {
    const updateFiles = [...files]
    // if checkbox is already included remove it
    if (files[index]?.checkBoxesSelected?.includes(checkboxSelected.toString())) {
      const replaceString = updateFiles[index]?.checkBoxesSelected ?? ''
      updateFiles[index].checkBoxesSelected = replaceString.replace(
        ',' + checkboxSelected.toString(),
        ''
      )
      // case only one item delete without ","
      updateFiles[index].checkBoxesSelected = replaceString.replace(checkboxSelected.toString(), '')
    } else {
      // add checkbox
      updateFiles[index].checkBoxesSelected =
        updateFiles[index].checkBoxesSelected + ',' + checkboxSelected.toString()
    }

    // remove "," of the first position
    if (updateFiles[index].checkBoxesSelected?.charAt(0) === ',') {
      updateFiles[index].checkBoxesSelected = updateFiles[index].checkBoxesSelected?.substring(1)
    }
    setFiles(updateFiles)
  }

  return (
    <Modal open={open} onClose={onClose} className={style.modalOverFlow}>
      <Box className={style.modalContainer}>
        <Box padding="1rem">
          <Box className={style.contentHeaderExitIcon}>
            <img
              style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
              onClick={handleOnclose}
              src={exitModal}
            />
          </Box>
          <Box className={style.contentHeader}>
            <Header label={t('addNewResource')} />
          </Box>
          <p style={{ fontFamily: 'sans-serif !important' }}>{t('newResourceModalTitle')}</p>
          <input
            type="file"
            id="fileInput"
            style={{ display: 'none' }}
            onChange={handlerChangeAttachFile}
          />

          <Box>
            {files.map((fileDto, index) => (
              <AddNewResource
                key={index}
                fileName={fileDto.name}
                fileParent={fileDto.parent ?? ''}
                directories={circleDirectories}
                newFile={fileDto}
                handleCheckBoxSelected={(type) => handleCheckBoxSelected(index, type)}
                handleRemoveResource={() => handleDeleteResource(index)}
                setRemoveDirectory={() =>
                  setFiles((prevState) => {
                    const newState = [...prevState]
                    newState[index].parent = ''
                    newState[index].basePath = ''
                    return newState
                  })
                }
                setResourceDirectory={(newDirectory) =>
                  setFiles((prevState) => {
                    const newState = [...prevState]
                    newState[index].parent = newDirectory.id
                    newState[index].basePath = newDirectory.basePath
                    return newState
                  })
                }
                setFileName={(newName) =>
                  setFiles((prevState) => {
                    if (!newName.includes('.')) return prevState
                    // avoid deleting extension
                    const extension = prevState[index].name.split('.').pop()
                    newName = newName.split('.')[0] + '.' + extension
                    const newState = [...prevState]
                    newState[index].name = newName
                    return newState
                  })
                }
              />
            ))}
          </Box>
          <Box display="flex" flexDirection="column" gap={1} mt={2}>
            <Box
              display={files.length > 0 ? 'none' : 'flex'}
              justifyContent="end"
              borderBottom="3px solid #68b3e0"></Box>
            <Box display="flex" alignItems="row" justifyContent="end">
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('save')}
                marginStartIcon={'10px'}
                startIcon={addButton}
                handler={async () => await handleSave()}
                vertical={true}
              />
            </Box>
            {errorMessage && (
              <Box mb={3}>
                <Alert severity="warning" key="errorMessage" id="errorMessage">
                  {t(errorMessage)}
                </Alert>
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="end"
              alignItems="center"
              gap={2}>
              {isLoading && (
                <Box width={40} height={40}>
                  <LoadingSpinnerMini />
                </Box>
              )}
              {!resourceID && ( // if not editing existing resource-patientView
                <AppButton
                  theme={ButtonTheme.newEvent}
                  type={'button'}
                  label={t('addNewResource')}
                  marginStartIcon={'10px'}
                  startIcon={addButton}
                  handler={handleAddResource}
                  vertical={true}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
export default ModalAddNewResource
