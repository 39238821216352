import React, { useEffect, useMemo, useState } from 'react'
import { Box, Modal, useMediaQuery } from '@mui/material'
import { ShortDescription } from './ShortDescription'
import { Message } from 'modules/messenger_consulta/models/Message'
import { Searcher } from './Searcher'
import { Messages } from './Messages'
import { Header } from './Header'
import { Sender } from './Sender'
import { v4 as uuidv4 } from 'uuid'
import 'dayjs'
import style from './Messenger.module.css'
import { File } from 'modules/files/models/File'
import { reduceString } from 'common/utils/strings'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { Query, QueryParam } from 'common/api/Query'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { getUserContainer } from 'container/user-module'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { getTeleConsultaContainer } from 'container/tele-consulta-module'
import { TeleConsultaService } from 'modules/teleconsultation/services/TeleConsultaService'
import { TELECONSULTA_SERVICE_KEY } from 'modules/teleconsultation'
import dayjs from 'dayjs'
import { TeleConsulta, TeleConsultaUserGender } from 'modules/teleconsultation/models/TeleConsulta'
import { ClosedStatus } from 'modules/teleconsultation/enums/ClosedStatus'
import {
  fromListModel,
  TeleconsultaDTO,
  toModel,
} from 'modules/teleconsultation/models/TeleconsultaDTO'
import { ModalTeleconsultationForm } from './ModalTeleconsultationForm'
import { ObjectForm } from '../query/ConversationEditor'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import open_message_icon from 'assets/query/open_message.svg'
import { finalize } from 'rxjs/operators'
import womanIcon from 'assets/chatIcons/woman.svg'
import maleIcon from 'assets/chatIcons/male.svg'
import { SwitchCustom } from './SwitchStyle'
import { ShortDescriptionSkeleton } from './ShortDescriptionSkeleton'
import { useGlobalContext } from 'common/utils/GlobalRoleContext'
import { Roles } from 'modules/users/enums/Roles'

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const teleConsultaService =
  getTeleConsultaContainer().get<TeleConsultaService>(TELECONSULTA_SERVICE_KEY)

export type QueryProps = {
  conversationID?: string
}

export function TeleConsultation(props: QueryProps): JSX.Element {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const { selectedUserCircle } = useCircleConfiguration()
  const [currentTeleconsultation, setCurrentTeleconsultation] = useState<TeleconsultaDTO>()
  const [teleconsultationCollection, setTeleconsultationCollection] = useState<TeleconsultaDTO[]>(
    []
  )
  const { role } = useGlobalContext()
  const [originalConversations, setOriginalConversations] = useState<TeleconsultaDTO[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showClosedQuery, setShowClosedQuery] = useState<boolean>(false)
  const [openTeleconsultationForm, setOpenTeleconsultationForm] = useState<boolean>(false)
  const isPatient = role.some((r) => r.name === Roles.Patient)

  const isFormAnswered = useMemo(() => {
    if (!currentTeleconsultation || !currentTeleconsultation.messages) return false

    const TEXT = t('anyCrisesSinceYourLastVisit?')
    return currentTeleconsultation.messages.some((m) => m.text.includes(TEXT))
  }, [currentTeleconsultation])

  const isXS = useMediaQuery('(max-width: 599px)')

  const [object, setObject] = useState<ObjectForm>({
    whatHappen: [],
    currentTreatment: '',
    timeWithoutCrisis: '',
    symptom: [],
    typeCrisis: '',
    durationCrisis: '',
    healthcare: '',
    medicationAdministered: '',
    timeWithoutAwareness: '',
    nextAppointment: null,
    dontKnowAppointment: '',
  })

  useEffect(() => {
    if (props.conversationID) {
      teleConsultaService.getByID(props.conversationID).subscribe((res) => {
        if (res) {
          setCurrentTeleconsultation(res)
        }
      })
    }
  }, [])

  useEffect(() => {
    setTeleconsultationCollection([])
    setCurrentTeleconsultation(undefined)
    getTeleconsultas()
  }, [showClosedQuery])

  const getTeleconsultas = () => {
    setIsLoading(true)
    const query = [
      new QueryParam<TeleConsulta>('patient', selectedUserCircle?.user.id ?? ''),
      new QueryParam<TeleConsulta>('userCircleID', selectedUserCircle?.id ?? ''),
      new QueryParam<TeleConsulta>(
        'closed',
        showClosedQuery ? ClosedStatus.Closed : ClosedStatus.Opened
      ),
    ]
    teleConsultaService
      .getFilteredItems(
        new Query({
          query,
          sort: [{ field: 'date', desc: true }],
        })
      )
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe((res) => {
        setTeleconsultationCollection(fromListModel(res))
        setOriginalConversations(res)
      })
  }

  const handleFormMessage = (m: string) => {
    const message = new Message({
      id: uuidv4(),
      text: m,
      author: loggedUser?.id ?? '',
      createdAt: new Date(),
      isPinned: false,
      reactions: [],
      message2Users: [],
      consultaID: currentTeleconsultation?.id ?? '',
      rootID: '',
      parentID: '',
    })
    if (currentTeleconsultation) {
      const conversation = cloneDeep<TeleConsulta>(toModel(currentTeleconsultation))

      conversation.addMessage(message)
      // conversationsService.update(conversation).subscribe((_) => {
      setCurrentTeleconsultation(conversation)
      teleConsultaService.addMessage(message).subscribe()
    }
  }
  const handleObject = (objt: ObjectForm) => {
    setObject(objt)
  }

  const orderMessagesByCreationDate = (messages: Message[]): Message[] => {
    return [...messages].sort(
      (m1, m2) => new Date(m1.createdAt).getTime() - new Date(m2.createdAt)?.getTime()
    )
  }

  const handleShortDescription = (conversationID: string | undefined) => {
    const conversation = teleconsultationCollection.find((c) => c.id === conversationID)
    if (conversation) {
      conversation.unreadMessages = 0
      // FIXME problem with async setState currentTeleconsultation
      setCurrentTeleconsultation(
        conversation.id === currentTeleconsultation?.id ? undefined : conversation
      )
    }
  }

  const handleSearch = (s: string) => {
    const filteredConversations = originalConversations?.filter((c) => {
      const name = c.name.toLowerCase()
      return name.includes(s.toLowerCase())
    })
    if (filteredConversations) {
      setTeleconsultationCollection(filteredConversations)
    }
  }

  const handleSender = (m: Message, f: File[]) => {
    if (currentTeleconsultation) {
      f.forEach((file) => m.addFile(file.id ?? ''))
      const conversation = cloneDeep<TeleConsulta>(toModel(currentTeleconsultation))

      conversation.addMessage(m)
      // conversationsService.update(conversation).subscribe((_) => {
      setCurrentTeleconsultation(conversation)
      replaceConversationCollection(conversation)
      // })
      teleConsultaService.addMessage(m).subscribe()
    }
  }

  function replaceConversationCollection(conversation: TeleConsulta) {
    const conversations = [...teleconsultationCollection]

    const index = conversations.findIndex((c) => c.id === conversation.id)
    conversations.splice(index, 1, conversation)

    setTeleconsultationCollection(conversations)
  }

  const reverseConversationCollection = () => {
    setTeleconsultationCollection([...teleconsultationCollection.reverse()])
  }

  const handleNewConversation = (): void => {
    if (selectedUserCircle) {
      const professional =
        selectedUserCircle?.profSmsID?.length > 0 ? selectedUserCircle?.profSmsID[0] : ''
      const newTeleconsulta = new TeleConsulta({
        id: uuidv4(),
        name: t('teleconsultaHeader'),
        description: selectedUserCircle?.user?.name + ' ' + dayjs().format('DD/MM/YYYY'),
        messages: [],
        patient: selectedUserCircle.user.id,
        professional: professional ?? '',
        closed: ClosedStatus.Opened,
        unreadMessages: 0,
        userCircleID: selectedUserCircle?.id ?? '',
        date: new Date(),
        userGender: selectedUserCircle.user.gender,
      })

      teleConsultaService.add(newTeleconsulta).subscribe((res) => {
        if (!showClosedQuery) {
          const newCollection = cloneDeep(teleconsultationCollection)
          newCollection.push(newTeleconsulta)
          setTeleconsultationCollection(newCollection)
          setCurrentTeleconsultation(res)
        } else {
          setShowClosedQuery(false)
        }
      })
    }
  }

  const handleCloseQuery = () => {
    if (currentTeleconsultation) {
      currentTeleconsultation.closed =
        currentTeleconsultation.closed === ClosedStatus.Closed
          ? ClosedStatus.Opened
          : ClosedStatus.Closed

      teleConsultaService
        .update(toModel(currentTeleconsultation))
        .pipe(finalize(() => getTeleconsultas()))
        .subscribe(() => {
          setCurrentTeleconsultation(undefined)
        })
    }
  }

  const handlerBacktoList = () => {
    setCurrentTeleconsultation(undefined)
  }

  const handleSwitchChange = () => {
    setShowClosedQuery(!showClosedQuery)
  }

  return (
    <div className={style.messengerContainer}>
      <Box className={style.backButton}>
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={handlerBacktoList}
          disabled={isLoading}
        />
      </Box>
      <Box
        component="section"
        className={!isXS || !currentTeleconsultation ? style.conversationWidget : style.displayNone}
      >
        <Box className={style.searcherContainer}>
          <Searcher
            handler={handleSearch}
            reverse={reverseConversationCollection}
            handleNewConversation={handleNewConversation}
          />
          {!loggedUser?.roles.includes('patient') && (
            <>
              <Box className={style.switchButtonContainer}>
                <p>{t('showQueriesClosed')}</p>
                <SwitchCustom
                  checked={showClosedQuery}
                  onClick={handleSwitchChange}
                  disabled={isLoading}
                />
              </Box>
              <Box className={style.addNewConversationContainer}>
                <AppButton
                  label={t('newConversation1')}
                  type={'button'}
                  theme={ButtonTheme.newEvent}
                  handler={handleNewConversation}
                  disabled={isLoading}
                />
              </Box>
            </>
          )}
        </Box>
        {isLoading && (
          <Box className={style.chatList} component="section">
            {Array.from({ length: 3 }).map((_, i) => {
              return <ShortDescriptionSkeleton key={i} />
            })}
          </Box>
        )}
        {!isLoading && (
          <Box className={style.chatList} component="section">
            {teleconsultationCollection.map((c, i) => {
              const messages = orderMessagesByCreationDate(c.messages ?? [])
              const lengthLimit = 70
              const lastMessage =
                c.messages?.length === 0
                  ? ''
                  : reduceString(messages[messages.length - 1].text, lengthLimit)

              return (
                <ShortDescription
                  key={c.id}
                  conversationID={c.id}
                  conversationName={c.name}
                  conversationDescription={c.description}
                  avatarUrl={c.userGender === TeleConsultaUserGender.female ? womanIcon : maleIcon}
                  description={c.description}
                  lastMessage={lastMessage}
                  notReadMessages={c.unreadMessages}
                  handlerConversation={handleShortDescription}
                  isCurrentConversation={c.id === currentTeleconsultation?.id}
                />
              )
            })}
          </Box>
        )}
      </Box>
      {currentTeleconsultation && !isLoading && (
        <>
          <Box className={style.messengerWidget}>
            <Header
              headerName={t('teleconsultaHeader')}
              avatarUrl={undefined}
              creatorID={currentTeleconsultation.patient}
              description={currentTeleconsultation.description || ''}
              handleEdit={() => {}}
              handleDelete={() => {}}
              handleCloseQuery={handleCloseQuery}
              currentConversation={currentTeleconsultation}
              conversationUsers={[]}
            />
            <Messages
              dayOfTeleconsultation={currentTeleconsultation.date}
              handleTeleconsultationForm={() => setOpenTeleconsultationForm(true)}
              messages={currentTeleconsultation.messages ?? []}
            />

            {!isXS && (
              <Box>
                <Sender
                  handler={handleSender}
                  conversationId={currentTeleconsultation.id ?? ''}
                  conversationClosed={currentTeleconsultation.closed === ClosedStatus.Closed}
                />
              </Box>
            )}
          </Box>
          {isXS && (
            <Box className={style.senderMobile}>
              <Sender
                handler={handleSender}
                conversationId={currentTeleconsultation.id ?? ''}
                conversationClosed={currentTeleconsultation.closed === ClosedStatus.Closed}
              />
            </Box>
          )}
        </>
      )}
      {(!currentTeleconsultation || isLoading) && (
        <Box className={style.emptyConversation}>
          <img
            className={style.imgCreateOrSelectConversation}
            src={open_message_icon}
            width={34}
            height={34}
            alt="Open conversation image"
          />
          <p className={style.labelCreateOrSelectConversation}>{t('createOrSelectQuery')}</p>
          <div className={style.orangeUnderscore}></div>
        </Box>
      )}
      {isPatient && !isFormAnswered && (
        <Modal
          open={openTeleconsultationForm}
          onClose={() => setOpenTeleconsultationForm(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalTeleconsultationForm
            handleCloseEpilepticCrisisModal={() => setOpenTeleconsultationForm(false)}
            saveConversation={() => {}}
            message={''}
            handleMessage={(m: string) => handleFormMessage(m)}
            handleObject={handleObject}
            object={object}
            errorMessage={''}
          />
        </Modal>
      )}
    </div>
  )
}
