import { getUserContainer } from 'container/user-module'
import { IUserService } from 'modules/users'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from 'modules/users'
import { useEffect, useState } from 'react'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const useIsRelated = () => {
  const loggedUser = loggedUserService.get()
  const { selectedUserCircle } = useCircleConfiguration()
  const [isRelated, setIsRelated] = useState<boolean>(false)

  useEffect(() => {
    if (!loggedUser || !selectedUserCircle) {
      return
    }
    if (!loggedUser.roles.includes('professionalSMS')) {
      setIsRelated(true)
    } else {
      userService.getUsersByDoctor(loggedUser.id).subscribe((res) => {
        res.forEach((element) => {
          if (element.id === selectedUserCircle.id) {
            setIsRelated(true)
          }
        })
        if (res.length === 0) {
          setIsRelated(true)
        }
      })
    }
  }, [loggedUser, selectedUserCircle])

  return isRelated
}

export const canSeeQuery = (): boolean => {
  const loggedUser = loggedUserService.get()
  const { selectedUserCircle } = useCircleConfiguration()

  if (!loggedUser || !selectedUserCircle) return false
  if (
    (typeof selectedUserCircle.profSmsID === 'string' &&
      selectedUserCircle.profSmsID === loggedUser.id) ||
    (typeof selectedUserCircle.familiarsID === 'string' &&
      selectedUserCircle.familiarsID === loggedUser.id) ||
    selectedUserCircle.user.id === loggedUser.id
  ) {
    return true
  }
  if (loggedUser.roles.some((role) => role.startsWith('manager'))) {
    return true
  }
  return (
    selectedUserCircle.profSmsID.includes(loggedUser.id) ||
    selectedUserCircle.familiarsID.includes(loggedUser.id)
  )
}
