import { useTranslation } from 'react-i18next'
import { Box, Modal, TextField } from '@mui/material'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import React, { useState } from 'react'
import {
  emptyPatientSymptomDTO,
  PatientSymptomDTO,
} from 'modules/patient-data/models/PatientSymptomDTO'
import { FormCard } from 'components/form-card/FormCard'
import { Header } from 'components/header/Header'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers'
import { TextFieldItem } from 'components/form-card/TextFieldItem'
import style from '../Editor.module.css'
import closeIcon from 'assets/esfera/buttons/delete.svg'
import { getPatientDataContainer } from 'container/patient-data-module'
import { PatientSymptomService } from 'modules/patient-data/services/PatientSymptomService'
import { PATIENT_SYMPTOM_SERVICE_KEY } from 'modules/patient-data'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

type EventModalProps = {
  open: boolean
  close: () => void
  onClick: (name: string, s?: string) => void
  id?: string
  name: string
  idIcon: string
}

const patientSymptomService = getPatientDataContainer().get<PatientSymptomService>(
  PATIENT_SYMPTOM_SERVICE_KEY
)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const NumberStoolsSymptomModal: React.FC<EventModalProps> = ({
  open,
  close,
  onClick,
  name,
  idIcon,
}) => {
  const { t } = useTranslation()
  const [patientSymptom, setPatientSymptom] = useState<PatientSymptomDTO>(emptyPatientSymptomDTO())
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const loggedUser = loggedUserService.get()
  const { selectedUserCircle } = useCircleConfiguration()
  const handleStartDate = (event: any) =>
    event &&
    setPatientSymptom(Object.assign({ ...patientSymptom }, { symptomStartDate: event.toDate() }))
  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPatientSymptom(Object.assign({ ...patientSymptom }, { comment: e.target.value }))
  }

  const handleSave = () => {
    const idSymptom = selectedUserCircle?.circle?.symptoms.find(
      (s) => s.idIcon === idIcon
    )?.idSymptom
    const newPatientSymptom = patientSymptom
    newPatientSymptom.symptomID = idSymptom ?? ''
    newPatientSymptom.creatorID = loggedUser?.id ?? ''
    newPatientSymptom.finishDate = patientSymptom.symptomStartDate
    newPatientSymptom.userID = selectedUserCircle?.user?.id ?? ''
    newPatientSymptom.userCircleID = selectedUserCircle?.id ?? ''
    newPatientSymptom.idIcon = idIcon

    patientSymptomService.add(newPatientSymptom).subscribe(() => {
      close()
    })
  }

  return (
    <Modal className={style.modal} open={open} onClose={close}>
      <Box className={style.modalContainer}>
        <FormCard>
          <>
            <Box className={style.exitIcon}>
              <img className={style.icon} src={closeIcon} alt={'close icon'} onClick={close} />
            </Box>
            <Header label={t('symptoms')} />
            <>
              <Box className={style.datePickerContainer} mt={2} mb={2}>
                <Box>
                  <p>{name}</p>
                </Box>
                <Box className={style.datePickerOnlyOne}>
                  <span style={{ fontFamily: 'Poppins', marginRight: '24px' }}>{t('date')}</span>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                    <Box sx={{ width: '180px' }}>
                      <DateTimePicker
                        PopperProps={{
                          sx: {
                            '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                              fontFamily: 'Poppins',
                              textTransform: 'capitalize',
                            },
                            '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                              fontFamily: 'Poppins',
                            },
                            '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                              fontFamily: 'Poppins',
                            },
                            '& .css-3eghsz-PrivatePickersYear-button': {
                              fontFamily: 'Poppins',
                            },
                          },
                        }}
                        onError={(reason, value) => {
                          switch (reason) {
                            case 'invalidDate':
                              setDateTimePickerError(t('invalidDateMessage'))
                              break
                            case 'minDate':
                              setDateTimePickerError(t('minDateMessage'))
                              break
                          }
                        }}
                        renderInput={(props) => (
                          <TextField
                            sx={{
                              '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': {
                                paddingRight: '17px',
                              },
                              '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                                fontFamily: 'Poppins',
                              },
                            }}
                            variant={'standard'}
                            helperText={props.error && DateTimePickerError}
                            {...props}
                          />
                        )}
                        inputFormat="DD/MM/YYYY HH:mm"
                        onChange={handleStartDate}
                        value={patientSymptom.symptomStartDate}
                      />
                    </Box>
                  </LocalizationProvider>
                </Box>
              </Box>

              <Box sx={{ marginBottom: '20px', width: '50%' }}>
                <TextFieldItem
                  field="depositionNumber"
                  value={patientSymptom.value}
                  label={'depositionNumber'}
                  type={'text'}
                  handleChange={(e) =>
                    setPatientSymptom(
                      Object.assign({ ...patientSymptom }, { value: e.target.value })
                    )
                  }
                  required={true}
                />
              </Box>

              <Box className={style.commentTextField}>
                <Box mb={1}>
                  <p>{t('comments')}</p>
                </Box>
                <TextFieldItem
                  field="comment"
                  value={patientSymptom.comment}
                  label={''}
                  type={'text'}
                  handleChange={handleInput}
                  rows={5}
                  required={false}
                />
              </Box>

              <Box className={style.buttonsContainer}>
                <AppButton
                  theme={ButtonTheme.whiteAndBlue}
                  type={'button'}
                  label={t('cancel')}
                  handler={close}
                />
                <AppButton
                  label={t('save')}
                  theme={ButtonTheme.NewPrimaryResponsive}
                  handler={handleSave}
                  type={'button'}
                />
              </Box>
            </>
          </>
        </FormCard>
      </Box>
    </Modal>
  )
}
