import { useTranslation } from 'react-i18next'
import { getNotificationContainer } from 'container/notification-module'
import { UserConfigService } from 'modules/notifications/services/UserConfigService'
import { USER_CONFIG_SERVICE_KEY } from 'modules/notifications'
import { getUserContainer } from 'container/user-module'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from 'modules/users'
import React, { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { emptyList, ItemList } from 'common/models/ItemList'
import { UserConfig } from 'modules/notifications/models/UserConfig'
import { SourceType, sourceTypes } from 'modules/notifications/enums/SourceType'
import { TransportType } from 'common/enums/TransportType'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CONFIGURATION } from 'routes/routes-constants'
import style from './NotificationsConfig.module.css'
import { finalize } from 'rxjs/operators'
import { forkJoin } from 'rxjs'

import checkboxCuadrado from 'assets/newCircle/checkbox-cuadrado.svg'
import checkBoxChecked from 'assets/newCircle/checkbox-cuadrado-ok.svg'
import checkBoxDeshabilitado from 'assets/newCircle/checkbox-deshabilitado.svg'

const userConfigService = getNotificationContainer().get<UserConfigService>(USER_CONFIG_SERVICE_KEY)
const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

const checkBoxes = [TransportType.Email, TransportType.SMS, TransportType.App]
const header = ['notificationType', 'email', 'sms', 'dashboard']
const sources = sourceTypes()

export function NotificationsConfig(props: any) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [userConfigs, setUserConfigs] = useState<ItemList<UserConfig>>(emptyList<UserConfig>())
  const [showcheckBoxes, setShowcheckBoxes] = useState<boolean[]>([])

  useEffect(() => {
    if (!loggedUser) return
    setIsLoading(true)

    const userConfig = userConfigService.getListByUserID(loggedUser.id)
    const userDetails = userService.getByID(loggedUser?.id ?? '')
    forkJoin(userConfig, userDetails)
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe(([config, details]) => {
        setUserConfigs(config)
        if (details) {
          setShowcheckBoxes([Boolean(details.email), Boolean(details.phone), true])
        }
      })
  }, [loggedUser])

  const isChecked = (st: SourceType, tt: TransportType): boolean =>
    !!userConfigs.items.find((uc) => uc.sourceType === st && uc.transportType === tt)

  const handleSave = () => {
    userConfigService.setConfigs(loggedUser?.id ?? '', userConfigs.items).subscribe()
    goBack()
  }
  const goBack = () => navigate(ROUTE_CONFIGURATION)

  const handleChange = (st: SourceType, tt: TransportType) => {
    if (userConfigs.items.find((uc) => uc.sourceType === st && uc.transportType === tt)) {
      setUserConfigs({
        count: userConfigs.count,
        items: userConfigs.items.filter((uc) => uc.sourceType !== st || uc.transportType !== tt),
      })
    } else {
      setUserConfigs({
        count: userConfigs.count,
        items: [
          ...userConfigs.items,
          new UserConfig({
            userID: loggedUser?.id ?? '',
            sourceType: st,
            transportType: tt,
          }),
        ],
      })
    }
  }

  return (
    <Box>
      <Card className={style.card}>
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {header.map((h, i) => {
                    return (
                      <TableCell key={i} className={i !== 0 ? style.cellCheck : ''}>
                        <h4 className={i === 0 ? style.headerName : style.headerTitle}>{t(h)}</h4>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(sources).map((k) => {
                  const st = parseInt(k) as SourceType
                  return (
                    <TableRow key={k}>
                      <TableCell scope="row">
                        <span className={style.cellValue}>{t(sources[st])}</span>
                      </TableCell>
                      {checkBoxes.map((v, i) => {
                        return (
                          <TableCell key={`row_${k}_${i}`} scope="row">
                            {isLoading && <Skeleton />}
                            {!isLoading && (
                              <Box className={style.cellCenter}>
                                <Checkbox
                                  style={{ width: '30px', height: '30px' }}
                                  icon={<img src={checkboxCuadrado} className={style.check} />}
                                  checkedIcon={
                                    <img src={checkBoxChecked} className={style.check} />
                                  }
                                  indeterminateIcon={
                                    <img src={checkBoxDeshabilitado} className={style.check} />
                                  }
                                  name="transportCheck"
                                  indeterminate={!showcheckBoxes[i]}
                                  onChange={() => handleChange(st, v)}
                                  checked={isChecked(st, v)}
                                />
                              </Box>
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Box className={style.actionBtns}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('save')}
              handler={handleSave}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
