import style from './CurrentNavHeaderEsfera.module.css'
import { Box, Chip, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Header } from '../header/Header'
import { getFirstWord } from 'common/utils/strings'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

export type CurrentNavHeaderEsferaProps = {
  title: string
  subtitle?: string
  circleSubtitle?: string
  headerTitle?: string
  divider?: boolean
  noCircleName?: boolean
}

export function CurrentNavHeaderEsfera(props: CurrentNavHeaderEsferaProps): JSX.Element {
  const { t } = useTranslation()
  const { selectedUserCircle } = useCircleConfiguration()

  return (
    <div className={style.currentNavHeaderContainer}>
      <div className={style.innerContainer}>
        <Box className={style.layoutContainer}>
          <div>
            <h1 className={style.titleComponent}>{props.title}</h1>
            {props.subtitle && (
              <Box display={'flex'} alignItems={'center'}>
                <Divider
                  style={{
                    width: 92,
                    marginRight: 20,
                    borderColor: 'rgba(33,84,193,0.37)',
                    borderBlockWidth: '1px',
                  }}
                />
                <Typography className={style.subtitle}>
                  {props.subtitle.slice(0, 1).toUpperCase() + props.subtitle.slice(1)}
                </Typography>
              </Box>
            )}
            {props.circleSubtitle && (
              <Box display={'flex'} alignItems={'center'}>
                <Divider
                  style={{
                    width: 92,
                    marginRight: 20,
                    borderColor: 'rgba(33,84,193,0.37)',
                    borderBlockWidth: '1px',
                  }}
                />
                <Typography className={style.subtitle}>
                  {props.circleSubtitle.slice(0, 1).toUpperCase() + props.circleSubtitle.slice(1)}
                </Typography>
              </Box>
            )}
          </div>
          {!props.subtitle && (
            <Box className={style.pathologyLayout}>
              {selectedUserCircle?.circle?.name && (
                <Chip label={selectedUserCircle?.circle?.name} className={style.pathologies} />
              )}
              {selectedUserCircle?.user.name && (
                <div className={style.circleContainer}>
                  <p>{getFirstWord(selectedUserCircle?.user.name)}</p>
                </div>
              )}
            </Box>
          )}
        </Box>
        {props.subtitle && !props.noCircleName && (
          <Header
            label={t(`${props.headerTitle}`)}
            circle={selectedUserCircle?.circle.name ?? ''}
            userName={selectedUserCircle?.user.name ?? ''}
            subtitle={props.subtitle}
            divider={props.divider}
          />
        )}
      </div>
    </div>
  )
}
