import React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material'
import dayjs from 'dayjs'
import styles from './MonthView.module.css'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import { useTranslation } from 'react-i18next'
import { capitalize } from '../../common/utils/strings'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

interface LoadingMonthViewProps {
  selectedDate?: Date
}

export function LoadingMonthView(props: LoadingMonthViewProps) {
  const { t } = useTranslation()
  const month = Array.from({ length: 30 }, (_, index) => index + 1)
  const ismobile = window.innerWidth < 599
  const weekDays = [
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday'),
    t('sunday'),
  ]
  const weekDaysMobile = [
    capitalize(t('monday').slice(0, 3)),
    capitalize(t('tuesday').slice(0, 3)),
    capitalize(t('wednesday').slice(0, 3)),
    capitalize(t('thursday').slice(0, 3)),
    capitalize(t('friday').slice(0, 3)),
    capitalize(t('saturday').slice(0, 3)),
    capitalize(t('sunday').slice(0, 3)),
  ]
  const upperCaseFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  return (
    <>
      <React.Suspense fallback={<LoadingSpinner />}>
        <div className={styles.arrowsMonth}>
          <button className={styles.styleArrow}>
            <ArrowBackIosIcon />
          </button>
          {upperCaseFirstLetter(
            dayjs(props.selectedDate).locale(navigator.language.split('-')[0]).format('MMMM YYYY')
          )}
          <button className={styles.styleArrow}>
            <ArrowForwardIosIcon />
          </button>
        </div>
        <TableContainer className={styles.tabRow}>
          <Table>
            {/* DIAS */}
            <TableHead className={styles.tabHeader}>
              {!ismobile ? (
                <TableRow>
                  {weekDays.map((i, d) => (
                    <TableCell className={styles.dateName} key={i + d}>
                      <Skeleton variant="rounded" className={styles.weekDay}></Skeleton>
                    </TableCell>
                  ))}
                </TableRow>
              ) : (
                <TableRow>
                  {weekDaysMobile.map((i, d) => (
                    <TableCell className={styles.dateName} key={i + d}>
                      <Skeleton variant={'rectangular'} className={styles.weekDay}></Skeleton>
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {month.map((w, j) => {
                if (j === 0 || j % 7 === 0) {
                  let n = 0
                  return (
                    <TableRow
                      style={{ fontFamily: 'Poppins', top: 0 }}
                      key={w.toString() + n.toString()}
                    >
                      {month.map((d, i) => {
                        if (
                          (i < 7 && j === 0 && n < 7) ||
                          (j === 7 && n < 7 && i < 14 && i > 6) ||
                          (j === 14 && n < 7 && i < 21 && i > 13) ||
                          (j === 21 && n < 7 && i < 28 && i > 20) ||
                          (j === 28 && n < 7 && i < 35 && i > 27) ||
                          (j === 35 && n < 7 && i < 42 && i > 34)
                        ) {
                          n++

                          return (
                            <TableCell className={styles.tabCell} key={d.toString() + i.toString()}>
                              <Box className={styles.date}>
                                <Skeleton
                                  variant={'circular'}
                                  style={{ width: 30, height: 30, marginTop: 3 }}
                                  className={styles.dataDivNumber}
                                ></Skeleton>
                              </Box>

                              <Box>
                                <Box className={styles.containerRow}>
                                  <Skeleton
                                    variant={'rounded'}
                                    style={{ width: '100%', marginTop: 5, marginBottom: 5 }}
                                  />
                                  <Skeleton
                                    variant={'rounded'}
                                    style={{ width: '100%', marginTop: 5, marginBottom: 5 }}
                                  />
                                  <Skeleton
                                    variant={'rounded'}
                                    style={{ width: '100%', marginTop: 5, marginBottom: 5 }}
                                  />
                                </Box>
                              </Box>
                            </TableCell>
                          )
                        }
                        return null
                      })}
                    </TableRow>
                  )
                }
                return null
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Suspense>
    </>
  )
}
