import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { Box, List, ListItem, ListItemText } from '@mui/material'
import style from './CirclesCard.module.css'
import { listItemTextStyle } from '../notification-card-patient/NotificationsCardStyleMui'
import patientIcon from 'assets/role_icons/ico-rol-paciente.svg'
import professionalSMSIcon from 'assets/role_icons/ico-rol-profesional.svg'
import familyIcon from 'assets/role_icons/ico-rol-familiar.svg'
import externProfessionalIcon from 'assets/role_icons/ico-rol-externo.svg'
import career from 'assets/role_icons/ico-rol-cuidador.svg'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CIRCLES } from 'routes/routes-constants'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import { UserCircleWithCircleAndUser } from 'modules/user-circle/models/UserCircleWithCircleAndUser'
import { User } from 'modules/users/models/User'
import { useCalendarEvents } from 'hooks/calendarEvents/useCalendarEvents'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const CirclesCard = () => {
  const loggedUser = loggedUserService.get()
  const navigate = useNavigate()
  const { selectedUserCircle, setSelectedUserCircle, userCircles } = useCircleConfiguration()
  const { setRefreshCalendarEvents } = useCalendarEvents()

  const handleSelectCircle = (u: User, userCircle: UserCircleWithCircleAndUser) => {
    setSelectedUserCircle(new UserCircleWithCircleAndUser(userCircle).toDTO())
  }

  const castToArray = (value: Array<string> | string) => {
    if (!Array.isArray(value)) {
      return value.split(',')
    }
    return value
  }

  const getRoleIcon = (userCircle: UserCircleWithCircleAndUser) => {
    const parseExternIDs = castToArray(userCircle.profExtID)
    const parseProfSMSIDs = castToArray(userCircle.profExtID)
    const parseFamiliarIDs = castToArray(userCircle.familiarsID)
    const parseCareersIDs = castToArray(userCircle.careersID)
    if (parseExternIDs.some((profExt) => profExt === loggedUser?.id)) {
      return externProfessionalIcon
    }
    if (parseProfSMSIDs.some((prof) => prof === loggedUser?.id)) {
      return professionalSMSIcon
    }
    if (parseFamiliarIDs.some((fam) => fam === loggedUser?.id)) {
      return familyIcon
    }
    if (parseCareersIDs.some((carer) => carer === loggedUser?.id)) {
      return career
    }

    return patientIcon
  }

  return (
    <DashboardSectionCard label={'Círculos del paciente'} onClick={() => navigate(ROUTE_CIRCLES)}>
      <Box className={style.container}>
        <List className={style.wrapper}>
          {userCircles.items.map((uc: UserCircleWithCircleAndUser) => (
            <ListItem
              key={uc.circle.name}
              onClick={() => {
                handleSelectCircle(uc.user, uc)
                setRefreshCalendarEvents(true)
              }}
              className={`${style.chatContainer} ${selectedUserCircle?.id === uc.id && style.chatContainerActive}`}>
              <ListItemText sx={listItemTextStyle} className={style.circle}>
                {uc.circle.name}
              </ListItemText>
              <Box className={style.user}>
                <img src={getRoleIcon(uc)} alt={'roleIcon'} className={style.icon} />
                <ListItemText sx={listItemTextStyle} className={style.text}>
                  {uc.user.name}
                </ListItemText>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </DashboardSectionCard>
  )
}
