import React, { useEffect, useState } from 'react'
import { CalendarViewProps } from './types'
import style from './WeekView.module.css'
import dayjs from 'dayjs'
import { dateToDateString } from 'common/utils/date'
import { Box, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { capitalize, reduceString } from 'common/utils/strings'
import { CalendarModal } from './CalendarModal'
import { CustomModal } from 'components/modal/CustomModal'
import { useTranslation } from 'react-i18next'
import { EventCategory } from 'modules/calendar/enums/EventCategory'
import styles from './MonthView.module.css'
import { AllCalendarEventDTO } from 'modules/calendar/models/AllCalendarEventDTO'
import { useCalendarEvents } from 'hooks/calendarEvents/useCalendarEvents'
import { getTrainingContainer } from 'container/training-module'
import { TrainingService } from 'modules/training/services/TrainingService'
import { TRAINING_SERVICE_KEY } from 'modules/training'
import { emptyList, ItemList } from 'common/models/ItemList'
import { Training } from 'modules/training/models/Training'
import { emptyTrainingDTO, toModel as trainingToModel } from 'modules/training/models/TrainingDTO'
import { Query, QueryParam } from 'common/api/Query'
import { TrainingModal } from './trainingModal/TrainingModal'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

const hours = (): string[] => {
  const hourRange = []
  for (let i = 9; i < 21; i++) {
    if (i < 10) {
      hourRange.push('0' + i + ':00')
    } else {
      hourRange.push(i + ':00')
    }
  }
  return hourRange
}

type WeekViewProps = CalendarViewProps

type WeekEvent = {
  event: AllCalendarEventDTO
}

const trainingService = getTrainingContainer().get<TrainingService>(TRAINING_SERVICE_KEY)

export function WeekView(props: WeekViewProps) {
  const { t } = useTranslation()
  const [selectedEvent, setSelectedEvent] = useState<AllCalendarEventDTO>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const currentDate = new Date(props.selectedDate)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { calendarEvents } = useCalendarEvents()
  const [canSetIntensity, setCanSetIntensity] = useState<boolean>(false)
  const [trainings, setTrainings] = useState<ItemList<Training>>(emptyList<Training>)
  const [openTrainingModal, setOpenTrainingModal] = useState<boolean>(false)
  const [selectedTraining, setSelectedTraining] = useState<Training>(
    trainingToModel(emptyTrainingDTO())
  )
  const { selectedUserCircle } = useCircleConfiguration()

  useEffect(() => {
    if (!isLoading) return
    const startDate = dayjs().locale('es').startOf('week').toDate()
    startDate.setHours(0, 0, 0, 0)
    const finishDate = dayjs().locale('es').endOf('week').toDate()

    trainingService
      .getFilteredList(
        new Query<Training>({
          query: [
            new QueryParam<Training>('startTime', startDate.toISOString()),
            new QueryParam<Training>('finishTime', finishDate.toISOString()),

            new QueryParam<Training>('userCircleID', selectedUserCircle?.id ?? ''),
          ],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setTrainings(res)
        setIsLoading(false)
      })
  }, [props.selectedDate, isLoading])

  // Mostrar modal en caso que la entrada es desde email
  useEffect(() => {
    if (!props.currentTraining) return
    if (trainings.items.length === 0) return

    const training = trainings.items.find((value) => value && value.id === props.currentTraining)
    if (!training) return
    setSelectedTraining(training)
    setCanSetIntensity(true)
    setOpenTrainingModal(true)
  }, [props.currentTraining, trainings.items])

  const handleCloseModal = () => setOpenModal(false)

  const editEvent = (e: AllCalendarEventDTO) => {
    props.handlerEdit(e)
    setOpenModal(false)
  }

  const deleteEvent = () => {
    setOpenDeleteModal(true)
  }

  const handlerTrainingCloseModal = () => {
    setCanSetIntensity(false)
    setOpenTrainingModal(false)
  }

  const handlerClickTraining = (event: AllCalendarEventDTO) => {
    const training = trainings.items.find((value) => value && value.id === event.id)
    if (!training) return
    setSelectedTraining(training)
    setOpenTrainingModal(true)
  }
  function WeekEvent(props: WeekEvent): JSX.Element {
    const handlerClick = (event: AllCalendarEventDTO) => {
      if (event.title === 'train') {
        handlerClickTraining(event)
      } else {
        setSelectedEvent(event)
        setOpenModal(true)
      }
    }

    let duration
    if (
      dayjs(props.event.finishDate).format('DD').toString() ===
      dayjs(props.event.startDate).format('DD').toString()
    ) {
      duration =
        Number(dayjs(props.event.finishDate).format('HH')) -
        Number(dayjs(props.event.startDate).format('HH'))
    }

    const getColorByCategory = (category: EventCategory) => {
      let color
      switch (category) {
        case EventCategory.Appointment:
          color = '#E1F0F9'
          break

        case EventCategory.Medication:
          color = '#F9D9C3'
          break

        case EventCategory.Landmarks:
          color = '#F4D1D9'
          break

        case EventCategory.Vaccines:
          color = '#F9F4C3'
          break

        case EventCategory.Reviews:
          color = '#D0F0F3'
          break

        case EventCategory.Food:
          color = '#E0EDC9'
          break

        case EventCategory.OralHealth:
          color = '#E1D3F3'
          break

        case EventCategory.Advices:
          color = '#E9E1CE'
          break

        case EventCategory.Others:
          color = '#E9D3F3'
          break

        case EventCategory.EpilepticCrisis:
          color = '#F8D4A5'
          break

        case EventCategory.Symptoms:
          color = '#E2F9D5'
          break

        case EventCategory.TrainingAbsent:
          color = '#F9D5D9'
          break

        case EventCategory.PersonalEvent:
          color = '#F4E1F9'
          break

        case EventCategory.Treatments:
          color = '#E9E1CE'
          break

        case EventCategory.TrainingPending:
          color = '#E0E0E0'
          break

        case EventCategory.TrainingAssisted:
          color = '#D5F9D5'
          break

        default:
          color = '#E0E0E0'
          break
      }
      return color
    }

    const getColorTextByCategory = (category: EventCategory) => {
      let color
      switch (category) {
        case EventCategory.Appointment:
          color = '#2A5C7D'
          break

        case EventCategory.Medication:
          color = '#8C4E2D'
          break

        case EventCategory.Landmarks:
          color = '#9B6078'
          break

        case EventCategory.Vaccines:
          color = '#8C7F31'
          break

        case EventCategory.Reviews:
          color = '#3B7A82'
          break

        case EventCategory.Food:
          color = '#6D8B4B'
          break

        case EventCategory.OralHealth:
          color = '#6C4C9B'
          break

        case EventCategory.Advices:
          color = '#8B7C49'
          break

        case EventCategory.Others:
          color = '#8B4C9B'
          break

        case EventCategory.EpilepticCrisis:
          color = '#8C5821'
          break

        case EventCategory.Symptoms:
          color = '#6D8B5B'
          break

        case EventCategory.TrainingAbsent:
          color = '#8B4C5C'
          break

        case EventCategory.PersonalEvent:
          color = '#8B4C9B'
          break

        case EventCategory.Treatments:
          color = '#8B7C49'
          break

        case EventCategory.TrainingPending:
          color = '#7A7A7A'
          break

        case EventCategory.TrainingAssisted:
          color = '#4C8B4C'
          break

        default:
          color = '#7A7A7A'
          break
      }
      return color
    }

    return (
      <>
        <Box
          className={style.event}
          onClick={() => handlerClick(props.event)}
          style={{
            ['--duration-height' as any]: duration,
            ['--col' as any]: getColorByCategory(props.event.eventCategory),
            backgroundColor: getColorByCategory(props.event.eventCategory),
          }}
        >
          <h3
            className={style.eventTitle}
            style={{ color: getColorTextByCategory(props.event.eventCategory) }}
          >
            {reduceString(
              props.event.title === 'train' ? t(props.event.title) : props.event.title,
              15
            )}
          </h3>
        </Box>
      </>
    )
  }

  const weekDays = (): Date[] => {
    const day = props.selectedDate

    const datesRange = []
    // Get the day of the week, adjusted so Monday is the first day
    const adjustedDayOfWeek = (day.getDay() + 6) % 7

    for (let i = 0; i < 7; i++) {
      const newDate = new Date(day)
      // Calculate the date for each day in the current week (Monday to Sunday)
      newDate.setDate(day.getDate() - adjustedDayOfWeek + i)
      datesRange.push(newDate)
    }

    return datesRange
  }

  const handleDeleteEvent = () => {
    if (selectedEvent) props.handlerRemove(selectedEvent)
    setOpenModal(false)
    setOpenDeleteModal(false)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const { innerWidth } = window

  function getWeekEvent(d: Date, h: string) {
    return (
      <>
        {calendarEvents.map((event: AllCalendarEventDTO) => {
          const day = dayjs(d)
          const startDate = dayjs(event.startDate)
          const finishDate = dayjs(event.finishDate)
          const sameDays = day.isSame(finishDate, 'day') || day.isSame(startDate, 'day')
          const betweenDays = day.isBetween(startDate, finishDate)

          if (
            (sameDays || betweenDays) &&
            h.split(':')[0] === dayjs(event.startDate).format('HH').toString()
          ) {
            return <WeekEvent event={event} key={event.id} />
          }
          return <></>
        })}
      </>
    )
  }

  return (
    <>
      {innerWidth > 598 ? (
        <>
          <div className={styles.arrowsMonth}>
            <span style={{ textTransform: 'capitalize' }}>
              {dayjs(currentDate).locale(navigator.language.split('-')[0]).format('MMMM YYYY')}
            </span>
          </div>
          <Table className={style.tableContainer}>
            <TableHead>
              <TableRow>
                <TableCell className={style.weekHeader} />
                {weekDays().map((d, i) => (
                  <TableCell key={d.toString()} className={style.weekHeader}>
                    <div className={`${i === 0 ? style.day1 : ''}`} key={dateToDateString(d)}>
                      <div className={style.weekDay}>
                        <div>
                          {capitalize(
                            dayjs(d).locale(navigator.language.split('-')[0]).format('ddd')
                          ).substring(0, 3)}
                        </div>
                      </div>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {hours().map((h, i) => (
                <TableRow key={h.toString()}>
                  <TableCell key={i} className={style.hourRow} style={{ border: 'none' }}>
                    <div key={i}>{h}</div>
                  </TableCell>
                  {weekDays().map((d) => (
                    <TableCell className={style.eventRow} key={String(d) + String(h)}>
                      {getWeekEvent(d, h)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {selectedEvent && (
            <Modal open={openModal} className={style.eventModal} onClose={handleCloseModal}>
              <CalendarModal
                event={selectedEvent}
                handleClose={handleCloseModal}
                handleEdit={editEvent}
                handleRemove={deleteEvent}
              />
            </Modal>
          )}
          <Modal
            open={openDeleteModal}
            className={style.eventModal}
            onClose={handleCloseDeleteModal}
          >
            <CustomModal
              handleClose={handleCloseDeleteModal}
              handleSave={handleDeleteEvent}
              title={t('deleteEvent')}
              warningText={t('irreversibleEventAction')}
            />
          </Modal>
          <Modal
            open={openTrainingModal}
            className={style.eventModal}
            onClose={handlerTrainingCloseModal}
          >
            <TrainingModal
              training={selectedTraining}
              handleClose={handlerTrainingCloseModal}
              canSetIntensity={canSetIntensity}
            />
          </Modal>
        </>
      ) : (
        <>
          <Box className={styles.arrowsMonth} px={1}>
            <span style={{ textTransform: 'capitalize' }}>
              {dayjs(currentDate).locale(navigator.language.split('-')[0]).format('MMMM YYYY')}
            </span>
          </Box>
          <Box className={style.scrollableContainer}>
            <Table className={style.tableContainerMob}>
              <TableBody>
                <TableRow>
                  <TableCell className={style.weekHeaderMobile} />
                  {weekDays().map((d, i) => (
                    <TableCell key={d.toString() + i} className={style.weekHeaderMobile}>
                      <div className={`${i === 0 ? style.day1 : ''}`} key={dateToDateString(d)}>
                        <div className={style.weekDayMobile}>
                          <span style={{ textTransform: 'capitalize' }}>
                            {dayjs(d)
                              .locale(navigator.language.split('-')[0])
                              .format('ddd')
                              .slice(0, -1)}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>

                {hours().map((h, i) => (
                  <TableRow key={h + i}>
                    <TableCell key={i} className={style.hourRowMobile}>
                      <div className={style.hourMobile} key={i}>
                        {h}
                      </div>
                    </TableCell>
                    {weekDays().map((d) => (
                      <TableCell className={style.eventRow} key={String(d) + String(h)}>
                        {getWeekEvent(d, h)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>

          {selectedEvent && (
            <Modal open={openModal} className={style.eventModal} onClose={handleCloseModal}>
              <CalendarModal
                event={selectedEvent}
                handleClose={handleCloseModal}
                handleEdit={editEvent}
                handleRemove={deleteEvent}
              />
            </Modal>
          )}
          <Modal
            open={openDeleteModal}
            className={style.eventModal}
            onClose={handleCloseDeleteModal}
          >
            <CustomModal
              handleClose={handleCloseDeleteModal}
              handleSave={handleDeleteEvent}
              title={t('deleteEvent')}
              warningText={t('irreversibleEventAction')}
            ></CustomModal>
          </Modal>
          <Modal
            open={openTrainingModal}
            className={style.eventModal}
            onClose={handlerTrainingCloseModal}
          >
            <TrainingModal
              training={selectedTraining}
              handleClose={handlerTrainingCloseModal}
              canSetIntensity={canSetIntensity}
            />
          </Modal>
        </>
      )}
    </>
  )
}
