import { Header } from 'components/header/Header'
import { Alert, Box, Modal, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import exitModdal from 'assets/newCircle/exit_icon.svg'
import addButton from 'assets/esfera/buttons/add-icon.svg'
import { getFileContainer } from 'container/file-module'
import { FILE_SERVICE_KEY, IFileService } from 'modules/files'
import { CheckboxItem } from './CheckboxItem'
import style from '../../../add-new-circle/4-forms/Modal.module.css'
import resourceStyle from '../resourcesCircle.module.css'
import { emptyFileDTO, FileDTO, fromModel } from 'modules/files/models/FileDTO'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import removeResouce from 'assets/newCircle/remove_resource_icon.svg'
import { CheckboxAssignType } from 'modules/forms/enums/CheckboxAssignType'

import { BoolQueryParam, Query, QueryParam } from 'common/api/Query'
import { FileQuery } from 'modules/files/models/File'
import { IsDirectoryType } from 'modules/files/enums/IsDirectoryType'
import { emptyCircleDTO, CircleDTO } from 'modules/circle/models/CircleDTO'
import { getCircleContainer } from 'container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from 'modules/circle'

interface Props {
  open: boolean
  directoryID?: string
  onClose: () => void
}

const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
const resourcesService = getFileContainer().get<IFileService>(FILE_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const ModalAddDirectory: React.FC<Props> = ({ open, onClose, directoryID }) => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()

  // get circleDraftId from local storage
  const circleDraftId = localStorage.getItem('circleDraftID')

  const [newDirectories, setNewDirectories] = React.useState<FileDTO[]>([])
  const [errorMessage, setErrorMessage] = useState<string>()
  const [circleDraft, setCircleDraft] = useState<CircleDTO>(emptyCircleDTO)

  useEffect(() => {
    if (!circleDraftId || circleDraft.name) return
    circleService.getByCircleDraftID(circleDraftId).subscribe((circle) => {
      if (!circle) {
        return
      }
      setCircleDraft(circle)
    })
  }, [])

  useEffect(() => {
    if (!circleDraft.name) return
    resourcesService.getFilteredItems(
      new Query<FileQuery>({
        query: [
          new QueryParam<FileQuery>('isDir', IsDirectoryType.Directory.toString()),
          new QueryParam<FileQuery>('circles', circleDraft.name),
          new QueryParam<FileQuery>('createdByDoctor', new BoolQueryParam(true)),
        ],

        sort: [{ field: 'name' }],
      })
    )

    if (!directoryID) {
      setNewDirectories([])

      return
    }

    resourcesService.getByID(directoryID).subscribe((resource) => {
      if (!resource) return
      setNewDirectories([fromModel(resource)])
    })
  }, [open])

  const handleNewDirectory = () => {
    const newDirectory = emptyFileDTO(loggedUser?.id, '', true)
    newDirectory.circles = circleDraft.name
    newDirectory.specialities = circleDraft.specialities.join(',')
    newDirectory.isDir = true
    newDirectory.basePath = '/'
    newDirectory.isCircleConfResource = true
    setNewDirectories([...newDirectories, newDirectory])
  }

  const handleCheckBoxSelected = (index: number, checkboxSelected: number) => {
    const updatedDirectories = [...newDirectories]
    // if checkbox is already included remove it
    if (newDirectories[index]?.checkBoxesSelected?.includes(checkboxSelected.toString())) {
      const replaceString = updatedDirectories[index]?.checkBoxesSelected ?? ''
      updatedDirectories[index].checkBoxesSelected = replaceString.replace(
        ',' + checkboxSelected.toString(),
        ''
      )
      // case only one item delete without ","
      updatedDirectories[index].checkBoxesSelected = replaceString.replace(
        checkboxSelected.toString(),
        ''
      )
    } else {
      // add checkbox
      updatedDirectories[index].checkBoxesSelected =
        updatedDirectories[index].checkBoxesSelected + ',' + checkboxSelected.toString()
    }

    // remove "," of the first position
    if (updatedDirectories[index].checkBoxesSelected?.charAt(0) === ',') {
      updatedDirectories[index].checkBoxesSelected =
        updatedDirectories[index].checkBoxesSelected?.substring(1)
    }

    setNewDirectories(updatedDirectories)
  }

  const validateDirectories = (): boolean => {
    let returnValue = true
    let errorMessage = ''
    newDirectories.forEach((directory) => {
      if (!directory.name || directory.name === '') {
        errorMessage = 'directoryNameRequired'
        returnValue = false
        return
      }
      if (!directory.checkBoxesSelected || directory.checkBoxesSelected === '') {
        errorMessage = 'directoryAssignTypeRequired'
        returnValue = false
      }
    })
    setErrorMessage(errorMessage)
    return returnValue
  }

  const handleSave = async () => {
    await Promise.all(
      newDirectories.map(async (directory) => await resourcesService.add(directory).toPromise())
    )
    setNewDirectories([])
    onClose()
  }

  const deleteDirectory = (id: string | undefined) => {
    const updatedDirectories = newDirectories.filter((directory) => directory.id !== id)
    setNewDirectories(updatedDirectories)
  }

  return (
    <Modal open={open} onClose={onClose} className={style.modalOverFlow}>
      <Box className={style.modalContainer}>
        <Box padding="1rem">
          <Box className={style.contentHeaderExitIcon}>
            <img
              className={resourceStyle.exitIconStyles}
              alt={'Exit icon'}
              onClick={onClose}
              src={exitModdal}
            />
          </Box>
          <Box className={style.contentHeader}>
            <Header label={t('addNewDirectory')} />
          </Box>
          <p style={{ fontFamily: 'sans-serif !important' }}>{t('nameAndCreateNewDirectory')}</p>

          <Box height="400px" overflow="auto" mx="10px" flexDirection="column">
            {newDirectories.map((dtoFile, idx) => (
              <Box
                display="flex"
                flexDirection="column"
                borderBottom="3px solid #68b3e0"
                paddingBottom="1rem"
                alignItems="center"
                key={idx}>
                <Box display="flex" alignItems="center" width="100%">
                  <Box flexGrow={1} pr={2}>
                    <TextField
                      label={t('nameDirectory')}
                      variant="outlined"
                      size="small"
                      onChange={(e) => {
                        const updatedDirectories = [...newDirectories]
                        updatedDirectories[idx].name = e.target.value
                        setNewDirectories(updatedDirectories)
                      }}
                      value={newDirectories[idx]?.name}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                  <Box>
                    <AppButton
                      theme={ButtonTheme.removeField}
                      type="button"
                      label={t('')}
                      startIcon={removeResouce}
                      marginStartIcon={'10px'}
                      handler={() => deleteDirectory(dtoFile.id)}
                      vertical={true}
                    />
                  </Box>
                </Box>

                <Box display="flex" className={resourceStyle.checkboxStyle}>
                  <Box className={style.columnContainer} style={{ margin: '20px' }}>
                    <CheckboxItem
                      name="allCircles"
                      isChecked={
                        newDirectories[idx]?.checkBoxesSelected?.includes(
                          CheckboxAssignType.AllMyCircles.toString()
                        ) ?? false
                      }
                      onCheckChange={() =>
                        handleCheckBoxSelected(idx, CheckboxAssignType.AllMyCircles)
                      }
                      label={t('allCircles')}
                    />
                    <CheckboxItem
                      name="allCarers"
                      isChecked={
                        newDirectories[idx]?.checkBoxesSelected?.includes(
                          CheckboxAssignType.AllCareers.toString()
                        ) ?? false
                      }
                      onCheckChange={() =>
                        handleCheckBoxSelected(idx, CheckboxAssignType.AllCareers)
                      }
                      label={t('allCarers')}
                    />
                    <CheckboxItem
                      name="allPatients"
                      isChecked={
                        newDirectories[idx]?.checkBoxesSelected?.includes(
                          CheckboxAssignType.AllMyPatients.toString()
                        ) ?? false
                      }
                      onCheckChange={() =>
                        handleCheckBoxSelected(idx, CheckboxAssignType.AllMyPatients)
                      }
                      label={t('allPatients')}
                    />
                  </Box>
                  <Box className={style.columnContainer}>
                    <CheckboxItem
                      name="allProfessionals"
                      isChecked={
                        newDirectories[idx]?.checkBoxesSelected?.includes(
                          CheckboxAssignType.AllExternal.toString()
                        ) ?? false
                      }
                      onCheckChange={() =>
                        handleCheckBoxSelected(idx, CheckboxAssignType.AllExternal)
                      }
                      label={t('allProfessionals')}
                    />
                    <CheckboxItem
                      name="allFamiliars"
                      isChecked={
                        newDirectories[idx]?.checkBoxesSelected?.includes(
                          CheckboxAssignType.AllFamiliars.toString()
                        ) ?? false
                      }
                      onCheckChange={() =>
                        handleCheckBoxSelected(idx, CheckboxAssignType.AllFamiliars)
                      }
                      label={t('allFamiliars')}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            display="flex"
            mx="10px"
            justifyContent="end"
            padding="20px 0"
            borderBottom="3px solid #68b3e0">
            {!directoryID && (
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('addNewDirectory')}
                marginStartIcon={'10px'}
                startIcon={addButton}
                handler={handleNewDirectory}
                vertical={true}
              />
            )}
          </Box>

          {errorMessage && (
            <Box mb={3}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}
          <Box paddingTop="24px" mx="10px" display="flex" justifyContent="end">
            <AppButton
              theme={ButtonTheme.newEvent}
              type={'button'}
              label={t('save')}
              marginStartIcon={'10px'}
              startIcon={addButton}
              handler={async () => await (validateDirectories() && handleSave())}
              vertical={true}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalAddDirectory
