import { getUserContainer } from 'container/user-module'
import {
  LOGGED_USER_SERVICE_KEY,
  PROFESSIONALTYPE_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
  IUserService,
} from 'modules/users'
import { Box, Tooltip, Skeleton, useMediaQuery } from '@mui/material'
import { Field } from 'components/table'
import { User, UserQuery } from 'modules/users/models/User'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { Query } from 'common/api/Query'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { Search, SearchValue } from 'components/table_type/types'
import genericStyle from 'common/utils/generic.module.css'
import patient from 'assets/role_icons/ico-rol-paciente.svg'
import professionalSMS from 'assets/role_icons/ico-rol-profesional.svg'
import family from 'assets/role_icons/ico-rol-familiar.svg'
import externProfessional from 'assets/role_icons/ico-rol-externo.svg'
import career from 'assets/role_icons/ico-rol-cuidador.svg'
import { ROUTE_USERS_CREATE } from 'routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { RolesService } from 'modules/users/services/RolesServices'
import { Roles } from 'modules/users/enums/Roles'
import { Permission } from 'common/permission'
import { UserPending } from 'modules/users/models/UserPending'
import style from 'components/table/AppTable.module.css'
import participantStyle from './Participant.module.css'
import { getUserCircleContainer } from 'container/user-circle-module'
import { USER_CIRCLE_SERVICE_KEY } from 'modules/user-circle'
import { AppUsersTable } from './participant-table/AppParticipantTable'
import { UserCircleService } from 'modules/user-circle/services/UserCircleConfigService'
import { ProfessionalTypesService } from 'modules/users/services/ProfessionalTypeService'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const roleService = userContainer.get<RolesService>(ROLES_SERVICE_KEY)
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)

type ParticipantProps = {
  id?: string
}

export interface Participant extends User {
  _professionalType?: string
  _role?: string
}

export function Table(props: ParticipantProps) {
  const { selectedUserCircle } = useCircleConfiguration()
  const { t } = useTranslation()
  const isXS = useMediaQuery('max-width: 599px')
  const [users, setUsers] = useState<Participant[]>([])
  const [filteredUsers, setFilteredUsers] = useState<Participant[]>([])
  const data = useMemo(() => filteredUsers, [filteredUsers])

  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'allFields',
      label: t(''),
      value: '',
      width: '100%',
    },
  ])
  const [usersPending, setUsersPending] = useState<UserPending[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        const tmpMap = new Map<string, string>()
        res.forEach((r) => tmpMap.set(r.id, r.name))
      })

    if (!selectedUserCircle?.id) {
      return
    }

    userService.getUserPending(selectedUserCircle.id).subscribe((res) => {
      setUsersPending(res)
    })

    let allUsers: Participant[] = []

    userCircleService.getByUserIDWithRelaters(selectedUserCircle.user.id).subscribe(async (res) => {
      if (!res) return
      const activeUserCircleParticipants = res.find(
        (userCircle) => userCircle.circleID === selectedUserCircle.circle.id
      )
      if (!activeUserCircleParticipants) return
      const patient = activeUserCircleParticipants.user as Participant
      patient._role = Roles.Patient
      allUsers = [
        // añadimos al paciente
        patient,
        ...activeUserCircleParticipants.familiars.map((user: Participant) => {
          user._role = Roles.FamilyOrTutor
          return user
        }),
        ...activeUserCircleParticipants.profExt.map((user: Participant) => {
          user._role = Roles.ProfessionalExtern
          return user
        }),
        ...activeUserCircleParticipants.profSms.map((user: Participant) => {
          user._role = Roles.Professional
          return user
        }),

        ...activeUserCircleParticipants.careers.map((user: Participant) => {
          user._role = Roles.CarerOrTeacher
          return user
        }),
      ]

      // Obtengo las especialidades de los profesionales
      const promises = allUsers.map(async (user: Participant, index) => {
        if (user._role !== Roles.Professional) {
          return user // Devuelvo el usuario sin cambios si no es profesional
        }
        const res = await professionalTypeService.getProfessionalTypeByUserID(user.id).toPromise()
        user._professionalType = res?.name ?? ''
        return user
      })

      const updatedUsers = await Promise.all(promises)
      setUsers(updatedUsers)
      setFilteredUsers(updatedUsers)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (searcher[0].value === '') {
      setFilteredUsers(users)
    } else {
      const nameSearch: string = searcher[0]?.value?.toLowerCase() ?? ''
      setFilteredUsers(
        users.filter((user) => (user.name + ' ' + user.lastname).toLowerCase().includes(nameSearch))
      )
    }
  }, [isLoading, searcher])

  useEffect(() => {
    const interval = setInterval(() => setIsLoading(false), 1000)
    return () => clearInterval(interval)
  }, [users, usersPending, filteredUsers])

  const handlerAddUser = (participants: Participant[] | undefined) =>
    navigate(ROUTE_USERS_CREATE, { state: participants })

  const getImgRole = (i: Participant) => {
    switch (i._role) {
      case Roles.Patient:
        return i.id === selectedUserCircle?.id ? patient : family
      case Roles.FamilyOrTutor:
        return i.id === selectedUserCircle?.id ? patient : family
      case Roles.Professional:
        return professionalSMS
      case Roles.ProfessionalExtern:
        return externProfessional
      case Roles.CarerOrTeacher:
        return career
      case Roles.ManagerHealthChild:
      case Roles.ManagerEpileptic:
      case Roles.ManagerActivePlan:
        return professionalSMS
    }
  }

  const fieldsXS: Field<Participant>[] = [
    // 2 columns empty
    {
      name: 'id',
      label: 'fakeId',
    },
    {
      name: 'id',
      label: 'fakeId2',
    },
    {
      name: 'name',
      label: t('fullName'),
    },
    {
      name: 'roles',
      label: t('role'),
      renderFunc: (f, i) => {
        const imgSrc = getImgRole(i)
        const name = i._role?.startsWith(Roles.Manager)
          ? t(Roles.Professional)
          : t(i._role as string)

        return (
          <Box display={'flex'} alignItems={'center'}>
            <img className={participantStyle.icon} src={imgSrc} alt={name} />
            <p style={{ margin: '0' }}>{name}</p>
          </Box>
        )
      },
    },
    {
      name: '_professionalType',
      label: t('professionalType'),
      renderFunc: (f, i) => {
        if (i._role !== Roles.Professional) {
          return <p>ieiiee</p>
        }
        return i._professionalType ?? 'asdf'
      },
    },
  ]

  const fieldsDefault: Field<Participant>[] = [
    {
      name: 'id',
      label: '',
    },
    {
      name: 'related',
      label: '',
      renderFunc: (f, i) => {
        const imgSrc = getImgRole(i)
        const name = i._role?.startsWith(Roles.Manager)
          ? t(Roles.Professional)
          : t(i._role as string)
        return (
          <Tooltip title={name}>
            <Box display={'flex'} alignItems={'center'}>
              <img className={participantStyle.icon} src={imgSrc} alt={name} />
              <p style={{ margin: '0' }}>{name}</p>
            </Box>
          </Tooltip>
        )
      },
    },
  ]

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => {
      setSearcher(svs)
    },
  }

  return (
    <>
      {!isLoading && (
        <>
          <Box className={genericStyle.pageContainer}>
            <AppUsersTable
              addButtonLabel={t('addParticipant')}
              removeButtonLabel={t('removeParticipant')}
              reInviteButtonLabel={t('reInviteParticipant')}
              handlerAdd={handlerAddUser}
              titleActions={t('Actions')}
              titleCaption={t('caption')}
              search={search}
              fields={isXS ? fieldsXS : fieldsDefault}
              items={data}
              newItems={usersPending}
              titleNewItems={t('pendingUsers')}
              hasLinkPermission={loggedUserService.userCan(Permission.linkUser)}
            />
          </Box>
        </>
      )}
      {isLoading && (
        <>
          <Box className={genericStyle.pageContainer}>
            {loggedUserService.userCan(Permission.linkUser) && (
              <Box className={genericStyle.circleButton}>
                <Skeleton width={140} height={70} />
              </Box>
            )}
            <Box className={style.searchContainer}>
              <Skeleton width={120} height={60} />
            </Box>
            <Box>
              <Skeleton variant="text" />
              <Skeleton height={450} />
            </Box>
            {loggedUserService.userCan(Permission.linkUser) &&
              usersPending &&
              usersPending.length > 0 && <Skeleton height={200} />}
          </Box>
          {isXS && (
            <Box className={genericStyle.captionContainer}>
              <Box>
                <p
                  style={{
                    color: '#616161',
                    fontSize: '14px',
                    fontWeight: 'bolder',
                    marginTop: '0',
                  }}
                >
                  <Skeleton />
                </p>
              </Box>
              <Box className={genericStyle.roleContainer}>
                <Box className={genericStyle.role}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton />
                </Box>
                <Box className={genericStyle.role}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton />
                </Box>
                <Box className={genericStyle.role}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton />
                </Box>
                <Box className={genericStyle.role}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton />
                </Box>
                <Box className={genericStyle.role}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton />
                </Box>
                <Box className={genericStyle.role}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton />
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  )
}
