import { Box, List } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './NavigationEsfera.module.css'
import { Link, useNavigate } from 'react-router-dom'
import {
  ROUTE_ARTICLES,
  ROUTE_ARTICLES_FORM,
  ROUTE_CALENDAR,
  ROUTE_CIRCLE_CONFIGURATOR,
  ROUTE_CREATE,
  ROUTE_DASHBOARD_PROFESSIONAL,
  ROUTE_DASHBOARD,
  ROUTE_FORM_GENERATOR,
  ROUTE_MESSENGER,
  ROUTE_NOTIFICATIONS,
  ROUTE_PATIENT_DATA,
  ROUTE_PATIENT_FORMS,
  ROUTE_QUERY,
  ROUTE_RESOURCES,
  ROUTE_SHARE_RESOURCES,
  ROUTE_USERS,
  ROUTE_VACCINES,
  ROUTE_MY_POSTS_CONTENT,
  ROUTE_TEMPLATES_PRO_CONTENT,
  ROUTE_TUTORIAL_CONTENT,
  ROUTE_ARTICLES_VALIDATE,
  ROUTE_ARTICLES_HISTORY,
  ROUTE_ADMIN_TUTORIAL_TEMPLATES,
  ROUTE_SEGMENTED_NOTIFICATIONS,
  ROUTE_DASHBOARD_CASE_MANAGER,
  ROUTE_TELECONSULTATION,
  ROUTE_PROMS_PREMS,
  ROUTE_CIRCLES_BASE,
} from 'routes/routes-constants'
import circleIcon from '@assets/left_menu/circulos.svg'
import queryIcon from '@assets/esfera/nav-circle-icons/query-icon.svg'
import queryActive from '@assets/esfera/nav-circle-icons/query-icon-active.svg'
import circleActive from '@assets/left_menu/circulos-act.svg'
import circleIconHover from '@assets/left_menu/circulos-hover.svg'
import circleManageIcon from '@assets/esfera/nav-circle-icons/paticipants-icon.svg'
import circleManageActive from '@assets/esfera/nav-circle-icons/paticipants-icon-active.svg'
import circleManageIconHover from '@assets/left_menu/circulos-admin-hover.svg'
import listIcon from '@assets/map/list_icon_blue.svg'
import listIconActive from '@assets/map/list_icon_active.svg'
import notifications from '@assets/esfera/nav-circle-icons/newsWall-icon.svg'
import notificationsActive from '@assets/esfera/nav-circle-icons/newsWall-icon-active.svg'
import notificationsHover from '@assets/left_menu/notificaciones-hover.svg'
import patientData from '@assets/esfera/nav-circle-icons/patient-data-icon.svg'
import patientDataActive from '@assets/esfera/nav-circle-icons/patient-data-icon-active.svg'
import patientDataHover from '@assets/left_menu/datos-paciente-hover.svg'
import chat from '@assets/esfera/nav-circle-icons/chat-icon.svg'
import chatActive from '@assets/esfera/nav-circle-icons/chat-icon-active.svg'
import chatHover from '@assets/left_menu/chat-hover.svg'
import calendar from '@assets/esfera/nav-circle-icons/calendar-icon.svg'
import calendarActive from '@assets/esfera/nav-circle-icons/calendar-icon-active.svg'
import calendarHover from '@assets/left_menu/calendar-hover.svg'
import resources from '@assets/esfera/nav-circle-icons/share-resources-icon.svg'
import resourcesActive from '@assets/esfera/nav-circle-icons/share-resources-icon-active.svg'
import resourcesHover from '@assets/left_menu/recursos-hover.svg'
import library from '@assets/left_menu/library.svg'
import libraryActive from '@assets/left_menu/library-act.svg'
import libraryActiveHover from '@assets/left_menu/library-active.svg'
import libraryHover from '@assets/left_menu/library-hover.svg'
import forms from '@assets/esfera/nav-circle-icons/forms-icon.svg'
import formsActive from '@assets/esfera/nav-circle-icons/forms-icon-active.svg'
import teleconsulta from '@assets/esfera/nav-circle-icons/teleconsulta.svg'
import teleconsultaHover from '@assets/esfera/nav-circle-icons/teleconsulta-hover.svg'
import formsHover from '@assets/left_menu/formularios-hover.svg'
import createForms from '@assets/left_menu/add-form.svg'
import createFormsActive from '@assets/left_menu/add-form-act.svg'
import createFormsHover from '@assets/left_menu/add-form-hover.svg'
import icoProfile from '@assets/esfera/nav-profile-icons/ico-perfil.svg'
import icoProfileActive from '@assets/esfera/nav-profile-icons/ico-perfil-active.svg'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Permission } from 'common/permission'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users/container'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import recursos from '@assets/left_menu/recursos.svg'
import recursosActive from '@assets/left_menu/recursos-active.svg'
import share from '@assets/esfera/nav-circle-icons/share-resources-icon.svg'
import shareActive from '@assets/esfera/nav-circle-icons/share-resources-icon-active.svg'
import add from '@assets/left_menu/add-form.svg'
import addActive from '@assets/left_menu/add-form-active.svg'
import eye from '@assets/resource_icons/ico-eye.svg'
import eyeActive from '@assets/resource_icons/ico-eye-active.svg'
import vaccineIconHover from '@assets/left_menu/vacunasHover.svg'
import vaccineIcon from '@assets/left_menu/vacunas.svg'
import shareWithMe from '@assets/resource_icons/ico-share2.svg'
import { useIsRelated, canSeeQuery } from 'hooks/relatedUsers/getRelatedUsers'
import { getLinkHeaderStyle, getListItemStyleHoverCircle } from './stylesFunctions'
import {
  ADMIN_TUTORIAL_TEMPLATES,
  ARTICLES_HISTORY,
  ARTICLES_VALIDATE_TITLE,
} from 'routes/title-constants'
import { Roles } from 'modules/users/enums/Roles'
import {
  emptyProfessionalQueryDTO,
  fromModel,
  ProfessionalQueryDTO,
} from 'modules/messenger_consulta/models/ProfessionalQueryDTO'
import { getMessengerConsultaContainer } from 'container/messenger-consulta-module'
import { ProfessionalQueryService } from 'modules/messenger_consulta/services/ProfessionalQueryService'
import { PROFESSIONAL_QUERY_SERVICE_KEY } from 'modules/messenger_consulta'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import { useSelectedMenuContext } from 'common/utils/menu-selected-context/MenuSelectedContext'
import { CircleSubMenuContainer } from './sub-menus/circle/container'
import { ProfileSubMenuContainer } from './sub-menus/profile/container'
import { ArticleSubMenuContainer } from './sub-menus/article/container'

export type LinkType = {
  route: string
  title: string
  activeIcon: string
  icon: string
  altSrc: string
  hoverIcon: string
  isVisible: boolean
  additionalRoutes: string[]
  subMenu: boolean
  subMenuArticle?: boolean
  isDisabled?: boolean
}

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

type MainNavigationProps = {
  login: string
  username: string
  gender: number
  dateOfBirth: string
  notificationNumber: number
  dni: string
  cip: string
  isChooseRole: (choose: boolean) => void
  handleRedirectToSesamo: () => void
}

const professionalQueryService = getMessengerConsultaContainer().get<ProfessionalQueryService>(
  PROFESSIONAL_QUERY_SERVICE_KEY
)

const DASHBOARD_ROLES: string[] = [
  Roles.Admin,
  Roles.ManagerHealthChild,
  Roles.ManagerEpileptic,
  Roles.ManagerActivePlan,
  Roles.CaseManager,
  Roles.Professional,
  Roles.ProfessionalExtern,
  Roles.Patient,
  Roles.FamilyOrTutor,
]

export function NavigationEsfera(props: MainNavigationProps) {
  const { t } = useTranslation()
  const logoutURL = process.env.REACT_APP_EXIT_URL

  const {
    sMenu,
    isCircleOpen,
    isProfileOpen,
    isArticleOpen,
    setIsArticleOpen,
    setIsCircleOpen,
    setIsProfileOpen,
  } = useSelectedMenuContext()
  const loggedUser = loggedUserService.get()
  const { selectedUserCircle } = useCircleConfiguration()

  const [professionalQuery, setProfessionalQuery] = useState<ProfessionalQueryDTO>(
    emptyProfessionalQueryDTO()
  )
  const menuConfigData = selectedUserCircle?.circle.menuConfig
  const isMD = useMediaQuery('(min-width:1000px)')

  const hasRelevantRole = [
    'professionalSMS',
    'managerHealthChild',
    'managerActivePlan',
    'managerEpileptic',
  ].some((role) => loggedUser?.roles?.includes(role))

  const canQuery = () => {
    if (!hasRelevantRole) return true
    if (professionalQuery.acceptConsulta === 1) return true
    return false
  }

  const links: LinkType[] = [
    {
      route: ROUTE_CIRCLES_BASE,
      title: 'circleOfPatient',
      activeIcon: circleActive,
      icon: circleIcon,
      altSrc: 'circleOfPatientIcon',
      hoverIcon: circleIconHover,
      isVisible: loggedUserService.userCan(Permission.seeCircles),
      additionalRoutes: [
        ROUTE_NOTIFICATIONS,
        ROUTE_USERS,
        ROUTE_PATIENT_DATA,
        ROUTE_PATIENT_FORMS,
        ROUTE_DASHBOARD,
        ROUTE_RESOURCES,
        ROUTE_CALENDAR,
        ROUTE_NOTIFICATIONS,
        ROUTE_QUERY,
        ROUTE_MESSENGER,
        ROUTE_CALENDAR,
        ROUTE_TELECONSULTATION,
      ],
      subMenu: false,
    },
    {
      route: loggedUser?.roles.some((r) => r === Roles.Professional ?? r.includes('Manager'))
        ? ROUTE_DASHBOARD_PROFESSIONAL
        : (loggedUser?.roles.includes(Roles.CaseManager) ??
            loggedUser?.roles.some((r) => r.startsWith(Roles.Manager)))
          ? ROUTE_DASHBOARD_CASE_MANAGER
          : ROUTE_DASHBOARD,
      title: t('dashboard'),
      activeIcon: notifications,
      icon: notifications,
      altSrc: 'notificationsIcon',
      hoverIcon: notificationsHover,
      isVisible: !loggedUser ? false : loggedUser.roles.some((r) => DASHBOARD_ROLES.includes(r)),
      additionalRoutes: [],
      subMenu: false,
    },
    {
      route: ROUTE_DASHBOARD,
      title: 'dashboard',
      activeIcon: notificationsActive,
      icon: notifications,
      altSrc: 'notificationsIcon',
      hoverIcon: notificationsHover,
      isVisible: (selectedUserCircle !== undefined && menuConfigData?.wall) ?? false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_USERS,
      title: 'participants',
      activeIcon: circleManageActive,
      icon: circleManageIcon,
      altSrc: 'manageCircleIcon',
      hoverIcon: circleManageIconHover,
      isVisible: (selectedUserCircle !== undefined && menuConfigData?.participant) ?? false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_PATIENT_DATA,
      title: 'patientData',
      activeIcon: patientDataActive,
      icon: patientData,
      altSrc: 'patientDataIcon',
      hoverIcon: patientDataHover,
      isVisible: (selectedUserCircle !== undefined && menuConfigData?.patientData) ?? false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_MESSENGER,
      title: 'chat',
      activeIcon: chatActive,
      icon: chat,
      altSrc: 'calendarIcon',
      hoverIcon: chatHover,
      isVisible:
        ((useIsRelated() ? selectedUserCircle !== undefined : false) &&
          (!loggedUserService.get()?.roles.some((role) => role.startsWith('manager')) ?? false) &&
          menuConfigData?.chats) ??
        false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_QUERY,
      title: 'query',
      activeIcon: queryActive,
      icon: queryIcon,
      altSrc: 'queryIcon',
      hoverIcon: chatHover,
      isVisible: ((canQuery() ? canSeeQuery() : false) && menuConfigData?.querys) ?? false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_CALENDAR,
      title: 'calendar',
      activeIcon: calendarActive,
      icon: calendar,
      altSrc: 'calendarIcon',
      hoverIcon: calendarHover,
      isVisible: (selectedUserCircle !== undefined && menuConfigData?.calendar) ?? false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_RESOURCES,
      title: 'resources',
      activeIcon: resourcesActive,
      icon: resources,
      altSrc: 'resourcesIcon',
      hoverIcon: resourcesHover,
      isVisible: selectedUserCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_PATIENT_FORMS,
      title: 'patientsForms',
      activeIcon: formsActive,
      icon: forms,
      altSrc: 'patientsFormsIcon',
      hoverIcon: formsHover,
      isVisible:
        selectedUserCircle !== undefined && loggedUserService.userCan(Permission.userForms),
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_TELECONSULTATION,
      title: 'Teleconsult',
      activeIcon: teleconsultaHover,
      icon: teleconsulta,
      altSrc: 'patientTeleconsultation',
      hoverIcon: teleconsultaHover,
      isVisible: selectedUserCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_CIRCLE_CONFIGURATOR,
      title: 'circleConfigurator',
      activeIcon: libraryActive,
      icon: library,
      altSrc: 'CircleConfiguratorIcon',
      hoverIcon: libraryHover,
      isVisible: loggedUserService.get()?.roles.includes(Roles.Admin) ?? false,
      additionalRoutes: [],
      subMenu: false,
    },
    {
      route: ROUTE_FORM_GENERATOR,
      title: 'generateForms',
      activeIcon: createFormsActive,
      icon: createForms,
      altSrc: 'generateFormsIcon',
      hoverIcon: createFormsHover,
      isVisible:
        loggedUserService.userCan(Permission.createFormTemplates) &&
        !loggedUserService.get()?.roles.includes(Roles.Admin),
      additionalRoutes: [],
      subMenu: false,
    },
    {
      route: ROUTE_VACCINES,
      title: 'vaccines',
      activeIcon: vaccineIconHover,
      icon: vaccineIcon,
      altSrc: 'vaccineIcon',
      hoverIcon: vaccineIconHover,
      isVisible:
        loggedUserService.userCan(Permission.configVaccines) &&
        !loggedUserService.get()?.roles.includes(Roles.Admin),
      additionalRoutes: [],
      subMenu: false,
    },
    {
      route: ROUTE_SHARE_RESOURCES,
      title: 'shareResources',
      activeIcon: shareWithMe,
      icon: shareWithMe,
      altSrc: 'shareResourcesIcon',
      hoverIcon: shareWithMe,
      isVisible:
        loggedUserService.userCan(Permission.SeeSharedResources) &&
        !loggedUserService.get()?.roles.includes(Roles.Admin),
      additionalRoutes: [],
      subMenu: false,
    },
    {
      route: ROUTE_PROMS_PREMS,
      title: t('proms_prems'),
      activeIcon: notifications,
      icon: notifications,
      altSrc: 'notificationsIcon',
      hoverIcon: notificationsHover,
      isVisible: loggedUser?.roles.includes(Roles.Professional) ?? false,
      additionalRoutes: [],
      subMenu: false,
    },
    {
      route: ROUTE_SEGMENTED_NOTIFICATIONS,
      title: 'segmentedNotifications',
      activeIcon: libraryActiveHover,
      icon: library,
      altSrc: 'libraryIcon',
      hoverIcon: libraryActiveHover,
      isVisible: selectedUserCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_ARTICLES,
      title: 'library',
      activeIcon: libraryActiveHover,
      icon: library,
      altSrc: 'libraryIcon',
      hoverIcon: libraryActiveHover,
      isVisible: true,
      additionalRoutes: [],
      subMenu: true,
      subMenuArticle: true,
    },
    {
      route: ROUTE_SEGMENTED_NOTIFICATIONS,
      title: 'segmentedNotifications',
      activeIcon: libraryActiveHover,
      icon: library,
      altSrc: 'libraryIcon',
      hoverIcon: libraryActiveHover,
      isVisible: loggedUserService.userCan(Permission.createContent),
      additionalRoutes: [],
      subMenu: false,
      // subMenuArticle: true,
    },
    {
      route: ROUTE_MY_POSTS_CONTENT,
      title: 'myPosts',
      activeIcon: shareActive,
      icon: share,
      altSrc: 'myPostsIcon',
      hoverIcon: shareActive,
      isVisible:
        loggedUserService.userCan(Permission.createContent) &&
        !loggedUserService.get()?.roles.some((r) => r.startsWith(Roles.Manager)),
      additionalRoutes: [],
      subMenu: true,
      subMenuArticle: true,
    },
    {
      route: ROUTE_ARTICLES_FORM + '/' + ROUTE_CREATE,
      title: 'newPost',
      activeIcon: addActive,
      icon: add,
      altSrc: 'newPostIcon',
      hoverIcon: addActive,
      isVisible:
        loggedUserService.userCan(Permission.createContent) &&
        !loggedUserService.get()?.roles.some((r) => r.startsWith(Roles.Manager)),
      additionalRoutes: [],
      subMenu: true,
      subMenuArticle: true,
    },
    {
      route: ROUTE_TUTORIAL_CONTENT,
      title: 'tutorialContent',
      activeIcon: recursosActive,
      icon: recursos,
      altSrc: 'tutorialContentIcon',
      hoverIcon: recursosActive,
      isVisible:
        loggedUserService.userCan(Permission.createContent) &&
        !loggedUserService.get()?.roles.some((r) => r.startsWith(Roles.Manager)),
      additionalRoutes: [],
      subMenu: true,
      subMenuArticle: true,
    },
    {
      route: ROUTE_TEMPLATES_PRO_CONTENT,
      title: 'templatesPro',
      activeIcon: calendarActive,
      icon: calendar,
      altSrc: 'templatesProIcon',
      hoverIcon: calendarActive,
      isVisible:
        loggedUserService.userCan(Permission.createContent) &&
        !loggedUserService.get()?.roles.some((r) => r.startsWith(Roles.Manager)),
      additionalRoutes: [],
      subMenu: true,
      subMenuArticle: true,
    },
    {
      route: ROUTE_ARTICLES_VALIDATE,
      title: ARTICLES_VALIDATE_TITLE,
      activeIcon: eyeActive,
      icon: eye,
      altSrc: 'eyeIcon',
      hoverIcon: eyeActive,
      isVisible: loggedUserService.userCan(Permission.validateContent),
      additionalRoutes: [],
      subMenu: true,
      subMenuArticle: true,
    },
    {
      route: ROUTE_ARTICLES_HISTORY,
      title: ARTICLES_HISTORY,
      activeIcon: listIconActive,
      icon: listIcon,
      altSrc: 'listIcon',
      hoverIcon: listIconActive,
      isVisible: loggedUserService.userCan(Permission.validateContent),
      additionalRoutes: [],
      subMenu: true,
      subMenuArticle: true,
    },
    {
      route: ROUTE_ADMIN_TUTORIAL_TEMPLATES,
      title: ADMIN_TUTORIAL_TEMPLATES,
      activeIcon: addActive,
      icon: add,
      altSrc: 'newTutorialIcon',
      hoverIcon: addActive,
      isVisible:
        loggedUserService.userCan(Permission.createContent) &&
        !loggedUserService.get()?.roles.some((r) => r.startsWith(Roles.Manager)),
      additionalRoutes: [],
      subMenu: true,
      subMenuArticle: true,
    },
  ]

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlerLogoutProfile = (_: React.MouseEvent<Element, MouseEvent>) => {
    if (logoutURL) {
      window.location.replace(logoutURL)
      loggedUserService.logout()
    }
    props.isChooseRole(false)
    loggedUserService.logout()
  }
  const navigate = useNavigate()

  const renderVisibleLinks = () => {
    return links
      .filter((l) => l.isVisible && !l.subMenu && l.route !== ROUTE_CIRCLES_BASE)
      .map((l) => (
        <Link
          onClick={() => {
            navigate(l.route)
            setIsCircleOpen(false)
          }}
          key={l.title}
          to={l.route}
          className={getLinkHeaderStyle(
            sMenu,
            style.linkActive,
            'other',
            isCircleOpen || isArticleOpen,
            [l.route, ...l.additionalRoutes]
          )}
          id={l.route}
        >
          <ListItem
            title={t(l.title)}
            handleClick={handleClick}
            altSrc={t(l.altSrc)}
            hoverIconSrc={l.hoverIcon}
            isCircleLink={false}
            isSubMenu={l.subMenu}
            l={l}
          />
        </Link>
      ))
  }

  useEffect(() => {
    if (loggedUser)
      professionalQueryService.getByUserID(loggedUser?.id).subscribe((res) => {
        if (res) {
          setProfessionalQuery(fromModel(res))
        }
      })
  }, [])

  const handleCircleEnter = () => {
    setIsCircleOpen(true)
    setIsArticleOpen(false)
  }

  const handleCircleLeave = () => {
    setIsCircleOpen(false)
  }

  const handleArticleEnter = () => {
    setIsArticleOpen(true)
    setIsCircleOpen(false)
  }

  const handleArticleLeave = () => {
    setIsArticleOpen(false)
  }

  const drawer = (
    <div className={style.listContainer}>
      <List className={style.list}>
        <div className={style.menuDiv}>
          <Box onMouseEnter={handleCircleEnter} onMouseLeave={handleCircleLeave}>
            <Link
              style={{ maxWidth: 80 }}
              onClick={() => {
                navigate(ROUTE_CIRCLES_BASE)
              }}
              key={ROUTE_CIRCLES_BASE}
              to={ROUTE_CIRCLES_BASE}
              className={getLinkHeaderStyle(sMenu, style.linkActive, 'submenu', isCircleOpen, [
                links[0].route,
                ...links[0].additionalRoutes,
              ])}
              id={ROUTE_CIRCLES_BASE}
            >
              <ListItem
                title={t('circleOfPatient')}
                altSrc={t('circleOfPatientIcon')}
                hoverIconSrc={circleIconHover}
                handleClick={handleClick}
                isCircleLink={true}
                isSubMenu={false}
                l={links.find((l) => l.route === ROUTE_CIRCLES_BASE)}
              />
            </Link>
          </Box>

          <Box onMouseEnter={handleArticleEnter} onMouseLeave={handleArticleLeave}>
            <Link
              style={{ maxWidth: 80 }}
              onClick={() => {
                navigate(ROUTE_ARTICLES)
              }}
              key={ROUTE_ARTICLES}
              to={ROUTE_ARTICLES}
              className={getLinkHeaderStyle(sMenu, style.linkActive, 'submenu', isArticleOpen, [
                ROUTE_ARTICLES,
                ...links[10].additionalRoutes,
              ])}
              id={ROUTE_ARTICLES}
            >
              <ListItem
                title={t('library')}
                altSrc={t('libraryIcon')}
                hoverIconSrc={libraryHover}
                setCircleHover={setIsArticleOpen}
                handleClick={handleClick}
                isCircleLink={true}
                isSubMenu={false}
                circleHover={isArticleOpen}
                setMobileOpen={setIsArticleOpen}
                sMenu={sMenu}
                l={links.find((l) => l.route === ROUTE_ARTICLES)}
              />
            </Link>
          </Box>

          {renderVisibleLinks()}
        </div>
        <Box
          onMouseEnter={() => setIsProfileOpen(true)}
          onMouseLeave={() => setIsProfileOpen(false)}
          className={isProfileOpen ? style.avatarContainerActive : style.avatarContainer}
        >
          <Box
            className={
              isProfileOpen
                ? style.avatarContainerActiveBackground
                : style.avatarContainerBackground
            }
          >
            {/* <b style={{ color: "#000" }}> ProfileOpen:  {isProfileOpen ? "open" : "close"} </b> */}
            <img
              src={isProfileOpen ? icoProfileActive : icoProfile}
              alt="avatar"
              className={style.avatarImg}
            />
          </Box>
        </Box>
      </List>

      <CircleSubMenuContainer anchorEl={anchorEl} links={links} />
      <ProfileSubMenuContainer
        anchorEl={anchorEl}
        login={props.login}
        username={props.username}
        gender={props.gender}
        dateOfBirth={props.dateOfBirth}
        dni={props.dni}
        cip={props.cip}
        links={links}
        notificationNumber={props.notificationNumber}
        handlerLogout={handlerLogoutProfile}
      />
      <ArticleSubMenuContainer anchorEl={anchorEl} links={links} />
    </div>
  )

  return <aside className={style.aside}>{isMD && drawer}</aside>
}

type ListItemProps = {
  title: string
  altSrc: string
  isDisabled?: boolean
  hoverIconSrc: string
  isCircleLink: boolean
  circleHover?: boolean | undefined
  setCircleHover?: (hover: boolean) => void
  isSubMenu: boolean
  mobileOpen?: boolean
  setMobileOpen?: (open: boolean) => void
  sMenu?: string
  handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  l?: LinkType | undefined
  style?: React.CSSProperties
}

function ListItem(props: ListItemProps): JSX.Element {
  return (
    <div className={style.menuDiv}>
      <Box key={props.title} className={style.menuList}>
        <Box
          className={getListItemStyleHoverCircle(
            props.isDisabled ?? false,
            props.isCircleLink ?? false,
            props.circleHover ?? false,
            props.sMenu ?? '',
            props.mobileOpen ?? false,

            style.menuItemActive,
            style.menuItem,
            ...(props.l?.additionalRoutes ?? ''),
            props.l?.route ?? ''
          )}
          display="flex"
          alignItems="center"
        >
          <h3 className={style.titleName}> {props.title}</h3>
        </Box>
      </Box>
    </div>
  )
}
