import { RouteProps } from 'routes/AppRouter'
import { useTranslation } from 'react-i18next'
import { DentistTable as DT } from 'features/patient-data'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from 'components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function DentistConfig(props: RouteProps) {
  const { id } = useParams()
  const title = props.title || ''
  const { t } = useTranslation()

  return (
    <>
      <CurrentNavHeaderEsfera title={t(title)} />
      <DT />
    </>
  )
}
