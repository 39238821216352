import { File as F } from 'modules/files/models/File'
import React, { useEffect, useRef } from 'react'
import style from '../messenger/Sender.module.css'
import { downloadFile } from 'common/utils/file'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import videoIcon from 'assets/resource_icons/ico-video.svg'
import fileIcon from 'assets/resource_icons/file.svg'
import styles from '../article/View.module.css'
import { Link } from 'react-router-dom'
import { ListItem, ListItemAvatar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

type ListAddedFilesProps = {
  files: F[]
  handlerClickRemoveFile: (event: any) => void
  scrollToBottom?: boolean
  noRemove?: boolean
}

export function ListAddedFiles(props: ListAddedFilesProps): JSX.Element {
  const listRef = useRef<HTMLUListElement>(null)

  const mainContainer = document.getElementById('mainContainer')

  useEffect(() => {
    // scroll to bottom of page when a file is added
    if (props.scrollToBottom) {
      if (mainContainer !== null) {
        mainContainer.scrollTop = mainContainer.scrollHeight
      }
    }
  }, [props.files])

  const viewResource = (resource: F) => {
    filesService.download(resource).subscribe((res) => {
      if (res) {
        downloadFile(res.name, res.mimeType, res.data)
        // viewFile(res)
      }
    })
  }

  return (
    <>
      {props.files.length > 0 && (
        <ul ref={listRef}>
          {props.files.map((f, i) => (
            <div className={style.attachedElement} key={i}>
              <ListItem style={{ width: 'auto' }}>
                <ListItemAvatar>
                  {(f && f.extension === 'avi') || (f && f.extension === 'mp4') ? (
                    <img src={videoIcon} alt="Video Icon" />
                  ) : (
                    <img src={fileIcon} alt="File Icon" />
                  )}
                </ListItemAvatar>
                <Link
                  key={`file-${f && f.id}`}
                  className={styles.tagLink}
                  to={'.'}
                  onClick={() => viewResource(f)}>
                  {f && f.name}
                </Link>
              </ListItem>
              {!props.noRemove ? (
                <IconButton onClick={props.handlerClickRemoveFile} id={f && f.id}>
                  <CloseIcon />
                </IconButton>
              ) : (
                <></>
              )}
            </div>
          ))}
        </ul>
      )}
    </>
  )
}
