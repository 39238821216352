import { Notification } from './Notification'
import { Query } from 'common/api/Query'
import { SourceType } from '../enums/SourceType'

export type MessageProps = {
  id?: string
  title: string
  description: string
  message: string
  notifications?: Notification[]
  sourceType: SourceType
  url: string
  lastSendAt?: Date
}

export class Message {
  private readonly _id: string | undefined
  private readonly _title: string
  private readonly _description: string
  private readonly _message: string
  private readonly _sourceType: SourceType
  private readonly _url: string
  private readonly _notifications: Notification[]
  private _lastSendAt?: Date

  constructor(p: MessageProps) {
    this._id = p.id
    this._title = p.title
    this._description = p.description
    this._message = p.message
    this._notifications = p.notifications ?? []
    this._sourceType = p.sourceType
    this._url = p.url
    this._lastSendAt = p.lastSendAt
  }

  get id(): string | undefined {
    return this._id
  }

  get title(): string {
    return this._title
  }

  get description(): string {
    return this._description
  }

  get message(): string {
    return this._message
  }

  get notifications(): Notification[] {
    return this._notifications
  }

  get sourceType(): SourceType {
    return this._sourceType
  }

  get url(): string {
    return this._url
  }

  get lastSendAt(): Date | undefined {
    return this._lastSendAt
  }

  set lastSendAt(value: Date) {
    this._lastSendAt = value
  }
}

export class MessageQuery extends Query<Message> {}
