import { AddFile } from 'features/files/AddFile'
import { ListAddedFiles } from 'features/files/ListAddedFiles'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { File as F, FileQuery } from 'modules/files/models/File'
import { createFiles, removeFile } from 'common/utils/file'
import { Query, QueryParam } from 'common/api/Query'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { LoadingFile } from '../loading-spinner/LoadingSpinner'

const FILE_DESTINATION_FOLDERS = ['Article', 'Cover', 'ArticleFile', 'Template', 'Tutorial']

const filesContainer = getFileContainer()
const filesService = filesContainer.get<FileService>(FILE_SERVICE_KEY)

export type FileItemProps = {
  label?: string
  type?: string
  field: string
  filesID: string[]
  userID: string
  cleanFiles: boolean
  handleFiles: (field: string, files: F[]) => void
  handlerChangeAttachFile?: (event: ChangeEvent<HTMLInputElement>) => void
  handlerRemoveFile?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
  uploadRemoveFileDirectly?: boolean
  maxFiles?: number
  disabled?: boolean
  isMultiple?: boolean
  destinationFolder?: string
  eventID?: string
  defaultFile?: F[]
  edit?: boolean
  userCircleID?: string
  circles?: string
  multiple?: boolean
  fullWidth?: boolean
}

export function FileItem({ multiple = true, fullWidth = false, ...props }: FileItemProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    if (!isLoading || props.filesID?.length === 0) {
      setIsLoading(false)
      return
    }
    filesService
      .getFilteredItems(
        new Query({
          query: [new QueryParam<FileQuery>('id', props.filesID)],
        })
      )
      .subscribe((_) => {
        setIsLoading(false)
      })
  }, [isLoading])

  useEffect(() => {
    if (isSending) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0
          }
          const diff = Math.random() * 10
          return Math.min(oldProgress + diff, 100)
        })
      }, 500)

      // return () => {
      clearInterval(timer)
      // }
    }
  }, [])

  async function handlerChangeAttachFile(event: ChangeEvent<HTMLInputElement>) {
    setIsSending(true)
    if (props.destinationFolder === 'Event') {
      const fs = await createFiles(
        event,
        props.userID,
        props.uploadRemoveFileDirectly ?? false,
        props.destinationFolder ?? '',
        null,
        null,
        props.eventID ? props.eventID : null,
        true,
        false,
        null,
        props.userCircleID,
        props.circles
      ).finally(() => {
        setIsSending(false)
      })
      props.handleFiles(props.field, [...(props.defaultFile ?? []), ...fs])
    } else if (FILE_DESTINATION_FOLDERS.includes(props.destinationFolder ?? '')) {
      const fs = await createFiles(
        event,
        props.userID,
        props.uploadRemoveFileDirectly ?? false,
        props.destinationFolder ?? '',
        props.eventID ? props.eventID : null,
        null,
        props.type ? props.type : null,
        false,
        false,
        undefined,
        undefined,
        props.circles ?? ''
      ).finally(() => {
        setIsSending(false)
      })
      props.handleFiles(props.field, [...(props.defaultFile ?? []), ...fs])
    } else {
      const fs = await createFiles(
        event,
        props.userID,
        props.uploadRemoveFileDirectly ?? false,
        props.destinationFolder ?? '',
        null,
        null,
        null,
        true,
        false,
        null,
        props.userCircleID
      ).finally(() => {
        setIsSending(false)
      })

      props.handleFiles(props.field, [...(props.defaultFile ?? []), ...fs])
    }
  }

  useEffect(() => {
    if (props.cleanFiles) {
      props.handleFiles(props.field, [])
    }
  }, [props.cleanFiles])

  async function handlerRemoveFile(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    if (props.defaultFile && !props.edit) {
      const fs = await removeFile(event, props.defaultFile, props.uploadRemoveFileDirectly ?? false)
      props.handleFiles('Remove', fs)
    }
  }

  return (
    <>
      {isSending && <LoadingFile progress={progress} />}
      {!isSending && props.defaultFile && (
        <>
          <AddFile
            multiple={multiple}
            fullWidth={fullWidth}
            handlerChangeAttachFile={handlerChangeAttachFile}
            lengthFiles={props.defaultFile.length}
            buttonLabel={props.label ?? ''}
            maxFiles={props.maxFiles ?? 4}
            disabled={props.disabled ?? false}
            isMultiple={props.isMultiple ?? true}
          />
          <ListAddedFiles
            files={props.defaultFile || []}
            handlerClickRemoveFile={handlerRemoveFile}
            noRemove={props.edit}
          />
        </>
      )}
    </>
  )
}
