import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GrowthMonitoring } from 'modules/patient-data/models/GrowthMonitoring'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { ROUTE_GROWTH_MONITORING_FORM } from 'routes/routes-constants'
import styles from './GrowthMonitoringModalResponsive.module.css'
import {
  growthMonitoringTypes,
  growthMonitoringUnitTypes,
} from 'modules/patient-data/enums/GrowthMonitoringType'
import { dateToDateString, twoDatesToMonths } from 'common/utils/date'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

type GrowthMonitoringModalResponsiveProps = {
  handleClose: () => void
  selected: GrowthMonitoring
}
const growthTypes = growthMonitoringTypes()
const growthUnitTypes = growthMonitoringUnitTypes()

export const GrowthMonitoringModalResponsive = (props: GrowthMonitoringModalResponsiveProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { selectedUserCircle } = useCircleConfiguration()

  const editGrowthMonitoring = () =>
    navigate(`${ROUTE_GROWTH_MONITORING_FORM}/${props.selected.id}`)

  const getAge = () => {
    if (selectedUserCircle?.user.birthDate) {
      return (
        new Date(props.selected.date).toLocaleDateString('es', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }) +
        ' - ' +
        twoDatesToMonths(new Date(props.selected.date), new Date(selectedUserCircle.user.birthDate))
      )
    }
    return dateToDateString(new Date(props.selected.date))
  }

  return (
    <Box className={styles.modalView}>
      <Box className={styles.eventContainer}>
        <Box className={styles.rowContainer}>
          <Box className={styles.label}>{t('type')}</Box>
          <Box>{t(growthTypes[props.selected.type])}</Box>
        </Box>
        <Box className={styles.rowContainer}>
          <Box className={styles.label}>{t('value')}</Box>
          <Box>
            {props.selected.value} {growthUnitTypes[props.selected.type]}
          </Box>
        </Box>
        <Box className={styles.rowContainer}>
          <Box className={styles.label}>{t('date')}</Box>
          <Box>{getAge()}</Box>
        </Box>
      </Box>
      <Box className={styles.actionBtns}>
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('cancel')}
          handler={props.handleClose}
        />
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'submit'}
          label={t('edit')}
          handler={(e) => editGrowthMonitoring()}
        />
      </Box>
    </Box>
  )
}
