import React, { useEffect, useState } from 'react'
import { AppBar, Box } from '@mui/material'
import style from './HeaderEsfera.module.css'
import patient from '../../assets/superior_menu/ico-paciente.svg'
import externProfessional from '../../assets/superior_menu/ico-externo.svg'
import smsProfessional from '../../assets/superior_menu/ico-profesional.svg'
import familiar from '../../assets/superior_menu/ico-familiar.svg'
import carer from '../../assets/superior_menu/ico-cuidador.svg'
import { useTranslation } from 'react-i18next'
import { Roles } from '../../modules/users/enums/Roles'
import { useGetNotifications } from '../../hooks/notifications/useGetNotifications'
import { useGlobalContext } from '../../common/utils/GlobalRoleContext'
import { useGlobalRenderHeaderContext } from '../../common/utils/GlobalRenderHeader'
import logoEsfera from '../../assets/esfera/logo-esfera.svg'
import concierge from '../../assets/brand_logo/conserjeria.png'
import { Divider } from '@mui/material'
import murciaHealth from '../../assets/brand_logo/murciasalud.png'
import { NavigationEsfera } from './NavigationEsfera'

type HeaderProps = {
  login: string
  username: string
  gender: number
  dateOfBirth: string
  dni: string
  cip: string
  isChooseRole: (choose: boolean) => void
  handleRedirectToSesamo: () => void
}

export function HeaderEsfera(props: HeaderProps) {
  const { t } = useTranslation()
  const { render } = useGlobalRenderHeaderContext()
  const { notificationNumber } = useGetNotifications(render)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [roleIcon, setRoleIcon] = useState<string>('')
  const { role } = useGlobalContext()

  useEffect(() => {
    if (role.length === 0) return
    switch (role[0].name) {
      case Roles.Professional:
        setRoleIcon(smsProfessional)
        break
      case Roles.ManagerHealthChild:
      case Roles.ManagerEpileptic:
      case Roles.ManagerActivePlan:
        setRoleIcon(smsProfessional)
        break
      case Roles.Patient:
        setRoleIcon(patient)
        break
      case Roles.ProfessionalExtern:
        setRoleIcon(externProfessional)
        break
      case Roles.CarerOrTeacher:
        setRoleIcon(carer)
        break
      case Roles.FamilyOrTutor:
        setRoleIcon(familiar)
        break
      default:
        setRoleIcon('')
        break
    }
  }, [role])

  return (
    <Box className={style.header} display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" className={style.headerTop}>
        <Box className={style.headerTopLeft}>
          <img src={logoEsfera} alt={t('logo')} />
        </Box>
        <Box className={style.headerTopRight}>
          <img src={concierge} alt={t('concierge')} style={{ height: '29px', width: '59px' }} />
          <Divider
            orientation={'vertical'}
            style={{ height: '28px', marginRight: 31, marginLeft: 18, borderColor: '#68B3E0' }}
          />
          <img
            src={murciaHealth}
            alt={t('murciaHealth')}
            style={{ height: '29px', width: '162px' }}
          />
        </Box>
      </Box>
      <AppBar elevation={0} position="static" className={style.headerNav}>
        <Box className={style.containerNav}>
          <NavigationEsfera
            login={props.login}
            username={props.username}
            gender={props.gender}
            dateOfBirth={props.dateOfBirth}
            dni={props.dni}
            cip={props.cip}
            isChooseRole={props.isChooseRole}
            handleRedirectToSesamo={props.handleRedirectToSesamo}
            notificationNumber={notificationNumber}
          />
        </Box>
      </AppBar>
    </Box>
  )
}

/*


type dateBirthAge = {
  dateBirth: string
}

const BirthAndAge = (props: dateBirthAge) => {
  const { t } = useTranslation()
  const birthDateText = props.dateBirth.split('/')
  const birthFormat = new Date(
    Number(birthDateText[2]),
    Number(birthDateText[1]) - 1,
    Number(birthDateText[0])
  )
  const yearBirth = birthFormat.getFullYear()

  if (yearBirth < 1901) {
    return <></>
  }
  return (
    <>
      <p>{formatDate(props.dateBirth)}</p>
      <p>
        ({dateToAge(props.dateBirth)} {t('years')})
      </p>
    </>
  )
}

*/
