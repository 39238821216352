import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Actions, Pager, Search, SearchValue } from '../../components/table_type/types'
import { AppTable, Field } from '../../components/table'
import { useTranslation } from 'react-i18next'
import editIcon from '../../assets/table_icons/ico-edit2.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar2.svg'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from '../../common/permission'
import { Box } from '@mui/material'
import style from './CircleConfigurator.module.css'
import { getCircleContainer } from '../../container/circle-module'
import { CircleService } from '../../modules/circle/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/circle'
import { CircleWithSpecialtyAndCreatorQuery } from '../../modules/circle/models/CircleWithCreator'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import {
  ROUTE_ADD_NEW_CIRCLE,
  ROUTE_EDIT_ACTIVE_CIRCLE_ADD_NEW_CIRCLE,
} from '../../routes/routes-constants'
import genericStyle from '../../common/utils/generic.module.css'
import addButton from '../../assets/esfera/buttons/add-icon.svg'
import { Searcher } from '../../components/table/Searcher'
import { Query, QueryParam, QueryParamN } from '../../common/api/Query'
import { DeletingModal } from '../../components/modal/DeletingModal'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const circleService = getCircleContainer().get<CircleService>(CIRCLE_SERVICE_KEY)
type CircleConfigurationProps = {}

interface CircleAndDraftsTable {
  id: string
  name: string
  specialities: string[]
  createdAt: Date
  createdBy: {
    name: string
  }
  specialRowClass: boolean
}

export function Table(props: CircleConfigurationProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pager, setPager] = useState<Pager>()
  const [circlesPerPage, setCirclesPerPage] = useState<number>(10)
  const [openDeleteModal, setOpenDeleteModal] = useState<{
    opened: boolean
    circleDraft: boolean
    id: string
  }>({ opened: false, circleDraft: false, id: '' })
  const navigate = useNavigate()
  const [allCirclesAndDrafts, setAllCirclesAndDrafts] = useState<CircleAndDraftsTable[]>([])

  const [searcher, setSearcher] = useState<SearchValue<CircleWithSpecialtyAndCreatorQuery>[]>([
    {
      name: 'allFields',
      label: t('search') + '...',
    },
  ])
  useEffect(() => {
    if (!isLoading || !searcher) {
      return
    }
    const searchTerms = [...searcherQuery(searcher)]
    const name = searchTerms.find((s) => s.value)
    circleService
      .getFilteredListAllCirclesAndDraftsWithSpecialtyAndCreator(
        new Query<CircleWithSpecialtyAndCreatorQuery>({
          query: [
            new QueryParam<CircleWithSpecialtyAndCreatorQuery>('allFields', name?.value ?? ''),
            ...searcherQuery(searcher),
          ],
          sort: [{ field: 'name', desc: true }],
        })
      )
      .subscribe((res) => {
        setCount(res.count)
        const allCircleDraftsTable: CircleAndDraftsTable[] = []
        res.drafts.forEach((draft) => {
          allCircleDraftsTable.push({
            id: draft.id,
            name: draft.name,
            specialities: draft.specialities,
            createdAt: draft.createdAt,
            createdBy: draft.createdBy,
            specialRowClass: true,
          })
        })
        res?.circles?.forEach((circle) => {
          allCircleDraftsTable.push({
            id: circle.id,
            name: circle.name,
            specialities: circle.specialities,
            createdAt: circle.createdAt,
            createdBy: circle.createdBy,
            specialRowClass: false,
          })
        })

        setAllCirclesAndDrafts(allCircleDraftsTable)
      })
  }, [searcher, openDeleteModal])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: circlesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setCirclesPerPage(10)
      return
    }
    setCirclesPerPage(Number.parseInt(event.target.value))
  }

  const searchCircle: Search<CircleWithSpecialtyAndCreatorQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<CircleWithSpecialtyAndCreatorQuery>[]) => {
      setSearcher(svs)
      // setIsLoading(!isLoading)
    },
  }

  const fields: Field<CircleAndDraftsTable>[] = [
    {
      name: 'name',
      label: t('circle'),
      renderFunc: (f, i) => <p style={{ margin: '0px', cursor: 'pointer' }}>{i.name}</p>,
    },
    {
      name: 'specialities',
      label: t('specialty'),
      renderFunc: (f, i) => {
        if (!i.specialities) return <p></p>

        return i.specialities.map((s) => (
          <p key={i.id} style={{ margin: '0px', cursor: 'pointer' }}>
            {s}
          </p>
        ))
      },
    },
    {
      name: 'createdAt',
      label: t('creationDate'),
      renderFunc: (f, i) => new Date(i.createdAt).toLocaleDateString(),
    },
    {
      name: 'createdBy',
      label: t('createdBy'),
      renderFunc: (f, i) => <p style={{ margin: '0px', cursor: 'pointer' }}>{i.createdBy.name}</p>,
    },
  ]

  const actions: Actions<CircleAndDraftsTable> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: (circleDraft) => {
          if (circleDraft.specialRowClass) {
            navigate(ROUTE_ADD_NEW_CIRCLE, { state: { circleDraftID: circleDraft.id } })
          } else {
            navigate(ROUTE_EDIT_ACTIVE_CIRCLE_ADD_NEW_CIRCLE, {
              state: { circleID: circleDraft.id },
            })
          }
        },
        icon: editIcon,
        label: 'edit',
        hidden: () => !loggedUserService.userCan(Permission.ConfigCircles),
      },
      {
        handler: (circleDraft) => {
          // if is not directory then show delete modal of resource-patientView
          setOpenDeleteModal({
            opened: true,
            circleDraft: circleDraft.specialRowClass,
            id: circleDraft.id,
          })
        },
        icon: deleteIcon,
        label: 'delete',
        hidden: () => !loggedUserService.userCan(Permission.ConfigCircles),
      },
    ],
  }

  const createNewCircle = () => navigate(ROUTE_ADD_NEW_CIRCLE)

  return (
    <Box className={genericStyle.pageContainer}>
      <Box>
        <Box className={style.headerTable}>
          <Searcher search={searchCircle} />
          <AppButton
            theme={ButtonTheme.newEvent}
            type={'button'}
            label={t('newCircle')}
            marginStartIcon={'10px'}
            startIcon={addButton}
            handler={createNewCircle}
            vertical={true}
          />
        </Box>
      </Box>
      <DeletingModal
        type={t('circle')}
        open={openDeleteModal.opened}
        handleClose={() => setOpenDeleteModal({ opened: false, circleDraft: false, id: '' })}
        handleDelete={() => {
          if (!openDeleteModal.id) return
          if (openDeleteModal.circleDraft) {
            circleService.deleteCircleDraft(openDeleteModal.id).subscribe(() => {
              setOpenDeleteModal({ opened: false, circleDraft: false, id: '' })
            })
          } else {
            circleService.delete(openDeleteModal.id).subscribe(() => {
              setOpenDeleteModal({ opened: false, circleDraft: false, id: '' })
            })
          }
        }}
      />
      <Box>
        <AppTable
          items={allCirclesAndDrafts}
          rowKeyField="id"
          fields={fields}
          actions={actions}
          /* pager={pager} */
          specialsRowStyle={true}
        />
      </Box>
    </Box>
  )
}

const searcherQuery = (
  svs: SearchValue<CircleWithSpecialtyAndCreatorQuery>[]
):
  | QueryParam<CircleWithSpecialtyAndCreatorQuery>[]
  | QueryParamN<CircleWithSpecialtyAndCreatorQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
