import { Box, Modal, useMediaQuery } from '@mui/material'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { AppTable, Field } from 'components/table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATIENT_DATA } from 'routes/routes-constants'
import { Treatment, TreatmentQuery } from 'modules/patient-data/models/Treatment'
import { Actions, Pager } from 'components/table_type/types'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam } from 'common/api/Query'
import { emptyList, ItemList } from 'common/models/ItemList'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { getPatientDataContainer } from 'container/patient-data-module'
import { TREATMENT_SERVICE_KEY } from 'modules/patient-data'
import { TreatmentService } from 'modules/patient-data/services/TreatmentService'
import genericStyle from 'common/utils/generic.module.css'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import editIcon from 'assets/table_icons/ico-edit.svg'
import deleteIcon from 'assets/table_icons/ico-eliminar.svg'
import { CustomModal } from 'components/modal/CustomModal'
import { Permission } from 'common/permission'
import { useIsRelated } from 'hooks/relatedUsers/getRelatedUsers'
import { ModalAddTreatments } from './ModalAddtreatments/ModalAddTreatments'
import styles from '../symptoms/Editor.module.css'
import style from './Table.module.css'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const patientDataContainer = getPatientDataContainer()
const treatmentService = patientDataContainer.get<TreatmentService>(TREATMENT_SERVICE_KEY)

export function Table() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { selectedUserCircle } = useCircleConfiguration()
  const isRelated = useIsRelated()

  const loggedUser = loggedUserService.get()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openAddModal, setOpenAddModal] = useState<boolean>(false)
  const [currentTreatment, setCurrentTreatment] = useState<Treatment>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  const [treatments, setTreatments] = useState<ItemList<Treatment>>(emptyList<Treatment>())
  const [treatmentsPerPage, setTreatmentsPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [editTreatmentID, setEditTreatmentID] = useState<string>()
  const isXS = useMediaQuery('(max-width: 599px)')

  useEffect(() => {
    getTreatments()
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: treatmentsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, treatmentsPerPage])

  const getTreatments = () => {
    if (!selectedUserCircle?.id) {
      return
    }
    setIsLoading(true)
    treatmentService
      .getFilteredList(
        new Query({
          query: [new QueryParam<TreatmentQuery>('userCircleID', selectedUserCircle?.id)],
          pager: { limit: treatmentsPerPage, offset: page * treatmentsPerPage },
          sort: [{ field: 'date', desc: true }],
        })
      )
      .subscribe((res) => {
        setCount(res.count)
        setTreatments(res)
        setIsLoading(false)
      })
  }

  const goBack = () => navigate(ROUTE_PATIENT_DATA)

  const editTreatment = (t: Treatment) => {
    setEditTreatmentID(t.id)
    setOpenAddModal(true)
  }

  const removeTreatment = (t: Treatment) => {
    setCurrentTreatment(t)
    setOpenDeleteModal(true)
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setTreatmentsPerPage(10)
      return
    }
    setTreatmentsPerPage(Number.parseInt(event.target.value))
  }

  const isCreator = (t: Treatment): boolean => t.creatorID === loggedUser?.id

  const handlerSelectCheck = (treatment: Treatment) => {
    if (currentTreatment === undefined || currentTreatment.id !== treatment.id) {
      setCurrentTreatment(treatment)
    } else {
      setCurrentTreatment(undefined)
    }
  }

  const fields: Field<Treatment>[] = [
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'date',
      label: t('startDate'),
      renderFunc: (f, i) =>
        new Date(i.date).toLocaleDateString('es', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
    },
    {
      name: 'endDate',
      label: t('finishDate'),
      renderFunc: (f, i) => {
        if (!i.endDate) return ''
        if (i.currently) return t('currently')
        return new Date(i.endDate).toLocaleDateString('es', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
      },
    },
    {
      name: 'duration',
      label: t('duration'),
      renderFunc: (f, i) => (i.currently ? '-' : i.duration.toString()),
    },
    {
      name: 'frequency',
      label: t('frequency'),
    },
    {
      name: 'actualDose',
      label: t('dose'),
      renderFunc: (f, i) => (i.newDose ? i.newDose.toString() : (i.actualDose.toString() ?? '')),
    },
    {
      name: 'creatorID',
      label: t('creator'),
      renderFunc: (f, i) => i.creatorName ?? '',
    },
  ]

  const fieldsXS: Field<Treatment>[] = [
    {
      name: 'name',
      label: t(''),
      styleFunc: (f, i) => styles.containerTableBorder,
      renderFunc: (f, i) => {
        const startDate = new Date(i.date)
        const hour = startDate.getHours()
        const minutes = startDate.getMinutes()
        const finishDate = i.endDate ? new Date(i.endDate) : new Date()
        const finishHour = finishDate.getHours()
        const finishMinutes = finishDate.getMinutes()

        const dateString = new Date(startDate).toLocaleDateString('es', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })

        const dateStringTime = !(
          hour === 2 &&
          minutes === 0 &&
          finishHour === 2 &&
          finishMinutes === 0
        )
          ? new Date(startDate).toLocaleTimeString('es', {
              hour: '2-digit',
              minute: '2-digit',
            })
          : ''
        return (
          <Box className={styles.containerTableRow}>
            {loggedUser?.id === i.creatorID && (
              <Box className={styles.checkBoxTable}>
                <input
                  type="radio"
                  name="symptoms"
                  disabled={false}
                  onClick={(e) => handlerSelectCheck(i)}
                  checked={currentTreatment && currentTreatment.id === i.id}
                />
              </Box>
            )}
            <Box className={styles.containerTable} onClick={() => editTreatment(i)}>
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{i.name}</span>
              <span style={{ fontSize: '16px' }}> {i.creatorName ?? ''}</span>
              <hr
                style={{
                  border: 'none',
                  borderTop: '1px solid #ccc',
                  width: '100%',
                  margin: '10px 0',
                }}
              />
              <Box className={styles.containerRowTime}>
                <Box className={styles.containerTimeText}>{dateString}</Box>
                <Box className={styles.containerTimeText}>{dateStringTime}</Box>
              </Box>
            </Box>
          </Box>
        )
      },
    },
  ]

  const actions: Actions<Treatment> = {
    actionsColumn: !isXS ? t('Actions') : t(''),
    items: isXS
      ? []
      : [
          {
            handler: editTreatment,
            icon: editIcon,
            label: 'edit',
            hidden: isRelated ? (t) => !isCreator(t) : (t) => true,
          },
          {
            handler: removeTreatment,
            icon: deleteIcon,
            label: 'delete',
            hidden: isRelated ? (t) => !isCreator(t) : (t) => true,
          },
        ],
  }

  const handleCloseDeleteModal = () => setOpenDeleteModal(false)

  const handleCloseAddModal = () => {
    setOpenAddModal(false)
    getTreatments()
  }
  const handleOpenAddModal = () => {
    setEditTreatmentID(undefined)
    setOpenAddModal(true)
  }

  const handleDeleteTreatment = () => {
    if (currentTreatment?.id)
      treatmentService.delete(currentTreatment.id).subscribe((_) => {
        setOpenDeleteModal(false)
        getTreatments()
      })
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Box className={style.headerBtns}>
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={goBack}
        />
        {loggedUserService.userCan(Permission.registerTreatments) && innerWidth > 599 && (
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            label={t('createTreatment')}
            handler={handleOpenAddModal}
          />
        )}
      </Box>
      <Box className={style.container}>
        <AppTable
          isLoading={isLoading}
          items={treatments.items}
          rowKeyField="id"
          fields={isXS ? fieldsXS : fields}
          actions={actions}
          pager={pager}
          fieldsInDraggable={true}
        />
        <Box className={style.actionBtns}>
          <AppButton
            disabled={isLoading}
            theme={ButtonTheme.newPrimaryRemove}
            type={'button'}
            label={t('delete')}
            handler={() => currentTreatment && removeTreatment(currentTreatment)}
          />
          <AppButton
            disabled={isLoading}
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            label={t('createTreatment')}
            handler={handleOpenAddModal}
          />
        </Box>
      </Box>
      {openAddModal && (
        <ModalAddTreatments
          id={editTreatmentID}
          open={openAddModal}
          onClose={handleCloseAddModal}
        />
      )}
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <CustomModal
          handleClose={handleCloseDeleteModal}
          handleSave={handleDeleteTreatment}
          title={t('deleteTreatment')}
          warningText={t('irreversibleTreatmentAction')}
        />
      </Modal>
    </Box>
  )
}
