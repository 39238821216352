import { Box } from '@mui/material'
import React from 'react'
import style from './Login.module.css'
import logoHeader from './assets/brand_logo/background-title.png'
import logo from './assets/brand_logo/logo.png'
import concierge from './assets/brand_logo/conserjeria.png'
import murciaHealth from './assets/brand_logo/murciasalud.png'
import { useTranslation } from 'react-i18next'
import { useGlobalContext } from './common/utils/GlobalRoleContext'
import { Roles } from './modules/users/enums/Roles'
import patient from './assets/superior_menu/ico-paciente.svg'
import family from './assets/superior_menu/ico-familiar.svg'
import professionalSMS from './assets/superior_menu/ico-profesional.svg'
import externProfessional from './assets/superior_menu/ico-externo.svg'
import career from './assets/superior_menu/ico-cuidador.svg'
import { AppButton, ButtonTheme } from './components/app-button/AppButton'
import { Role } from './modules/users/models/Role'
type ChooseRolLoginProps = {
  isChooseRole: (choose: boolean) => void
}

export const ChooseRolLogin = (props: ChooseRolLoginProps) => {
  const { t } = useTranslation()
  const { role, setRole } = useGlobalContext()

  const chooseRol = (value: string) => {
    const roleFilter = role.filter((rol) => rol.name === value)
    setRole(roleFilter)
    props.isChooseRole(true)
  }

  const showRole = (role: Role, i: number) => {
    let img = ''
    switch (role.name) {
      case Roles.Patient:
        img = patient
        break
      case Roles.FamilyOrTutor:
        img = family
        break
      case Roles.Professional:
        img = professionalSMS
        break
      case Roles.ProfessionalExtern:
        img = externProfessional
        break
      case Roles.CarerOrTeacher:
        img = career
        break
      case Roles.ManagerHealthChild:
      case Roles.ManagerActivePlan:
      case Roles.ManagerEpileptic:
        img = professionalSMS
        break
    }
    return (
      <Box className={style.role}>
        <AppButton
          label={t(role.name)}
          theme={ButtonTheme.NewPrimary}
          handler={() => chooseRol(role.name)}
          type={'button'}
          startIcon={img}
        />
      </Box>
    )
  }

  return (
    <Box className={style.container}>
      <Box className={style.header} display="flex" flexDirection="column">
        <Box display="flex" className={style.headerTop}>
          <img src={logoHeader} alt={t('logoHeader')} className={style.logoHeader} />
          <Box className={style.headerTopLeft} display="flex">
            <img src={logo} alt={t('logo')} />
            <Box justifyContent="center" alignItems="center" display="flex">
              <p>Iniciar Sesión</p>
            </Box>
          </Box>
          <Box className={style.headerTopRight} display="block" justifyContent="right">
            <img src={concierge} alt={t('concierge')} />
            <img src={murciaHealth} alt={t('murciaHealth')} />
          </Box>
        </Box>
      </Box>
      <Box className={style.loginContainer} display={'flex'}>
        <Box className={style.login}>
          <h2>¿Como desea acceder?</h2>
          <Box className={style.roleContainer}>
            {role && role.map((rol, index) => showRole(rol, index))}
          </Box>
        </Box>
      </Box>
      <Box className={style.footer} display="flex" flexDirection="column">
        <p style={{ fontWeight: 'bolder' }}>© Servicio Murciano de Salud</p>
        <p>Calle Central, 7, Edificio Habitamia 1, 30100, Espinardo (Murcia)</p>
        <p>Teléfono de Información de Sanidad: 968 36 59 00</p>
      </Box>
    </Box>
  )
}
