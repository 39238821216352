import { ICircleService, CIRCLE_SERVICE_KEY } from 'modules/circle'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider } from '@mui/material'
import {
  ROUTE_EDIT_ACTIVE_CIRCLE_ADD_NEW_CIRCLE,
  ROUTE_EDIT_ACTIVE_CIRCLE_SYMPTOMS_CONFIGURATOR,
  ROUTE_CIRCLE_CONFIGURATOR,
} from 'routes/routes-constants'
import { useLocation, useNavigate } from 'react-router-dom'
import genericStyle from 'common/utils/generic.module.css'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import style from '../../add-new-circle/AddNewCircle.module.css'
import { getCircleContainer } from 'container/circle-module'
import { TextFieldItem } from 'components/form-card/TextFieldItem'
import { Header } from 'components/header/Header'
import { CircleDTO, emptyCircleDTO, fromModel } from 'modules/circle/models/CircleDTO'
import CustomStepper from '../CustomStepperEditActiveCircle'
import { stepsEditActiveCircle } from '../StepsEditActiveCircle'
import styles from '../../../user-profile/Editor.module.css'

const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

export function EditActiveCircleConfigurator() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [circle, setCircle] = useState<CircleDTO>(emptyCircleDTO())
  // get circle id from navigation
  const location = useLocation()
  const circleID = location?.state?.circleID

  useEffect(() => {
    if (circleID) {
      circleService.getByID(circleID).subscribe((circle) => {
        if (!circle) {
          navigate(ROUTE_CIRCLE_CONFIGURATOR)
          return
        }
        setCircle(fromModel(circle))
      })
    }
  }, [])

  const handleMenuConfig = (e: ChangeEvent<HTMLInputElement>) => {
    setCircle({
      ...circle,
      menuConfig: { ...circle.menuConfig, [e.target.name]: e.target.checked },
    })
  }

  const handleCalendarConfig = (e: ChangeEvent<HTMLInputElement>) => {
    setCircle({
      ...circle,
      calendarConfig: { ...circle.calendarConfig, [e.target.name]: e.target.checked },
    })
  }

  const handlePatientConfig = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case 'medicalData':
        setCircle({
          ...circle,
          patientConfig: { ...circle.patientConfig, patientChart: e.target.checked },
        })
        break
      case 'medicalDataHealthyChild':
        setCircle({
          ...circle,
          patientConfig: { ...circle.patientConfig, healthyChildChart: e.target.checked },
        })
        break
      case 'activePlan':
        setCircle({
          ...circle,
          patientConfig: { ...circle.patientConfig, activePlan: e.target.checked },
        })
        break
      case 'evolutionControl':
        setCircle({
          ...circle,
          patientConfig: { ...circle.patientConfig, evolutionaryControl: e.target.checked },
        })
        break
      case 'symptoms':
        setCircle({
          ...circle,
          patientConfig: { ...circle.patientConfig, symptoms: e.target.checked },
        })
        break
      case 'treatments':
        setCircle({
          ...circle,
          patientConfig: { ...circle.patientConfig, treatments: e.target.checked },
        })
        break
      case 'vaccinationSchedule':
        setCircle({
          ...circle,
          patientConfig: { ...circle.patientConfig, vacCalendar: e.target.checked },
        })
        break
      case 'Health':
        setCircle({
          ...circle,
          patientConfig: { ...circle.patientConfig, oralHealth: e.target.checked },
        })
        break
      case 'wearables':
        setCircle({
          ...circle,
          patientConfig: { ...circle.patientConfig, wearables: e.target.checked },
        })
        break
    }
  }

  const handleQueryConfig = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case 'teleCall':
        setCircle({
          ...circle,
          queryConfig: { ...circle.queryConfig, teleCall: e.target.checked, videoCall: false },
        })
        break
      case 'videoCall':
        setCircle({
          ...circle,
          queryConfig: { ...circle.queryConfig, videoCall: e.target.checked, teleCall: false },
        })
        break
      case 'yes':
        setCircle({
          ...circle,
          queryConfig: { ...circle.queryConfig, acceptQuery: true },
        })
        break
      case 'no':
        setCircle({
          ...circle,
          queryConfig: {
            ...circle.queryConfig,
            acceptQuery: false,
            noAvailableMessage: '',
          },
        })
        break
    }
  }

  const updateCircle = (): boolean => {
    if (!circle) return false
    circleService.update(circle).subscribe((res) => {})
    return true
  }

  const goPreviousStep = () =>
    updateCircle() && navigate(ROUTE_EDIT_ACTIVE_CIRCLE_ADD_NEW_CIRCLE, { state: { circleID } })

  const goNextStep = () =>
    updateCircle() &&
    navigate(ROUTE_EDIT_ACTIVE_CIRCLE_SYMPTOMS_CONFIGURATOR, { state: { circleID } })

  const saveDraft = () => updateCircle() && navigate(ROUTE_CIRCLE_CONFIGURATOR)

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Header label={t('circleConfiguration')} />
        <Box>
          <CustomStepper steps={stepsEditActiveCircle} activeStep={1} />
        </Box>

        <Box>
          <Box>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
                width: '100%',
              }}>
              {t('menuConfigurator')}
            </p>
            <Divider
              light
              style={{ backgroundColor: '#68b3e0', height: 4, marginBottom: '30px' }}
            />
          </Box>

          <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.containerInput}>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="wall"
                  className={styles.check}
                  checked={circle.menuConfig.wall}
                  disabled={false}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('Dashboard')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="patientData"
                  className={styles.check}
                  checked={circle.menuConfig.patientData}
                  disabled={false}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('patientData')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="teleconsultation"
                  className={styles.check}
                  checked={circle.menuConfig.teleconsultation}
                  disabled={false}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('Teleconsult')}
              </label>
            </Box>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="participant"
                  className={styles.check}
                  checked={circle.menuConfig.participant}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('participants')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="chats"
                  className={styles.check}
                  checked={circle.menuConfig.chats}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('Chats')}
              </label>
            </Box>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="querys"
                  className={styles.check}
                  checked={circle.menuConfig.querys}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('Consultations')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="calendar"
                  className={styles.check}
                  checked={circle.menuConfig.calendar}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('Calendars')}
              </label>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
                width: '100%',
              }}>
              {t('calendarModuleConfigurator')}
            </p>
            <Divider
              light
              style={{ backgroundColor: '#68b3e0', height: 4, marginBottom: '30px' }}
            />
          </Box>
          <span style={{ fontWeight: 'bold', color: '#68b3e0' }}>{t('visualisation')}</span>
          <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.containerInput}>
            <label className={style.checkboxContainer}>
              <input
                type="checkbox"
                name="monthly"
                className={styles.check}
                checked={circle.calendarConfig.monthly}
                onChange={handleCalendarConfig}
              />
              {t('Monthly')}
            </label>
            <label className={style.checkboxContainer}>
              <input
                type="checkbox"
                name="weekly"
                className={styles.check}
                checked={circle.calendarConfig.weekly}
                onChange={handleCalendarConfig}
              />
              {t('Weekly')}
            </label>
            <label className={style.checkboxContainer}>
              <input
                type="checkbox"
                name="diary"
                className={styles.check}
                checked={circle.calendarConfig.diary}
                onChange={handleCalendarConfig}
              />
              {t('Daily')}
            </label>
          </Box>
          <span style={{ fontWeight: 'bold', color: '#68b3e0' }}>{t('categories')}</span>

          <Box className={style.containerInput}>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="vaccines"
                  className={styles.check}
                  checked={circle.calendarConfig.vaccines}
                  onChange={handleCalendarConfig}
                />
                {t('vaccines')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="symptoms"
                  className={styles.check}
                  checked={circle.calendarConfig.symptoms}
                  onChange={handleCalendarConfig}
                />
                {t('symptoms')}
              </label>
            </Box>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="appointments"
                  className={styles.check}
                  checked={circle.calendarConfig.appointments}
                  onChange={handleCalendarConfig}
                />
                {t('medicalappointment')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="treatments"
                  className={styles.check}
                  onChange={handleCalendarConfig}
                  checked={circle.calendarConfig.treatments}
                />
                {t('treatments')}
              </label>
            </Box>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="others"
                  className={styles.check}
                  onChange={handleCalendarConfig}
                  checked={circle.calendarConfig.others}
                />
                {t('others')}
              </label>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
                width: '100%',
              }}>
              {t('patientDataModuleConfigurator')}
            </p>
            <Divider
              light
              style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4, marginBottom: '30px' }}
            />
          </Box>
          <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.containerInput}>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="medicalData"
                  onChange={handlePatientConfig}
                  checked={circle.patientConfig.patientChart}
                  className={styles.check}
                />
                {t('medicalData')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="medicalDataHealthyChild"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circle.patientConfig.healthyChildChart}
                />
                {t('medicalDataHealthyChild')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="activePlan"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circle.patientConfig.activePlan}
                />
                {t('activePlan')}
              </label>
            </Box>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="evolutionControl"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circle.patientConfig.evolutionaryControl}
                />
                {t('evolutionControl')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="symptoms"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circle.patientConfig.symptoms}
                />
                {t('symptoms')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="treatments"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circle.patientConfig.treatments}
                />
                {t('treatments')}
              </label>
            </Box>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="vaccinationSchedule"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circle.patientConfig.vacCalendar}
                />
                {t('vaccinationSchedule')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="Health"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circle.patientConfig.oralHealth}
                />
                {t('oralHealth')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="wearables"
                  value="no"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circle.patientConfig.wearables}
                />
                {t('wearables')}
              </label>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
                width: '100%',
              }}>
              {t('queryModuleConfigurator')}
            </p>
            <Divider
              light
              style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4, marginBottom: '30px' }}
            />
          </Box>
          <span style={{ fontWeight: 'bold', color: '#68b3e0' }}>{t('typeOfQuery')}</span>
          <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.containerInput}>
            <label className={style.checkboxContainer}>
              <input
                type="radio"
                name="teleCall"
                className={styles.check}
                disabled={false}
                onChange={(e) => handleQueryConfig(e)}
                checked={circle.queryConfig.teleCall}
              />
              {t('Teleconsult')}
            </label>
            <label className={style.checkboxContainer}>
              <input
                type="radio"
                name="videoCall"
                className={styles.check}
                disabled={false}
                onChange={(e) => handleQueryConfig(e)}
                checked={circle.queryConfig.videoCall}
              />
              {t('videoCall')}
            </label>
            <label className={`${style.checkboxContainer} ${style.invisibleCheckbox}`}>
              <input type="radio" />
            </label>
          </Box>
          <span style={{ fontWeight: 'bold', color: '#68b3e0' }}>
            {t('acceptQuerysAtTheMoment')}
          </span>
          <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.containerInput}>
            <label className={style.checkboxContainer}>
              <input
                type="radio"
                name="yes"
                className={styles.check}
                disabled={false}
                onChange={(e) => handleQueryConfig(e)}
                checked={circle.queryConfig.acceptQuery}
              />
              {t('yes')}
            </label>
            <label className={style.checkboxContainer}>
              <input
                type="radio"
                name="no"
                className={styles.check}
                onChange={(e) => handleQueryConfig(e)}
                checked={!circle.queryConfig.acceptQuery}
              />
              {t('no')}
            </label>
            <label className={`${style.checkboxContainer} ${style.invisibleCheckbox}`}>
              <input type="radio" />
            </label>
          </Box>
          {circle.queryConfig.acceptQuery && (
            <Box>
              <span style={{ fontWeight: 'bold', color: '#68b3e0' }}>
                {t('messageConfigurationCircleConfigurator')}
              </span>
              <Box display="flex">
                <TextFieldItem
                  sx={{ margin: '0' }}
                  field={''}
                  value={circle.queryConfig.noAvailableMessage}
                  handleChange={(e) => {
                    setCircle({
                      ...circle,
                      queryConfig: { ...circle.queryConfig, noAvailableMessage: e.target.value },
                    })
                  }}
                  label={t('')}
                  rows={6}
                  required={true}
                />
              </Box>
            </Box>
          )}
        </Box>

        <Box display="flex" style={{ justifyContent: 'space-between' }}>
          <AppButton
            theme={ButtonTheme.whiteAndBlue}
            type={'button'}
            label={t('save')}
            marginStartIcon={'10px'}
            handler={saveDraft}
            vertical={true}
          />{' '}
          <Box style={{ display: 'flex' }}>
            <Box style={{ marginRight: '20px' }}>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('backOneToResources')}
                marginStartIcon={'10px'}
                handler={goPreviousStep}
                vertical={true}
              />
            </Box>
            <Box>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('saveAndNext')}
                marginStartIcon={'10px'}
                handler={goNextStep}
                vertical={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
