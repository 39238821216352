import { getUserContainer } from 'container/user-module'
import {
  LOGGED_USER_SERVICE_KEY,
  PROFESSIONALTYPE_SERVICE_KEY,
  ROLES_SERVICE_KEY,
} from 'modules/users'
import { ChangeEvent, useEffect, useState } from 'react'
import deleteButton from 'assets/buttons/deleteBorder.svg'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { Query } from 'common/api/Query'
import { useTranslation } from 'react-i18next'
import { Alert, Box, Divider } from '@mui/material'
import {
  ROUTE_ADD_NEW_CIRCLE_CIRCLE_CONFIGURATOR,
  ROUTE_CIRCLE_CONFIGURATOR,
  ROUTE_CIRCLES,
} from 'routes/routes-constants'
import { useLocation, useNavigate } from 'react-router-dom'
import genericStyle from 'common/utils/generic.module.css'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import style from '../AddNewCircle.module.css'
import { TextFieldItem } from 'components/form-card/TextFieldItem'
import { Header } from 'components/header/Header'
import addButton from 'assets/esfera/buttons/add-icon.svg'
import { CircleDTO, emptyCircleDTO, fromModel } from 'modules/circle/models/CircleDTO'
import { ProfessionalTypesService } from 'modules/users/services/ProfessionalTypeService'
import { ProfessionalType } from 'modules/users/models/ProfessionalType'
import { RolesService } from 'modules/users/services/RolesServices'
import { Role } from 'modules/users/models/Role'
import { RolesType } from 'common/enums/RolesType'
import { AddParticipant } from './AddParticipant'
import { getCircleContainer } from 'container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from 'modules/circle'
import CustomStepper from '../CustomStepper'
import { steps } from '../Steps'
import {
  getCircleParticipantConfigFromParticipants,
  getParticipantsFromCircleParticipantConfig,
  Participant,
} from './Participant'
import CustomSpecialitySelect from '../../CustomSpecialitySelect'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userContainer = getUserContainer()

const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)

const roleContainer = getUserContainer()
const roleService = roleContainer.get<RolesService>(ROLES_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

export function AddNewCircle() {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const navigate = useNavigate()
  const location = useLocation()
  const circleDraftID = location?.state?.circleDraftID
  const [circle, setCircle] = useState<CircleDTO>(emptyCircleDTO())
  const [specialities, setSpecialities] = useState<ProfessionalType[]>([])
  const [roles, setRoles] = useState<Role[]>([])
  const [arraySelectedSpecialities, setArraySelectedSpecialities] = useState<string[]>([''])
  const [participantIdCounter, setParticipantIdCounter] = useState(0)
  const [participants, setParticipants] = useState<Participant[]>([
    { id: 0, role: '', numberOfParticipant: 0 },
  ])
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (!loggedUser?.id) return
    setCircle({ ...circle, createdBy: loggedUser.id, createdAt: new Date() })
  }, [])

  useEffect(() => {
    roleService.getAll(new Query<Role>({})).subscribe((res) => {
      res = res.filter((x) => x.name !== RolesType.Admin)
      setRoles(res)
    })
  }, [])

  useEffect(() => {
    if (roles.length === 0) return
    if (circleDraftID) {
      circleService.getByCircleDraftID(circleDraftID).subscribe((circle) => {
        if (!circle) {
          navigate(ROUTE_CIRCLES)
          return
        }
        const circleModel = fromModel(circle)
        const participants = getParticipantsFromCircleParticipantConfig(
          circleModel.participantConfig,
          roles
        )
        setParticipants(participants)
        setParticipantIdCounter(participants.length - 1)
        setArraySelectedSpecialities(circleModel.specialities)
        setCircle(circleModel)
      })
    }
  }, [roles])

  useEffect(() => {
    professionalTypeService.getAll(new Query<ProfessionalType>({})).subscribe((res) => {
      setSpecialities(res)
    })
  }, [])

  const handlerInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCircle({ ...circle, [e.target.name]: e.target.value })
  }

  const handleSpecialtyChange = (index: number, value: string) => {
    const updatedSpecialties = [...arraySelectedSpecialities]
    updatedSpecialties[index] = value
    setArraySelectedSpecialities(updatedSpecialties)
    setCircle({ ...circle, specialities: updatedSpecialties })
  }

  const handleAddSpecialty = () => {
    // Verificar si la última especialidad en el array está vacía
    const lastSpecialty = arraySelectedSpecialities[arraySelectedSpecialities.length - 1]
    if (lastSpecialty !== '') {
      const updatedSpecialties = [...arraySelectedSpecialities, '']
      setArraySelectedSpecialities(updatedSpecialties)
    } else {
      setErrorMessage('No se puede agregar una especialidad vacía')
    }
  }

  const handleRemoveSpecialty = (index: number) => {
    const updatedSpecialties = [...arraySelectedSpecialities]
    updatedSpecialties.splice(index, 1)
    setArraySelectedSpecialities(updatedSpecialties)
    setCircle({ ...circle, specialities: updatedSpecialties })
  }

  const handleSelectedParticipant = (value: string, index: number) => {
    const participantsUpdate = [...participants]
    participantsUpdate[index].role = value
    setParticipants(participantsUpdate)
    setCircle((prevCircle) => {
      const updatedCircle = { ...prevCircle }
      updatedCircle.participantConfig = getCircleParticipantConfigFromParticipants(
        participantsUpdate,
        roles
      )
      return updatedCircle
    })
  }

  const handleNumberChange = (value: number, index: number) => {
    const participantsUpdate = [...participants]
    participantsUpdate[index].numberOfParticipant = value
    setParticipants(participantsUpdate)

    setCircle((prevCircle) => {
      const updatedCircle = { ...prevCircle }
      updatedCircle.participantConfig = getCircleParticipantConfigFromParticipants(
        participantsUpdate,
        roles
      )
      return updatedCircle
    })
  }

  const deleteParticipant = (id: number) => {
    const updatedParticipants = participants.filter((participant) => participant.id !== id)
    setParticipants(updatedParticipants)
    setCircle((prevCircle) => {
      const updatedCircle = { ...prevCircle }
      updatedCircle.participantConfig = getCircleParticipantConfigFromParticipants(
        updatedParticipants,
        roles
      )
      return updatedCircle
    })
  }

  const handleAddParticipant = () => {
    // only add new participant if the last participant has a role
    if (participants.length > 0 && participants[participants.length - 1].role === '') return

    // if already exist participants icrements the id
    let newParticipantId
    if (participants.length > 0) {
      newParticipantId = participantIdCounter + 1
    } else {
      // set the index to 0 if not exist participants
      newParticipantId = 0
    }
    const newParticipant: Participant = {
      id: newParticipantId,
      role: '',
      numberOfParticipant: 0,
    }
    setParticipants([...participants, newParticipant])
    setParticipantIdCounter(newParticipantId)
  }

  const updateOrCreateDraft = (routeNavigation: string) => {
    if (circleDraftID) {
      // in case circleDraftID exists, update the circle draft
      circleService.updateCircleDraft(circle).subscribe((res) => {
        // in case update draft is successful, save also on local Storage for the next steps.
        localStorage.setItem('circleDraftID', circle.id)
        navigate(routeNavigation)
      })
      return
    }
    // in case circleDraftID does not exist, create a new circle draft
    circleService.addCircleDraft(circle).subscribe((res) => {
      navigate(routeNavigation)
      // in case saving draft is successful, save also on local Storage for the next steps.
      localStorage.setItem('circleDraftID', circle.id)
    })
  }

  const validateCircle = () => {
    if (!circle.name.trim() || !circle.description.trim()) {
      // Trim comprueba que no haya solo espacios en blanco o que no este vacio
      setErrorMessage('Los campos de Nombre y descripción son obligatorios')
      return false
    }
    if (
      arraySelectedSpecialities.length === 0 ||
      (arraySelectedSpecialities.length === 1 && arraySelectedSpecialities[0] === '')
    ) {
      setErrorMessage('No hay especialidades seleccionadas')
      return false
    }
    setErrorMessage('')
    return true
  }

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Header label={t('newCircle')} />
        <Box>
          <CustomStepper steps={steps} activeStep={0} />
        </Box>

        <Box>
          <Box>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
                width: '100%',
              }}>
              {t('circleDescription')}
            </p>
            <Divider
              style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4, marginBottom: 36 }}
            />
          </Box>

          <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.textField}>
            <b className={style.titleLabel} style={{ marginRight: '30px' }}>
              {' '}
              {t('name')}
            </b>
            <TextFieldItem
              sx={{
                '.MuiInputBase-input': {
                  fontFamily: 'OpenSans',
                  minHeight: 10,
                  height: 10,
                },
                '.MuiInputLabel-outlined': {
                  fontFamily: 'OpenSans',
                  fontSize: 15,
                },
              }}
              field={'name'}
              value={circle.name}
              label={'name'}
              type={'text'}
              handleChange={handlerInput}
              rows={undefined}
              required={true}
              maxLength={40}
            />
          </Box>

          <Box display="flex">
            <b className={style.titleLabel}>{t('description')}</b>
            <TextFieldItem
              sx={{
                margin: 0,
                '.MuiInputBase-input': {
                  fontFamily: 'OpenSans',
                  minHeight: 10,
                  height: 10,
                },
                '.MuiInputLabel-outlined': {
                  fontFamily: 'OpenSans',
                  fontSize: 15,
                },
              }}
              field={'description'}
              value={circle.description}
              handleChange={handlerInput}
              label={t('description')}
              rows={4}
              required={true}
              maxLength={400}
            />
          </Box>
        </Box>

        <Box>
          <Box>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
                width: '100%',
              }}>
              {t('circleSpeciality')}
            </p>
            <Divider style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
          </Box>
          {arraySelectedSpecialities.map((selector, selectorIndex) => (
            <Box
              key={selector}
              style={{ display: 'flex', marginTop: '30px', justifyContent: 'space-between' }}>
              <Box style={{ width: '30%' }}>
                <CustomSpecialitySelect
                  specialities={specialities}
                  selector={selector}
                  selectorIndex={selectorIndex}
                  arraySelectedSpecialities={arraySelectedSpecialities}
                  handleSpecialtyChange={handleSpecialtyChange}
                />
              </Box>

              <Box>
                {selectorIndex > 0 ? (
                  <AppButton
                    theme={ButtonTheme.removeField}
                    type={'button'}
                    label={t('')}
                    startIcon={deleteButton}
                    marginStartIcon={'10px'}
                    handler={() => handleRemoveSpecialty(selectorIndex)}
                    vertical={true}
                  />
                ) : null}
              </Box>
            </Box>
          ))}

          <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}>
            <AppButton
              theme={ButtonTheme.newEvent}
              type={'button'}
              label={t('add_proffessionalType')}
              marginStartIcon={'10px'}
              startIcon={addButton}
              handler={handleAddSpecialty}
              vertical={true}
            />
          </Box>
        </Box>

        <Box>
          <Box>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
                width: '100%',
              }}>
              {t('circleParticipant')}
            </p>
            <Divider style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
          </Box>
          {participants.map((participant, index) => (
            <AddParticipant
              key={participant.id}
              id={participant.id}
              roles={roles}
              deleteParticipant={deleteParticipant}
              handleNumberChange={handleNumberChange}
              handleSelectedParticipant={handleSelectedParticipant}
              index={index}
              participant={participants}
            />
          ))}
          <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}>
            <AppButton
              theme={ButtonTheme.newEvent}
              type={'button'}
              label={t('addUserGroup')}
              marginStartIcon={'10px'}
              startIcon={addButton}
              handler={handleAddParticipant}
              vertical={true}
            />
          </Box>
        </Box>
        <Box>
          <Divider style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
        </Box>
        {errorMessage && (
          <Box mb={3}>
            <Alert severity="warning" key="errorMessage" id="errorMessage">
              {t(errorMessage)}
            </Alert>
          </Box>
        )}
        <Box display="flex" style={{ justifyContent: 'space-between' }}>
          <Box>
            <AppButton
              theme={ButtonTheme.whiteAndBlue}
              type={'button'}
              label={t('saveDraft')}
              marginStartIcon={'10px'}
              handler={() => {
                validateCircle() && updateOrCreateDraft(ROUTE_CIRCLE_CONFIGURATOR)
              }}
              vertical={true}
            />{' '}
            <AppButton
              theme={ButtonTheme.whiteAndBlue}
              type={'button'}
              label={t('cancel')}
              marginStartIcon={'10px'}
              handler={() => {
                navigate(ROUTE_CIRCLE_CONFIGURATOR)
              }}
              vertical={true}
            />{' '}
          </Box>
          <AppButton
            theme={ButtonTheme.newEvent}
            type={'button'}
            label={t('saveAndNext')}
            marginStartIcon={'10px'}
            handler={() => {
              validateCircle() && updateOrCreateDraft(ROUTE_ADD_NEW_CIRCLE_CIRCLE_CONFIGURATOR)
            }}
            vertical={true}
          />
        </Box>
      </Box>
    </>
  )
}
