import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Divider } from '@mui/material'
import styles from './GenericCard.module.css'

import { useTheme } from '@mui/material/styles'

interface Props {
  title: string
  handleMouseEnter: (name: string | null) => void
  handleMouseLeave: (nullValue: null) => void
  itemName: string | null
  children: React.ReactNode
  isHover?: boolean
  onClick?: (name: string) => void
  margin?: boolean
}

const GenericCard: React.FC<Props> = ({
  title,
  handleMouseEnter,
  handleMouseLeave,
  itemName,
  children,
  isHover,
  onClick,
  margin,
}) => {
  const theme = useTheme()

  return (
    <Card
      className={isHover ? styles.cardHover : styles.card}
      sx={{
        m: margin ? '18px' : 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        mt: 2,
        p: 2,

        [theme.breakpoints.down('md')]: {
          m: margin ? '18px' : 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
          mt: 2,
          p: 1,
        },
      }}
      onMouseEnter={() => handleMouseEnter(itemName)}
      onMouseLeave={() => handleMouseLeave(null)}
      onClick={() => onClick && onClick(itemName ?? '')}
    >
      <CardContent className={styles.cardContent}>
        <Box>{children}</Box>

        <Box className={styles.titleContent}>
          <Typography className={styles.title} variant="h5" component="div">
            {title}
          </Typography>
          <Divider className={styles.divider} />
        </Box>
      </CardContent>
    </Card>
  )
}

export default GenericCard
