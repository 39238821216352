import React, { useEffect, useState } from 'react'
import { Query, QueryParam } from '../../../../common/api/Query'
import { useTranslation } from 'react-i18next'
import { Box, Divider } from '@mui/material'
import { Actions, Field, Search, SearchValue } from '../../../../components/table_type/types'
import { useNavigate } from 'react-router-dom'
import genericStyle from '../../../../common/utils/generic.module.css'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import style from '.././AddNewCircle.module.css'
import { Header } from '../../../../components/header/Header'
import addButton from '../../../../assets/esfera/buttons/add-icon.svg'
import {
  CircleDTO,
  fromModel as circleDTOfromModel,
} from '../../../../modules/circle/models/CircleDTO'
import CustomStepper from '.././CustomStepper'
import { UserQuery } from '../../../../modules/users/models/User'
import { Searcher } from '../../../../components/table/Searcher'
import ModalFormsFromOtherCircles from './ModalFormsFromOtherCircles'
import ModalNewFormFromTemplate from './ModalNewFormFromTemplate'
import { steps } from '../Steps'
import ModalAddNewCircleNewForm from './add-new-form-modals/ModalAddNewCircleNewForm'
import { AppTable } from '../../../../components/table'
import { Form, FormQuery } from '../../../../modules/forms/models/Form'
import { getCircleAssignFormsContainer } from '../../../../container/circle-assign-form-module'
import { CircleAssignFormsService } from '../../../../modules/circle-assign-form/services/CircleAssignFormsService'
import { CIRCLE_ASSIGN_FORMS_SERVICE_KEY } from '../../../../modules/circle-assign-form'
import { getFormContainer } from '../../../../container/form-module'
import { FormService } from '../../../../modules/forms/services/FormService'
import { FORM_SERVICE_KEY } from '../../../../modules/forms'
import editIcon from '../../../../assets/newCircle/edit_icon.svg'
import deleteIcon from '../../../../assets/newCircle/remove_icon.svg'
import { FormType } from '../../../../modules/forms/enums/FormType'
import {
  CircleAssignFormDTO,
  fromModel,
} from '../../../../modules/circle-assign-form/models/CircleAssignFormDTO'
import { FormDTO, fromModel as formDTOfromModel } from '../../../../modules/forms/models/FormDTO'
import {
  ROUTE_ADD_NEW_CIRCLE_RESOURCES_CONFIGURATOR,
  ROUTE_ADD_NEW_CIRCLE_SYMPTOMS_CONFIGURATOR,
  ROUTE_CIRCLE_CONFIGURATOR,
} from '../../../../routes/routes-constants'
import { getCircleContainer } from '../../../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../../../modules/circle'
import { DeletingModal } from '../../../../components/modal/DeletingModal'

const formService = getFormContainer().get<FormService>(FORM_SERVICE_KEY)
const circleAssignFormsService = getCircleAssignFormsContainer().get<CircleAssignFormsService>(
  CIRCLE_ASSIGN_FORMS_SERVICE_KEY
)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

export function AddNewCircleFormConfigurator() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [circleDraft, setCircleDraft] = useState<CircleDTO>()
  // get circleDraftId from local storage
  const circleDraftId = localStorage.getItem('circleDraftID')
  const [isModalOtherFormOpen, setModalOtherFormOpen] = useState(false)
  const [isModalTemplateFormOpen, setModalTemplateFormOpen] = useState(false)
  const [circleForms, setCircleForms] = useState<Form[]>([])
  const [filteredForms, setFilteredForms] = useState<Form[]>([])
  const [circleAssignForm, setCircleAssignForm] = useState<CircleAssignFormDTO>()
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [formToDelete, setFormToDelete] = useState<Form | undefined>(undefined)

  const openDeleteModal = (form: Form) => {
    setFormToDelete(form)
    setDeleteModalOpen(true)
  }

  const closeDeleteModal = () => {
    setFormToDelete(undefined)
    setDeleteModalOpen(false)
  }
  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'allFields',
      label: t(''),
    },
  ])
  const [updateViewFormsUpdated, setUpdateViewFormsUpdated] = useState(false)
  const [modalNewForm, setModalNewFormOpen] = useState<{ open: boolean; editForm?: FormDTO }>({
    editForm: undefined,
    open: false,
  })

  useEffect(() => {
    if (!circleDraftId) {
      navigate(ROUTE_CIRCLE_CONFIGURATOR)
      return
    }
    circleService.getByCircleDraftID(circleDraftId).subscribe((circle) => {
      if (!circle) {
        navigate(ROUTE_CIRCLE_CONFIGURATOR)
        return
      }
      setCircleDraft(circleDTOfromModel(circle))
    })
  }, [])

  useEffect(() => {
    circleAssignFormsService.getByCircleID(circleDraftId ?? '').subscribe((res) => {
      if (!res) return
      setCircleAssignForm(fromModel(res))
      formService
        .getFilteredList(
          new Query({
            query: [
              new QueryParam<FormQuery>('ids', res.formsID),
              new QueryParam<FormQuery>('fillWithCreatorName', 'true'),
            ],
          })
        )
        .subscribe((res) => {
          setCircleForms(res.items)
          if (!searcher[0].value) {
            // in case not filtering set all forms
            setFilteredForms(res.items)
          }
        })
    })
  }, [updateViewFormsUpdated, modalNewForm, isModalOtherFormOpen, isModalTemplateFormOpen])

  useEffect(() => {
    const searchValue = searcher[0].value

    !searchValue || searchValue === ''
      ? setFilteredForms(circleForms)
      : setFilteredForms(
          circleForms.filter((form) => {
            return form.title.toLowerCase().includes(searchValue.toLowerCase())
          })
        )
  }, [searcher])

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => {
      setSearcher(svs)
    },
  }

  const tableFields: Field<Form>[] = [
    {
      name: 'title',
      label: t('name'),
    },
    { name: 'creator', label: t('author') },
    { name: 'specialities', label: t('specialty') },
  ]

  const tableActions: Actions<Form> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: (form) => {
          const formDTO = formDTOfromModel(form)
          setModalNewFormOpen({ open: true, editForm: formDTO })
        },
        icon: editIcon,
        label: 'edit',
        hidden: (form) => {
          return form.isTemplate !== FormType.Form || !form.circles.includes(circleDraft?.id ?? '')
        },
      },
      {
        handler: (form) => {
          openDeleteModal(form) // Abrir el modal de confirmación al hacer clic en eliminar
        },
        icon: deleteIcon,
        label: 'delete',
        hidden: () => {
          return false
        },
      },
    ],
  }

  const handleConfirmDelete = async () => {
    if (!circleAssignForm) return
    // delete form from circleAssignForm
    const circleAssignFormUpdate = circleAssignForm
    circleAssignFormUpdate.formsID = circleAssignFormUpdate.formsID.filter((formID) => {
      return formID !== formToDelete?.id
    })
    await circleAssignFormsService.update(circleAssignFormUpdate).toPromise()
    setCircleAssignForm(circleAssignFormUpdate)

    // if form is created on the configurator we delete it from the forms table
    if (formToDelete?.circles.includes(circleDraft?.id ?? '')) {
      await formService.delete(formToDelete?.id ?? '').toPromise()
    }
    setUpdateViewFormsUpdated(!updateViewFormsUpdated)
    closeDeleteModal()
  }

  return (
    <>
      <DeletingModal
        type={t('form')}
        open={isDeleteModalOpen}
        handleClose={closeDeleteModal}
        handleDelete={handleConfirmDelete}
      />
      <Box className={genericStyle.pageContainer}>
        <Header label={t('formConfigurator')} />
        <Box>
          <CustomStepper steps={steps} activeStep={3} />
        </Box>
        <Box>
          <Divider light style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
          <Box className={style.formTableContainer}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <p>{t('search')}</p>
              <Searcher search={search} isPreventDefault={true} />
            </Box>
            <AppButton
              theme={ButtonTheme.newEvent}
              type={'button'}
              label={t('newForm')}
              marginStartIcon={'10px'}
              startIcon={addButton}
              handler={() => {
                setModalNewFormOpen({ open: true, editForm: undefined })
              }}
              vertical={true}
            />
            <ModalAddNewCircleNewForm
              open={modalNewForm.open}
              onClose={() => setModalNewFormOpen({ open: false, editForm: undefined })}
              circleDraft={circleDraft}
              editForm={modalNewForm.editForm ?? undefined}
            />

            <AppButton
              theme={ButtonTheme.newEvent}
              type={'button'}
              label={t('formsFromOthersCircle')}
              marginStartIcon={'10px'}
              startIcon={addButton}
              handler={() => setModalOtherFormOpen(true)}
              vertical={true}
            />
            <ModalFormsFromOtherCircles
              open={isModalOtherFormOpen}
              onClose={() => setModalOtherFormOpen(false)}
              circleDraft={circleDraft}
            />
            <AppButton
              theme={ButtonTheme.newEvent}
              type={'button'}
              label={t('newFormFromTemplate')}
              marginStartIcon={'10px'}
              startIcon={addButton}
              handler={() => setModalTemplateFormOpen(true)}
              vertical={true}
            />
            <ModalNewFormFromTemplate
              open={isModalTemplateFormOpen}
              onClose={() => setModalTemplateFormOpen(false)}
              circleDraftID={circleDraft?.id ?? ''}
            />
          </Box>
          <Box
            display="flex"
            style={{ backgroundColor: '#fff' }}
            className={style.containerInput}></Box>
        </Box>

        <Box>
          <AppTable
            fields={tableFields}
            items={filteredForms}
            rowKeyField={'id'}
            actions={tableActions}
          />
        </Box>

        <Box display="flex" style={{ justifyContent: 'space-between' }}>
          <AppButton
            theme={ButtonTheme.whiteAndBlue}
            type={'button'}
            label={t('saveDraft')}
            marginStartIcon={'10px'}
            handler={() => {
              navigate(ROUTE_CIRCLE_CONFIGURATOR)
            }}
            vertical={true}
          />{' '}
          <Box style={{ display: 'flex' }}>
            <Box style={{ marginRight: '20px' }}>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('backOneToResources')}
                marginStartIcon={'10px'}
                handler={() => {
                  navigate(ROUTE_ADD_NEW_CIRCLE_RESOURCES_CONFIGURATOR)
                }}
                vertical={true}
              />
            </Box>
            <Box>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('saveAndNext')}
                marginStartIcon={'10px'}
                handler={() => {
                  navigate(ROUTE_ADD_NEW_CIRCLE_SYMPTOMS_CONFIGURATOR)
                }}
                vertical={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
