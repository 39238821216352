import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import {
  emptyPatientSymptomDTO,
  fromModel,
  PatientSymptomDTO,
} from 'modules/patient-data/models/PatientSymptomDTO'
import { Box, Checkbox, MenuItem, Select, Alert, Autocomplete, TextField } from '@mui/material'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { ROUTE_PATIENT_SYMPTOMS } from 'routes/routes-constants'
import { TextFieldItem } from 'components/form-card/TextFieldItem'
import { FormCard } from 'components/form-card/FormCard'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { getPatientDataContainer } from 'container/patient-data-module'
import { PATIENT_SYMPTOM_SERVICE_KEY, SYMPTOM_SERVICE_KEY } from 'modules/patient-data'
import { PatientSymptomService } from 'modules/patient-data/services/PatientSymptomService'
import { SymptomService } from 'modules/patient-data/services/SymptomService'
import genericStyle from 'common/utils/generic.module.css'
import { SymptomQuery } from 'modules/patient-data/models/Symptom'
import { ConditionField } from 'modules/patient-data/enums/PatientSymptomsNames'
import { FormFieldType } from 'modules/forms/enums/FormFieldType'
import styles from './Editor.module.css'
import { emptyPatientSymptomConditionDTO } from 'modules/patient-data/models/PatientSymptomConditionDTO'
import { EpilepticCrisis } from './epilepticalCrisis/EpilepticCrisis'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { v4 as uuidv4 } from 'uuid'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { useLocation, useNavigate } from 'react-router-dom'
import { BoolQueryParam, Query, QueryParam } from 'common/api/Query'
import { toModel as userToModel, emptyUserDTO } from 'modules/users/models/User'
import { LoadingSpinnerMini } from 'components/loading-spinner/LoadingSpinner'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

enum SymptomErrors {
  NO_SYMPTOM = 'aSymptomMustBeSelected',
  REQUIRED_FIELDS = 'requiredFields',
  INVALID_DATES = 'invalidDates',
}

type AutocompleteSymptomInterface = {
  pathology: string
  key: string
  value: string
  commonSymptom?: string
}

type SymptomsFormProps = {
  id?: string
  symptomName?: string
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const patientDataContainer = getPatientDataContainer()
const patientSymptomService = patientDataContainer.get<PatientSymptomService>(
  PATIENT_SYMPTOM_SERVICE_KEY
)
const symptomService = patientDataContainer.get<SymptomService>(SYMPTOM_SERVICE_KEY)

export function Editor(props: SymptomsFormProps) {
  const { t } = useTranslation()
  const location = useLocation()
  const navigateCreatingEpilepticCrisis = location.state?.epileptic ?? false
  const loggedUser = loggedUserService.get()
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const [symptomMap, setSymptomMap] = useState<Map<string, AutocompleteSymptomInterface>>(new Map())
  const [selectedSymptom, setSelectedSymptom] = useState<AutocompleteSymptomInterface>()
  const [patientSymptom, setPatientSymptom] = useState<PatientSymptomDTO>(emptyPatientSymptomDTO())
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [conditions, setConditions] = useState<ConditionField[]>([])
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const { selectedUserCircle } = useCircleConfiguration()

  // navigate direct to create epileptic crisis
  useEffect(() => {
    if (navigateCreatingEpilepticCrisis) {
      setSelectedSymptom({
        pathology: 'Epilepsia',
        key: '1111',
        value: 'Epilepsia',
        commonSymptom: 'false',
      })
    }
  }, [])

  useEffect(() => {
    if (!selectedUserCircle) {
      return
    }
    setIsEditing(!!props.id)
    setIsLoading(true)
    if (props.id) {
      patientSymptomService.getByID(props.id).subscribe((res) => {
        if (res) {
          setPatientSymptom(fromModel(res))
          setIsLoading(false)
        }
      })
    } else {
      if (loggedUser?.id && selectedUserCircle?.id) {
        setPatientSymptom(
          Object.assign(
            { ...patientSymptom },
            {
              creatorID: loggedUser.id,
            }
          )
        )
        if (!isEditing) setIsLoading(false)
      }
    }

    if (!selectedUserCircle || isEditing) {
      return
    }
    const updatedSymptomMap = new Map(symptomMap)
    const uniqueSymptomIds = new Set()

    if (selectedUserCircle.circle.symptoms && selectedUserCircle.circle.symptoms.length > 0) {
      selectedUserCircle.circle.symptoms.forEach((p) => {
        const symptomId = p.idSymptom ?? ''

        if (!uniqueSymptomIds.has(symptomId)) {
          const symptomData: AutocompleteSymptomInterface = {
            commonSymptom: 'false',
            key: symptomId,
            value: p.name ?? '',
            pathology: selectedUserCircle.circle.name ?? '',
          }
          updatedSymptomMap.set(symptomId, symptomData)
          uniqueSymptomIds.add(symptomId)
        }
      })
    }

    symptomService
      .getFilteredList(
        new Query<SymptomQuery>({
          query: [new QueryParam<SymptomQuery>('commonSymptom', new BoolQueryParam(true))],
        })
      )
      .subscribe((sl) => {
        sl.items.forEach((s) => {
          const symptomId = s.id ?? ''
          if (!uniqueSymptomIds.has(symptomId)) {
            const symptomData: AutocompleteSymptomInterface = {
              commonSymptom: s.commonSymptom ? 'true' : 'false',
              pathology: '',
              key: symptomId,
              value: s.name ?? '',
            }
            updatedSymptomMap.set(symptomId, symptomData)
            uniqueSymptomIds.add(symptomId)
          }
        })
        setIsLoading(false)
        setSymptomMap(updatedSymptomMap)
      })
  }, [])

  // Conditions are not used
  // Commented for future use
  /*
  useEffect(() => {
    if (!selectedSymptom) {
      return
    }
    setErrorMessage('')
    const conds = patientSymptoms.get(selectedSymptom.value) ?? []
    setConditions(conds)
    setPatientSymptom(
      Object.assign(
        { ...patientSymptom },
        {
          conditions: conds.map((c) => ({
            name: c.name,
            type: c.valueType,
            value: falsyValue(c.valueType),
          })),
        }
      )
    )
  }, [selectedSymptom])
  */

  useEffect(() => {
    if (props.id && symptomMap.has(patientSymptom.symptomID)) {
      setSelectedSymptom(symptomMap.get(patientSymptom.symptomID))
      setIsLoading(false)
    }
    if (!props.id) setIsLoading(false)
  }, [symptomMap])

  // const falsyValue = (vt: ValueTypes): any => {
  //   switch (vt) {
  //     case ValueTypes.Bool:
  //       return false
  //     case ValueTypes.Float:
  //       return 0
  //     default:
  //       return undefined
  //   }
  // }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setPatientSymptom(Object.assign({ ...patientSymptom }, { [e.target.name]: e.target.value }))

  const handleStartDate = (event: any) =>
    event &&
    setPatientSymptom(Object.assign({ ...patientSymptom }, { symptomStartDate: event.toDate() }))

  const handleEndDate = (event: any) =>
    event && setPatientSymptom(Object.assign({ ...patientSymptom }, { finishDate: event.toDate() }))

  const handleSymptomID = (value: AutocompleteSymptomInterface | null) => {
    if (!value) {
      setSelectedSymptom({ pathology: '', key: '', value: '' })
      // si no tenemos valor re-inicializamos los estaods
      setPatientSymptom((prevState) => ({
        ...prevState,
        symptomID: '',
        userCircleID: '',
        userID: '',
      }))
      return
    }

    setSelectedSymptom(value)

    setPatientSymptom(
      Object.assign(
        { ...patientSymptom },
        {
          symptomID: value.key,
          userCircleID: selectedUserCircle?.id,
          userID: userToModel(selectedUserCircle?.user ?? emptyUserDTO()).id,
        }
      )
    )
  }

  const createField = (c: ConditionField): JSX.Element => {
    switch (c.fieldType) {
      case FormFieldType.CheckBox:
        return (
          <Box mb={3} key={c.name + uuidv4()}>
            <p className={styles.label + ' ' + styles.checkBoxLabel}>{c.name}</p>
            <Checkbox
              className={styles.styleCheckBox}
              name={c.name}
              checked={patientSymptom.conditions.find((cond) => cond.name === c.name)?.value}
              onChange={(e) => handleCheckbox(e, c.valueType)}
            />
          </Box>
        )
      case FormFieldType.Select:
        return (
          <Box mb={3} key={c.name + uuidv4()}>
            <p className={styles.label}>{c.name}</p>
            <Select
              key={c.name}
              id={c.name}
              variant={'outlined'}
              value={
                patientSymptom.conditions.find(
                  (cond) => cond.name === c.name && !Object.is(+cond.value, NaN)
                )?.value || ''
              }
              onChange={(e) => handleSelect(e, c.name)}
              className={styles.select}
              required={true}
            >
              {Array.from(c.options ? c.options.keys() : []).map((k) => (
                <MenuItem key={k + uuidv4()} value={c.options ? c.options.get(k) : ''}>
                  {k}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )
      default:
        return <></>
    }
  }

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, type: number) => {
    const c = emptyPatientSymptomConditionDTO()
    c.name = e.target.name
    c.type = type
    c.value = e.target.checked
    const newConditions = patientSymptom.conditions.map((cond) => {
      if (cond.name === c.name) {
        return c
      }
      return cond
    })
    setPatientSymptom(Object.assign({ ...patientSymptom }, { conditions: newConditions }))
  }

  const handleSelect = (event: any, name: string) =>
    setPatientSymptom(
      Object.assign(
        { ...patientSymptom },
        {
          conditions: patientSymptom.conditions.map((cond) => {
            if (cond.name === name) {
              return {
                name: cond.name,
                type: cond.type,
                value: +(event.target.value as number),
              }
            }
            return cond
          }),
        }
      )
    )

  const goBack = () => navigate(ROUTE_PATIENT_SYMPTOMS)

  const validateSymptom = () => {
    if (!patientSymptom.symptomID) {
      setErrorMessage(SymptomErrors.NO_SYMPTOM)
      return false
    }

    // check date values are not invalid
    if (
      isNaN(patientSymptom.symptomStartDate.getTime()) ||
      isNaN(patientSymptom.finishDate.getTime())
    ) {
      setErrorMessage(SymptomErrors.INVALID_DATES)
      return false
    }
    // check dates are not bigger than today
    const today = new Date()
    if (patientSymptom.symptomStartDate > today || patientSymptom.finishDate > today) {
      setErrorMessage(SymptomErrors.INVALID_DATES)
      return false
    }

    // check end date is not lower than start date
    if (patientSymptom.symptomStartDate > patientSymptom.finishDate) {
      setErrorMessage(SymptomErrors.INVALID_DATES)
      return false
    }

    const required = conditions.filter((c) => c.required)
    let found = true
    required.forEach((c) => {
      if (
        !patientSymptom.conditions.find(
          (cond) => c.options && Array.from(c.options.values()).find((v) => +v === cond.value)
        )
      ) {
        found = false
      }
    })
    if (!found) {
      setErrorMessage(SymptomErrors.REQUIRED_FIELDS)
      return false
    }

    setErrorMessage('')
    return true
  }

  const saveSymptom = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateSymptom()) {
      return
    }

    if (props.id) {
      patientSymptomService.update(patientSymptom).subscribe(() => goBack())
      return
    }
    patientSymptomService.add(patientSymptom).subscribe(() => goBack())
  }

  const handlerDetailsOpen = (e: boolean) => {
    setDetailsOpen(e)
  }

  // FIXME in order to fix invalid dates, use dateTimePicker

  return (
    <Box className={genericStyle.pageContainer}>
      {isLoading && <LoadingSpinnerMini className={styles.loaderBox} />}
      {!isLoading && (
        <Box>
          <Box className={styles.title}>{t('symptoms')}</Box>
          <FormCard>
            <form onSubmit={saveSymptom}>
              {isEditing && props.symptomName && (
                <Box className={styles.symtomName}>{props.symptomName}</Box>
              )}
              {!detailsOpen && !isEditing && (
                <Box className={styles.inputContainer}>
                  <Box className={styles.inputBox}>
                    <Autocomplete
                      key={patientSymptom.id}
                      id={patientSymptom.id}
                      options={Array.from(symptomMap.values()).sort((a, b) => {
                        const sort = a.pathology.localeCompare(b.pathology)
                        return sort !== 0 ? sort : a.value.localeCompare(b.value)
                      })}
                      groupBy={(option) => option.pathology}
                      getOptionLabel={(opt) => opt.value}
                      value={selectedSymptom}
                      onChange={(_, v) => handleSymptomID(v)}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={t('symptom')} />
                      )}
                      className={styles.autocompleteStyle}
                      noOptionsText={t('noElementsAvailable')}
                      disabled={isEditing}
                    />
                  </Box>
                  {selectedSymptom?.pathology !== 'Epilepsia' && (
                    <>
                      <Box className={styles.dateTimeField}>
                        <Box className={styles.labelContainer}>
                          <span className={styles.labelText}>{t('startDateAndTime')}</span>
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                          <DateTimePicker
                            maxDate={dayjs()}
                            maxDateTime={dayjs()}
                            PopperProps={{
                              sx: {
                                '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                                  textTransform: 'capitalize',
                                },
                              },
                            }}
                            components={{
                              OpenPickerIcon: CalendarMonthOutlinedIcon, // sustituye el icono en vista escritorio
                            }}
                            InputProps={{
                              endAdornment: <CalendarMonthOutlinedIcon />, // para que se vea el icono en móvil
                            }}
                            onError={(reason, value) => {
                              switch (reason) {
                                case 'invalidDate':
                                  setDateTimePickerError(t('invalidDateMessage'))
                                  break
                                case 'minDate':
                                  setDateTimePickerError(t('minDateMessage'))
                                  break
                              }
                            }}
                            renderInput={(props) => (
                              <TextField
                                variant={'outlined'}
                                helperText={props.error && DateTimePickerError}
                                {...props}
                              />
                            )}
                            ampm={false}
                            inputFormat="DD/MM/YYYY HH:mm"
                            onChange={handleStartDate}
                            value={patientSymptom.symptomStartDate}
                            label=""
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box className={styles.dateTimeField}>
                        <Box className={styles.labelContainer}>
                          <span className={styles.labelText}>{t('endDateAndTime')}</span>
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                          <DateTimePicker
                            maxDate={dayjs()}
                            PopperProps={{
                              sx: {
                                '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                                  fontFamily: 'Poppins',
                                  textTransform: 'capitalize',
                                },
                              },
                            }}
                            components={{
                              OpenPickerIcon: CalendarMonthOutlinedIcon, // sustituye el icono en vista escritorio
                            }}
                            InputProps={{
                              endAdornment: <CalendarMonthOutlinedIcon />, // para que se vea el icono en móvil
                            }}
                            onError={(reason, value) => {
                              switch (reason) {
                                case 'invalidDate':
                                  setDateTimePickerError(t('invalidDateMessage'))
                                  break
                                case 'minDate':
                                  setDateTimePickerError(t('minDateMessage'))
                                  break
                              }
                            }}
                            renderInput={(props) => (
                              <TextField
                                sx={{
                                  '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': {
                                    paddingRight: '17px',
                                  },
                                  '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                                    fontFamily: 'Poppins',
                                  },
                                }}
                                helperText={props.error && DateTimePickerError}
                                variant={'outlined'}
                                InputProps={{
                                  endAdornment: <CalendarMonthOutlinedIcon />,
                                }}
                                {...props}
                              />
                            )}
                            key={'finishDate'}
                            ampm={false}
                            inputFormat="DD/MM/YYYY HH:mm"
                            onChange={handleEndDate}
                            value={patientSymptom.finishDate}
                            minDate={dayjs(patientSymptom.symptomStartDate || undefined)}
                            label={null}
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  )}
                </Box>
              )}

              {selectedSymptom?.pathology === 'Epilepsia' && (
                <>
                  <EpilepticCrisis
                    id={props.id}
                    symptom={selectedSymptom}
                    userCircleID={selectedUserCircle?.id ?? ''}
                    loggedUserID={loggedUser?.id}
                    detailsOpen={(e) => handlerDetailsOpen(e)}
                  />
                </>
              )}

              {selectedSymptom?.pathology !== 'Epilepsia' && (
                <>
                  {conditions.map((c) => createField(c))}

                  <Box mb={3}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      <span>{t('comments')}</span>
                      <TextFieldItem
                        field="comment"
                        value={patientSymptom.comment}
                        type={'text'}
                        handleChange={handleInput}
                        rows={10}
                        required={false}
                        label=""
                      />
                    </Box>
                  </Box>
                  {errorMessage && (
                    <Box mb={3}>
                      <Alert severity="warning" key="errorMessage" id="errorMessage">
                        {t(errorMessage)}
                        {errorMessage === SymptomErrors.REQUIRED_FIELDS
                          ? ': ' +
                            conditions
                              .filter((c) => c.required)
                              .map((c) => c.name)
                              .join(', ')
                          : ''}
                      </Alert>
                    </Box>
                  )}
                  <Box mb={3} display="flex" justifyContent="space-between">
                    <AppButton
                      theme={ButtonTheme.whiteAndBlue}
                      type={'button'}
                      label={t('back')}
                      handler={goBack}
                    />
                    <AppButton
                      theme={ButtonTheme.NewPrimary}
                      type={'submit'}
                      label={t('save')}
                      handler={saveSymptom}
                    />
                  </Box>
                </>
              )}
            </form>
          </FormCard>
        </Box>
      )}
    </Box>
  )
}
