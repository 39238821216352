import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_QUERY } from 'routes/routes-constants'
import { Query, QueryParam } from 'common/api/Query'
import { Consulta } from 'modules/messenger_consulta/models/Consulta'
import { getMessengerConsultaContainer } from 'container/messenger-consulta-module'
import { ConsultaService } from 'modules/messenger_consulta/services/ConsultaService'
import { CONSULTA_SERVICE_KEY } from 'modules/messenger_consulta'
import { getUserContainer } from 'container/user-module'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import style from './ActiveQueriesCard.module.css'
import queryIcon from 'assets/dashboard/queryIcon.svg'
import { listItemTextStyle } from '../notification-card-patient/NotificationsCardStyleMui'
import { ConsultaDTO } from 'modules/messenger_consulta/models/ConsultaDTO'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

type ActiveQueriesCardProps = {}

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const queryService = getMessengerConsultaContainer().get<ConsultaService>(CONSULTA_SERVICE_KEY)

export const ActiveQueriesCard: React.FC<ActiveQueriesCardProps> = () => {
  const loggedUser = loggedUserService.get()
  const { selectedUserCircle } = useCircleConfiguration()
  const [conversationCollection, setConversationCollection] = useState<ConsultaDTO[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (!loggedUser?.id) return

    queryService
      .getFilteredItems(
        new Query({
          query: [
            new QueryParam<Consulta>('userID', selectedUserCircle?.user.id ?? ''),
            new QueryParam<Consulta>('circleID', selectedUserCircle?.circle?.id ?? ''),
          ],
        })
      )
      .subscribe((res) => {
        setConversationCollection(res)
        setIsLoading(false)
      })
  }, [isLoading, selectedUserCircle])

  const handlerGotoQueries = (conversation: ConsultaDTO) => {
    const { id } = conversation

    navigate(ROUTE_QUERY, {
      state: { conversationId: id },
    })
  }

  const handlerGoQueriesWithoutdata = () => {
    navigate(ROUTE_QUERY)
  }
  return (
    <DashboardSectionCard
      label={'activeQuerys'}
      showMore={false}
      onClick={handlerGoQueriesWithoutdata}
    >
      {conversationCollection.length > 0 &&
        conversationCollection.slice(-3).map((conversation) => (
          <ListItem
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => handlerGotoQueries(conversation)}
            key={conversation.id}
          >
            <Box width={'100%'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                mr={1}
                ml={1}
                className={style.chatContainer}
              >
                <ListItemAvatar>
                  <Avatar
                    src={queryIcon}
                    style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0' }}
                  />
                </ListItemAvatar>
                <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                  {conversation.name}
                </ListItemText>
              </Box>
            </Box>
          </ListItem>
        ))}
    </DashboardSectionCard>
  )
}
