import { UserEpilepticalConfigDTO } from '../../modules/epileptical/models/UserEpilepticalConfigDTO'
import { useTranslation } from 'react-i18next'
import { zoneTypes } from '../../modules/epileptical/enums/Zone'
import { durationTypes } from '../../modules/epileptical/enums/Duration'
import { awarenessTypes } from '../../modules/epileptical/enums/Awareness'
import { movementTypes } from '../../modules/epileptical/enums/Movement'
import { visionTypes } from '../../modules/epileptical/enums/Vision'
import { skinColorTypes } from '../../modules/epileptical/enums/SkinColor'
import { symptomTypes } from '../../modules/epileptical/enums/Symptom'
import { mouthTypes } from '../../modules/epileptical/enums/Mouth'
import { afterDurationTypes } from '../../modules/epileptical/enums/AfterDuration'
import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import styles from './StringTooltip.module.css'

type StringTooltipProps = {
  readonly detailConfig: UserEpilepticalConfigDTO[]
  readonly index?: number
  readonly withIndex?: boolean
}
export const StringTooltip = (props: StringTooltipProps) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box>
        <p className={styles.boldText}>{t('beforeCrisis') + ' ' + t('childCrisisComment')}</p>
        <Typography className={styles.commentText}>
          -{' '}
          {props.detailConfig.length > 0 && props.detailConfig[0].comment
            ? props.detailConfig[0].comment
            : t('noComments')}
        </Typography>
      </Box>
      <Divider className={styles.divider} />
      <Box>
        <p className={styles.boldText}>{t('duringTheCrisis')}</p>
        <Typography className={styles.commentText}>
          - {t('zone')}: {props.detailConfig.length > 0 && zoneTypes()[props.detailConfig[0].zone]}
        </Typography>
        <Typography className={styles.commentText}>
          - {t('eventDuration')}:{' '}
          {props.detailConfig.length > 0 && durationTypes()[props.detailConfig[0].duration]}
        </Typography>
        <Typography className={styles.commentText}>
          - {t('awareness')}:{' '}
          {props.detailConfig.length > 0 && awarenessTypes()[props.detailConfig[0].awareness]}
        </Typography>
        <Typography className={styles.commentText}>
          - {t('movement')}:{' '}
          {props.detailConfig.length > 0 && movementTypes()[props.detailConfig[0].movement]}
        </Typography>
        <Typography className={styles.commentText}>
          - {t('vision')}:{' '}
          {props.detailConfig.length > 0 && visionTypes()[props.detailConfig[0].vision]}
        </Typography>
        <Typography className={styles.commentText}>
          - {t('skinColor')}:{' '}
          {props.detailConfig.length > 0 && skinColorTypes()[props.detailConfig[0].skinColor]}
        </Typography>
        <Typography className={styles.commentText}>
          - {t('symptom')}:{' '}
          {props.detailConfig.length > 0 && symptomTypes()[props.detailConfig[0].symptom]}
        </Typography>
        <Typography className={styles.commentText}>
          - {t('mouth')}:{' '}
          {props.detailConfig.length > 0 && mouthTypes()[props.detailConfig[0].mouth]}
        </Typography>
      </Box>
      <Divider className={styles.divider} />
      <Box>
        <p className={styles.boldText}>{t('afterTheCrisis')}</p>
      </Box>
      <Typography className={styles.commentText}>
        - {t('eventDuration')}:{' '}
        {props.detailConfig.length > 0 && afterDurationTypes()[props.detailConfig[0].afterDuration]}
      </Typography>
      {props.detailConfig.length > 0 ? (
        props.detailConfig[0].afterSymptom ? (
          <Typography className={styles.commentText}>
            - {props.detailConfig.length > 0 && props.detailConfig[0].afterSymptom.join(', ')}
          </Typography>
        ) : null
      ) : null}
    </Box>
  )
}
