import React from 'react'
import { Action } from '../table_type/types'
import { Box, Skeleton } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { makeStyles } from '@mui/styles'
import style from './AppTable.module.css'

const useStyles = makeStyles(() => ({
  label: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
  },
  root: {
    fontFamily: 'Poppins',
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontFamily: 'Poppins',
    },
  },
  font: {
    fontFamily: 'Poppins',
  },
}))

// AUTOCOMPLETE
interface SearcherAutocompleteProps {
  isLoading: boolean
  autocompleteAction: Action
  autocompleteLabel: string
  autocompleteItems: string[]
}

export function SearcherAutocomplete(props: SearcherAutocompleteProps) {
  const classes = useStyles()

  if (props.isLoading) {
    return <Skeleton style={{ width: '40px', height: '16px' }} />
  }

  return (
    <Autocomplete
      onChange={props.autocompleteAction.handleAction}
      disablePortal
      options={props.autocompleteItems}
      className={style.searchField}
      renderInput={(params) => (
        <TextField
          sx={{
            '.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root': {
              fontFamily: 'Poppins',
            },
            '.MuiInputBase-input': {
              fontFamily: 'Poppins',
            },
            '.MuiOutlinedInput-input': {
              fontFamily: 'Poppins',
            },
            '.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
              fontFamily: 'Poppins',
            },
            '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
              fontFamily: 'Poppins',
            },
            '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
              fontFamily: 'Poppins',
            },
          }}
          {...params}
          label={props.autocompleteLabel}
          variant="outlined"
          size="small"
          fullWidth
        />
      )}
      classes={{
        option: classes.font,
        groupLabel: classes.font,
      }}
    />
  )
}

// TEXTFIELD
interface SearchTextFieldProps {
  name: string
  label: string
  disabled: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  type: string
  value: string
}

export function SearcherTextField(props: SearchTextFieldProps) {
  const classes = useStyles()

  return (
    <Box className={style.searchField} key={props.name}>
      <TextField
        disabled={props.disabled}
        sx={{
          '& .MuiFormLabel-root': {
            fontFamily: 'Poppins',
            zIndex: 0,
          },
          '& .MuiInputBase-input': {
            fontFamily: 'Poppins',
          },
          '& .MuiOutlinedInput-input': {
            fontFamily: 'Poppins',
          },
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            fontFamily: 'Poppins',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            fontFamily: 'Poppins',
          },
        }}
        InputProps={{
          style: { fontFamily: 'Poppins' },
          classes: {
            root: classes.root,
            input: classes.label,
          },
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        name={props.name}
        label={props.label}
        style={{
          width: '100%',
          fontFamily: 'Poppins',
        }}
        onChange={props.handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault() // Esto previene el comportamiento predeterminado de recargar la página
          }
        }}
        type={props.type ?? 'text'}
        value={props.value ?? ''}
        variant="outlined"
        size="small"
      />
    </Box>
  )
}

// DATEPICKER
interface SearchDatePickerProps {
  name: string
  placeholder: string
  onErrorPicker: (v: string) => void
  handleDate: (value: any, keyboardInputValue?: string) => void
  type: string
  value: string
  DateTimePickerError: boolean
}

export function SearcherDatePicker(props: SearchDatePickerProps) {
  const classes = useStyles()

  return (
    <Box className={style.searchField}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
        <DatePicker
          PopperProps={{
            sx: {
              '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
              },
              '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                fontFamily: 'Poppins',
              },
              '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                fontFamily: 'Poppins',
              },
              '& .css-3eghsz-PrivatePickersYear-button': {
                fontFamily: 'Poppins',
              },
            },
          }}
          onError={(reason, value) => {
            switch (reason) {
              case 'invalidDate':
                props.onErrorPicker('invalidDateMessage')
                break
              case 'minDate':
                props.onErrorPicker('minDateMessage')
                break
            }
          }}
          renderInput={(renderProps) => (
            <TextField
              disabled={renderProps.disabled}
              InputProps={{
                classes: {
                  root: classes.root,
                  input: classes.label,
                },
              }}
              placeholder={props.placeholder}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                },
              }}
              sx={{
                '& .MuiInputBase-input::placeholder': {
                  color: '#666666',
                  opacity: 1, // Asegúrate de que la opacidad no esté afectando la visibilidad del color
                },
                '.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
                  fontFamily: 'Poppins',
                },
                '.MuiInputBase-input': {
                  fontFamily: 'Poppins',
                },
                '.MuiOutlinedInput-input': {
                  fontFamily: 'Poppins',
                },
                '.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
                  fontFamily: 'Poppins',
                },
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  fontFamily: 'Poppins',
                },
                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                  fontFamily: 'Poppins',
                },
              }}
              fullWidth
              size="small"
              helperText={renderProps.error && props.DateTimePickerError}
              {...renderProps}
            />
          )}
          inputFormat="DD/MM/YYYY"
          onChange={props.handleDate}
          value={props.value ? new Date(props.value) : null}
        />
      </LocalizationProvider>
    </Box>
  )
}
