import { Box, FormControlLabel, Modal, Radio, RadioGroup, Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormEvent, useEffect, useState, useRef } from 'react'
import style from './ConversationEditor.module.css'
import { Conversation } from 'modules/messenger/models/Conversation'
import { getUserContainer } from 'container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from 'modules/users'
import { Query } from 'common/api/Query'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { ConsultaDTO, toModel as toModelQuery } from 'modules/messenger_consulta/models/ConsultaDTO'
import close from 'assets/table_icons/ico-eliminar.svg'
import { Header } from 'components/header/Header'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { ModalEpilepticCrisis } from './ModalEpilepticCrisis'
import { getQueryTypeContainer } from 'container/queryType-module'
import { IQueryTypeApi } from 'modules/query-type/api/QueryTypeApi'
import { QUERY_TYPE_SERVICE_KEY } from 'modules/query-type'
import { QueryType, QueryTypeQuery } from 'modules/query-type/models/QueryType'
import { emptyList, ItemList } from 'common/models/ItemList'
import { Consulta } from 'modules/messenger_consulta/models/Consulta'
import { v4 as uuidv4 } from 'uuid'
import { ReadingStatus } from 'modules/messenger_consulta/enums/ReadingStatus'
import { getMessengerConsultaContainer } from 'container/messenger-consulta-module'
import { CONSULTA_SERVICE_KEY } from 'modules/messenger_consulta'
import { ConsultaService } from 'modules/messenger_consulta/services/ConsultaService'
import { ProfessionalQuery } from 'modules/messenger_consulta/models/ProfessionalQuery'
import { getUserCircleContainer } from 'container/user-circle-module'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from 'modules/user-circle'
import { UserCircleWithCircle } from 'modules/user-circle/models/UserCircleWithCircle'
import { UserCircleWithCircleDTO } from 'modules/user-circle/models/UserCircleWithCircleDTO'
import { User } from 'modules/users/models/User'
import { QueryUserGender } from 'modules/messenger_consulta/models/ConsultaUser'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

type NewConversationProps = {
  conversation?: Conversation
  query?: Consulta
  handleClose: () => void
  handleSave: (c: Consulta, isUpdate: boolean) => void
}

export interface ObjectForm {
  whatHappen: string[]
  currentTreatment: string
  timeWithoutCrisis: string
  symptom: string[]
  typeCrisis: string
  durationCrisis: string
  healthcare: string
  medicationAdministered: string
  timeWithoutAwareness: string
  nextAppointment: Date | null
  dontKnowAppointment: string
}

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const queryService = getMessengerConsultaContainer().get<ConsultaService>(CONSULTA_SERVICE_KEY)
const queryTypeService = getQueryTypeContainer().get<IQueryTypeApi>(QUERY_TYPE_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)

export const ConversationEditor = (props: NewConversationProps): JSX.Element => {
  const { t } = useTranslation()
  const { selectedUserCircle } = useCircleConfiguration()
  const loggedUser = loggedUserService.get()
  const [error, setError] = useState<string>('')
  const [circleConversation, setCircleConversation] = useState<string>('')
  const [reasonOfInquiry, setReasonOfInquiry] = useState<string>('')
  const [nextForm, setNextForm] = useState<boolean>(false)
  const [userCircles, setUserCircles] = useState<UserCircleWithCircle[]>([])
  const [userCircleSelected, setUserCircleSelected] = useState<UserCircleWithCircleDTO>()
  const [queryTypes, setQueryTypes] = useState<ItemList<QueryType>>(emptyList())
  const queryID = useRef('')
  const [message, setMessage] = useState<string>('')
  const [AcceptQuery, setAcceptQuery] = useState<ProfessionalQuery>()
  const [object, setObject] = useState<ObjectForm>({
    whatHappen: [],
    currentTreatment: '',
    timeWithoutCrisis: '',
    symptom: [],
    typeCrisis: '',
    durationCrisis: '',
    healthcare: '',
    medicationAdministered: '',
    timeWithoutAwareness: '',
    nextAppointment: null,
    dontKnowAppointment: '',
  })
  const [professionalsUsers, setProfessionalsUsers] = useState<User[]>([])

  useEffect(() => {
    if (!selectedUserCircle?.id) {
      return
    }
    userCircleService
      .getUserCircleByUserIDWithCircleForQuery(selectedUserCircle.user.id)
      .subscribe((res) => {
        if (!res) return
        setUserCircles(res)
      })
    // recuperamos los objetos usuario de los profesionales
    if (Array.isArray(selectedUserCircle.profSmsID)) {
      selectedUserCircle.profSmsID.forEach((p) => {
        userService.getByID(p).subscribe((res) => {
          if (!res) return
          setProfessionalsUsers((prev) => [...prev, res])
        })
      })
    } else {
      userService.getByID(selectedUserCircle.profSmsID).subscribe((res) => {
        if (!res) return
        setProfessionalsUsers((prev) => [...prev, res])
      })
    }
  }, [selectedUserCircle])

  useEffect(() => {
    if (!selectedUserCircle?.id) {
      return
    }
    queryService
      .getProfessionalQuery(selectedUserCircle.user.id, selectedUserCircle.circle.id)
      .subscribe((res) => {
        setAcceptQuery(res)
      })
  }, [selectedUserCircle, userCircleSelected])

  useEffect(() => {
    if (!userCircleSelected?.id) return
    queryTypeService
      .getFilteredList(
        new Query<QueryTypeQuery>({
          sort: [{ field: 'name' }],
          query: [
            {
              name: 'circleID',
              value: userCircleSelected?.circle.id,
            },
          ],
        })
      )
      .subscribe((res) => {
        setQueryTypes(res)
      })
  }, [userCircleSelected])

  const handleMessage = (mess: string) => {
    setMessage(mess)
  }

  const handleObject = (objt: ObjectForm) => {
    setObject(objt)
  }

  const bold = (strings: any, ...expressions: any) => {
    let finalString = ''

    expressions.forEach((value: any, i: any) => {
      finalString += `<b>${strings[i]}</b>${value}`
    })

    finalString += strings[strings.length - 1]

    return finalString
  }

  const saveConversation = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (object.dontKnowAppointment !== '') {
      object.nextAppointment = null
    }
    const save = JSON.stringify(object)
    const formatted = JSON.parse(save) as ObjectForm
    if (reasonOfInquiry === t('Crisis epiléptica')) {
      let appointment = true
      if (formatted.nextAppointment == null && formatted.dontKnowAppointment === '')
        appointment = false

      if (
        formatted.whatHappen.length === 0 ||
        !formatted.currentTreatment ||
        formatted.symptom.length === 0 ||
        !appointment
      ) {
        setError('Los campos con asterisco son obligatorios')
        return
      }
    }

    const symptomsTranslated = formatted.symptom
      .map((s: string) => t(s.replace(' ', '')))
      .join(', ')
    const whatHappenTranslated = formatted.whatHappen
      .map((s: string) => t(s.replace(' ', '')))
      .join(', ')
    const stringForm = bold`¿Qué ha ocurrido?: ${whatHappenTranslated}
    Tratamiento actual: ${formatted.currentTreatment}
    ¿Cuánto tiempo llevaba sin crisis?: ${formatted.timeWithoutCrisis}
    ¿Ha habido algún desencadenante?: ${symptomsTranslated}
    ¿Cómo ha sido la crisis?: ${formatted.typeCrisis}
    ¿Cuánto tiempo ha durado?: ${formatted.durationCrisis}
    ¿Ha requerido asistencia sanitaria?: ${formatted.healthcare}
    ¿Ha tenido que administrar algún medicamento?: ${formatted.medicationAdministered}
    ¿Cuánto tiempo ha tardado en recuperar la conciencia del habla?: ${
      formatted.timeWithoutAwareness
    }
    ¿Cuándo tiene su próxima cita?: ${
      formatted.nextAppointment
        ? new Date(formatted.nextAppointment).toLocaleDateString('es', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
        : formatted.dontKnowAppointment
    }`

    if (userCircleSelected && loggedUser) {
      const id = uuidv4()
      const idMessage = uuidv4()

      const newQuery: ConsultaDTO = {
        id,
        name: reasonOfInquiry,
        users: [
          {
            userID: loggedUser.id,
            consultaID: id,
            isAdmin: false,
            userGender: loggedUser.gender,
          },
          ...(Array.isArray(userCircleSelected.profSmsID)
            ? userCircleSelected.profSmsID.map((p) => ({
                userID: p,
                consultaID: id,
                isAdmin: true,
                userGender: professionalsUsers.find((u) => u.id)?.gender ?? QueryUserGender.female,
              }))
            : [
                {
                  userID: userCircleSelected.profSmsID,
                  consultaID: id,
                  isAdmin: true,
                  userGender:
                    professionalsUsers.find((u) => userCircleSelected.profSmsID)?.gender ??
                    QueryUserGender.female,
                },
              ]),
        ],
        circleID: userCircleSelected?.circle.id,
        userCircleID: userCircleSelected?.id,
        closed: false,
        consultaType: queryID.current,
        description: 'Consulta',
        messages:
          reasonOfInquiry === t('Crisis epiléptica')
            ? [
                {
                  id: idMessage,
                  text: stringForm,
                  author: loggedUser.id,
                  consultaID: id,
                  createdAt: new Date(),
                  message2Users: [
                    {
                      userID: userCircleSelected.userID, // loggedUser.id,
                      messageID: idMessage,
                      readingStatus: ReadingStatus.Created,
                    },
                  ],
                  isPinned: false,
                  reactions: [],
                  rootID: '',
                  parentID: '',
                  files: undefined,
                },
              ]
            : [],
        pathologyID: userCircleSelected?.id,
        unreadMessages: 0,
      }

      props.handleSave(toModelQuery(newQuery), props.query !== undefined)
    }
  }

  const handleCloseEpilepticCrisisModal = () => {
    setNextForm(false)
    props.handleClose()
  }

  const useStyles = () => ({
    label: {
      fontFamily: 'Poppins',
    },
  })

  const classes = useStyles()

  return (
    <>
      <Box className={style.newConversation}>
        <Header
          label={t(props.conversation ? 'editConversation' : 'newQuery')}
          icon={close}
          onClick={props.handleClose}
        />
        <Box style={{ margin: '10px' }}>
          <h3 style={{ marginTop: '10px' }}>{t('circleRelativeTeleQuery')}</h3>
          <RadioGroup
            style={{ marginLeft: '15px' }}
            aria-label="gender"
            name="gender1"
            value={circleConversation}
            onChange={(event) => {
              setCircleConversation(event.target.value)
              // props.handleIntensityCrisisType(event.target.value)
            }}>
            {selectedUserCircle &&
              userCircles
                .filter((f) => f.circle.id === selectedUserCircle.circle.id)
                .map((p, i) => {
                  // if (selectedUserCircle.circle.id !== p.circle.id) return <></>
                  return (
                    <FormControlLabel
                      key={p.id}
                      sx={{
                        label: classes.label, // Pass your override css here
                      }}
                      value={t(p.circle.name)}
                      control={<Radio />}
                      label={t(p.circle.name)}
                      onClick={() => {
                        setUserCircleSelected(userCircles[i])
                      }}
                    />
                  )
                })}
          </RadioGroup>
        </Box>
        <Box style={{ margin: '10px' }}>
          {queryTypes.count > 0 ? (
            <>
              {AcceptQuery?.acceptConsulta === 0 ? (
                <Box>
                  <Alert severity="warning">{AcceptQuery?.message}</Alert>
                </Box>
              ) : (
                <>
                  <h3 style={{ marginTop: '10px' }}>{t('reasonOfInquiry')}</h3>
                  <RadioGroup
                    style={{ marginLeft: '15px' }}
                    aria-label="gender"
                    name="gender1"
                    value={reasonOfInquiry}
                    onChange={(event) => {
                      setReasonOfInquiry(event.target.value)
                    }}>
                    {selectedUserCircle &&
                      queryTypes.items.map((q) => (
                        <FormControlLabel
                          key={q.id}
                          sx={{
                            label: classes.label,
                          }}
                          value={t(q.name)}
                          control={<Radio />}
                          label={t(q.name)}
                          onClick={() => {
                            if (q?.id) {
                              queryID.current = q.id
                            }
                          }}
                        />
                      ))}
                  </RadioGroup>
                </>
              )}
            </>
          ) : (
            <>{userCircleSelected && <p>No hay consultas asociadas a ese círculo</p>}</>
          )}
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: window.innerWidth < 598 ? 'center' : 'flex-end',
          }}
          width={'100%'}>
          <Box style={{ margin: 8 }}>
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={props.handleClose}
            />
          </Box>
          <Box style={{ margin: 8 }}>
            <AppButton
              disabled={
                AcceptQuery?.acceptConsulta === 0 || queryTypes.count <= 0 || !reasonOfInquiry
              }
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('next')}
              handler={(e) => {
                if (reasonOfInquiry === t('Crisis epiléptica')) {
                  setNextForm(true)
                } else {
                  saveConversation(e)
                }
              }}
            />
          </Box>
        </Box>
      </Box>
      {nextForm && reasonOfInquiry === t('Crisis epiléptica') && (
        <Modal
          className={style.modalNewConversation}
          open={nextForm}
          onClose={() => setNextForm(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <ModalEpilepticCrisis
            handleCloseEpilepticCrisisModal={handleCloseEpilepticCrisisModal}
            saveConversation={saveConversation}
            message={message}
            handleMessage={handleMessage}
            handleObject={handleObject}
            object={object}
            errorMessage={error}
          />
        </Modal>
      )}
    </>
  )
}
