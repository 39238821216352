import {
  Box,
  Modal,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material'
import { useEffect, useState } from 'react'
import style from './AppParticipantTable.module.css'
import { Searcher } from 'components/table/Searcher'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import patient from 'assets/role_icons/ico-rol-paciente.svg'
import professionalSMS from 'assets/role_icons/ico-rol-profesional.svg'
import family from 'assets/role_icons/ico-rol-familiar.svg'
import externProfessional from 'assets/role_icons/ico-rol-externo.svg'
import career from 'assets/role_icons/ico-rol-cuidador.svg'
import { useTranslation } from 'react-i18next'
import { UserPending } from 'modules/users/models/UserPending'
import { Participant } from '../Table'
import { Action, Actions, Field, Search, Sort } from 'components/table_type/types'
import { getUserContainer } from 'container/user-module'
import { RolesService } from 'modules/users/services/RolesServices'
import { IUserService, ROLES_SERVICE_KEY, USER_SERVICE_KEY } from 'modules/users'
import { enqueueSnackbar } from 'notistack'
import { Roles } from 'modules/users/enums/Roles'
import { UserPendingDTO } from 'modules/users/models/UserPendingDTO'
import { getUserCircleContainer } from 'container/user-circle-module'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from 'modules/user-circle'
import { CustomModal } from 'components/modal/CustomModal'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

export type UsersTableProps<T, Q> = {
  readonly titleActions: string
  readonly titleNewItems: string
  readonly addButtonLabel: string
  readonly removeButtonLabel: string
  readonly reInviteButtonLabel: string
  readonly titleCaption: string
  readonly items: Participant[]
  readonly newItems?: UserPending[]
  readonly fields: Field<T>[]
  readonly sort?: Sort<T>
  readonly actions?: Actions<T>
  readonly search?: Search<Q>
  readonly autocompleteItems?: string[]
  readonly autocompleteLabel?: string
  readonly autocompleteAction?: Action
  readonly isPreventDefault?: boolean
  handlerAdd: (e?: any) => void
  hasLinkPermission: boolean
}

const roleService = getUserContainer().get<RolesService>(ROLES_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)

export function AppUsersTable<T extends Record<string, any>, Q extends Record<string, any>>(
  props: UsersTableProps<T, Q>
) {
  const { t } = useTranslation()
  const [user, setUser] = useState<Participant>()
  const [selectedPendingUser, setSelectedPendingUser] = useState<UserPending>()
  const [pendingUsers, setPendingUsers] = useState<UserPending[]>(props.newItems ?? [])
  const [participants, setParticipants] = useState<Participant[]>(props.items ?? [])
  const [pendingIcons, setPendingIcons] = useState<(string | undefined)[]>([])
  const [rowsPending, setRowsPending] = useState<JSX.Element[]>([])
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [isPSelected, setIsPSelected] = useState<boolean>(false)
  const { selectedUserCircle } = useCircleConfiguration()
  const isMobile = useMediaQuery('(max-width:599px)')

  // useEffecçt added in order to listen to changes in searchbar and display result accondently
  useEffect(() => {
    setParticipants(props.items)
  }, [props.items])

  const changeUser = (u: Participant) => {
    const toggle = user === u
    setSelectedPendingUser(undefined)
    setUser(toggle ? undefined : u)
    setIsPSelected(!toggle)
  }

  const changePending = (u: UserPending) => {
    const toggle = selectedPendingUser === u
    setSelectedPendingUser(toggle ? undefined : u)
    setUser(undefined)
    setIsPSelected(!toggle)
  }

  const setImgRole = (role: string | undefined) => {
    if (role === undefined) {
      return
    }
    switch (role) {
      case Roles.Patient:
        return patient
      case Roles.FamilyOrTutor:
        return family
      case Roles.Professional:
        return professionalSMS
      case Roles.CarerOrTeacher:
        return career
      case Roles.ProfessionalExtern:
        return externProfessional
    }
  }

  const getNameAndRole = (i: Participant): JSX.Element => {
    const result = getRoleColor(i)
    return (
      <Box>
        <Box>{i.name + ' ' + i.lastname}</Box>
        <Box className={style.chipRoleRow}>
          <Box
            style={{
              color: result.color,
              backgroundColor: result.background,
              padding: '4px 12px',
              borderRadius: '20px',
              fontWeight: 'bold',
            }}
          >
            {i._role ? t(i._role) : ''}
          </Box>
        </Box>
      </Box>
    )
  }

  const getRoleColor = (i: Participant): { color: string; background: string } => {
    let color = ''
    let background = ''
    switch (i._role) {
      case Roles.Patient:
        color = '#ffffff'
        background = '#1462a2'
        break
      case Roles.FamilyOrTutor:
        color = '#1462a2'
        background = '#b1d7ed'
        break
      case Roles.Professional:
        color = '#ffffff'
        background = '#ee8c38'
        break
      case Roles.ProfessionalExtern:
      case Roles.CarerOrTeacher:
      case Roles.ManagerHealthChild:
      case Roles.ManagerEpileptic:
      case Roles.ManagerActivePlan:
        color = '#ffffff'
        background = '#6db3dd'
        break
    }
    return { color, background }
  }

  const headCells = !isMobile ? (
    <TableRow>
      <TableCell className={style.headCell}></TableCell>
      <TableCell className={style.headCell} key={'fullName'}>
        {t('fullName')}
      </TableCell>
      <TableCell className={style.headCell} key={'role'}>
        {t('role')}
      </TableCell>
      <TableCell className={style.headCell} key={'professionalType'}>
        {t('professionalType')}
      </TableCell>
    </TableRow>
  ) : (
    <TableRow>
      <TableCell className={style.headCell} />
      <TableCell className={style.headCell} key={'fullName'} />
      <TableCell className={style.headCell} key={'role'} />
      <TableCell className={style.headCell} key={'professionalType'} />
    </TableRow>
  )

  const headCells2 = !isMobile ? (
    <TableRow>
      <TableCell className={style.headCell}></TableCell>
      <TableCell className={style.headCell} key={'email'}>
        {t('email')}
      </TableCell>
      <TableCell className={style.headCell} key={'email'}>
        {t('status')}
      </TableCell>
      <TableCell className={style.headCell} key={'role'}>
        {t('role')}
      </TableCell>
    </TableRow>
  ) : (
    <TableRow>
      <TableCell className={style.headCell}></TableCell>
      <TableCell className={style.headCell} key={'role'}></TableCell>
      <TableCell className={style.headCell} key={'email'} />
    </TableRow>
  )

  const rows = participants.map((item, index) => {
    const imgrole = setImgRole(item._role)

    const hasLinkPermission = props.hasLinkPermission
    return (
      <TableRow className={style.row} key={index}>
        <TableCell className={style.firtsBodyCell}>
          {hasLinkPermission && (
            <Radio
              checked={user === item}
              onClick={() => {
                changeUser(item)
              }}
            />
          )}
        </TableCell>
        <TableCell className={isMobile ? style.middleBodyCell : style.displayNone}>
          <img src={imgrole} alt={item._role} />
        </TableCell>
        <TableCell className={!isMobile ? style.middleBodyCell : style.displayNone}>
          {item.name} {item.lastname}
        </TableCell>
        <TableCell className={!isMobile ? style.displayNone : style.lastBodyCell}>
          {getNameAndRole(item)}
        </TableCell>
        <TableCell className={isMobile ? style.displayNone : style.middleBodyCell}>
          <img src={imgrole} alt={item._role} />
        </TableCell>
        <TableCell className={!isMobile ? style.lastBodyCell : style.displayNone}>
          {item._professionalType}
        </TableCell>
      </TableRow>
    )
  })

  useEffect(() => {
    setPendingIcons([])
    if (props.newItems) {
      // Mapear los nuevos elementos y suscribirse a las promesas
      const promises = pendingUsers.map(async (item, index) => {
        return await roleService
          .getByID(item.roleId)
          .toPromise()
          .then((role) => {
            return setImgRole(role?.name)
          })
      })

      // Esperar a que todas las promesas se completen
      Promise.all(promises).then((newRows) => {
        // Actualizar el estado con todas las filas completadas
        setPendingIcons(newRows ?? [])
      })
    }
  }, [pendingUsers])

  useEffect(() => {
    const pending = pendingUsers.map((item, index) => {
      if (pendingIcons?.length !== pendingUsers?.length) {
        return <></>
      }
      return (
        <TableRow className={style.row} key={index}>
          <TableCell className={style.firtsBodyCell}>
            <Radio
              key={`row_${index}_radioInput`}
              onClick={() => changePending(item)}
              checked={item.email === selectedPendingUser?.email}
            />
          </TableCell>
          <TableCell className={global.innerWidth < 599 ? style.middleBodyCell : style.displayNone}>
            <img src={pendingIcons[index]} alt={'familiarIcon'} />
          </TableCell>
          <TableCell className={style.middleBodyCell}>
            {global.innerWidth < 599 ? item.email.substring(0, 10) + '...' : item.email}
          </TableCell>
          <TableCell
            className={
              global.innerWidth < 599
                ? [
                    style.lastBodyCell,
                    item.token === 'expired'
                      ? style.statusBodyCellActiveExpired
                      : style.statusBodyCellActive,
                  ].join(' ')
                : item.token === 'expired'
                  ? style.statusBodyCellActiveExpired
                  : style.statusBodyCellActive
            }
          >
            {item.token === 'expired' ? t('expired') : t('active')}
          </TableCell>

          <TableCell className={global.innerWidth < 599 ? style.displayNone : style.lastBodyCell}>
            <img src={pendingIcons[index]} alt={'familiarIcon'} />
          </TableCell>
        </TableRow>
      )
    })
    setRowsPending(pending)
  }, [pendingIcons, selectedPendingUser])

  const handlerDelete = () => {
    if (selectedPendingUser) {
      setPendingUsers((prev) => prev.filter((item) => item.email !== selectedPendingUser.email))
      userService.deleteUserPending(selectedPendingUser.email)
      setSelectedPendingUser(undefined)
      setIsPSelected(false)
      setUser(undefined)
      handleCloseDeleteModal()
    } else if (user) {
      const loggedUser = localStorage.getItem('logged user')
      if (loggedUser) {
        const loggedUserJSON = JSON.parse(loggedUser)
        if (loggedUserJSON.id === user.id) {
          enqueueSnackbar(t('cantDeleteExistingParticipants'), { variant: 'error' })
          setUser(undefined)
          setSelectedPendingUser(undefined)
          setIsPSelected(false)
          handleCloseDeleteModal()
          return
        }
      }
      if (selectedUserCircle) {
        userCircleService.removeParticipant(selectedUserCircle.id, user.id).subscribe((res) => {
          if (res) {
            enqueueSnackbar(t('deleteUserSuccess'), { variant: 'success' })
            setParticipants((prev) => prev.filter((item) => item.id !== user.id))
            setSelectedPendingUser(undefined)
            setUser(undefined)
            setIsPSelected(false)
            handleCloseDeleteModal()
          } else {
            enqueueSnackbar(t('cantDeleteExistingParticipants'), { variant: 'error' })
          }
        })
      }
    }
  }

  const handlerReInvite = () => {
    if (selectedPendingUser) {
      const userAux: UserPendingDTO = {
        email: selectedPendingUser.email,
        token: '',
        circleId: selectedPendingUser.circleId,
        roleId: selectedPendingUser.roleId,
        date: new Date(),
      }
      userService.deleteUserPending(selectedPendingUser.email).subscribe(() => {
        userService
          .invite(
            selectedPendingUser.email,
            selectedPendingUser.circleId,
            selectedPendingUser.roleId
          )
          .subscribe(() => {
            setPendingUsers((prev) => [
              ...prev.filter((item) => item.email !== selectedPendingUser.email),
              new UserPending(userAux),
            ])
            enqueueSnackbar(t('reinviteSuccess'), { variant: 'success' })
          })
      })
      setSelectedPendingUser(undefined)
    }
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true)
  }

  return (
    <>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <CustomModal
          handleClose={handleCloseDeleteModal}
          handleSave={handlerDelete}
          title={t('deleteParticipant')}
          warningText={t('irreversibleParticipantAction')}
        />
      </Modal>
      <Box className={style.containerTableUsers}>
        <Box className={style.containerActions}>
          <p className={style.title}>{props.titleActions}</p>
          <Box className={style.containerSearch}>
            {props.search && (
              <>
                <Box className={style.searchText}>{t('search')}</Box>
                <Searcher
                  search={props.search}
                  autocompleteAction={props.autocompleteAction}
                  autocompleteItems={props.autocompleteItems}
                  autocompleteLabel={props.autocompleteLabel}
                  isPreventDefault={props.isPreventDefault}
                />
              </>
            )}
          </Box>

          {props.hasLinkPermission && !isMobile && (
            <Box className={style.containerAction}>
              <AppButton
                theme={ButtonTheme.addParticipant}
                type={'button'}
                label={props.addButtonLabel}
                handler={() => props.handlerAdd(participants)}
              />
              {isPSelected && (
                <AppButton
                  theme={ButtonTheme.RemoveGroup}
                  type={'button'}
                  label={props.removeButtonLabel}
                  handler={handleOpenDeleteModal}
                />
              )}
              {selectedPendingUser?.token === 'expired' && (
                <AppButton
                  theme={ButtonTheme.reinviteParticipant}
                  type={'button'}
                  label={props.reInviteButtonLabel}
                  handler={handlerReInvite}
                />
              )}
            </Box>
          )}
        </Box>

        <Box className={style.containerParticipants}>
          <TableContainer>
            <Table className={style.table}>
              <TableHead className={style.head}>{headCells}</TableHead>
              <TableBody>{rows}</TableBody>
            </Table>
          </TableContainer>
          <Box className={style.containerPendings}>
            {props.hasLinkPermission && pendingUsers && pendingUsers.length > 0 && (
              <>
                <p className={style.title}>{props.titleNewItems}</p>
                <TableContainer>
                  <Table className={style.table}>
                    <TableHead className={style.head}>{headCells2}</TableHead>
                    <TableBody>{rowsPending}</TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
          {props.hasLinkPermission && isMobile && (
            <Box className={style.containerAction}>
              <Box className={style.containerAction2}>
                {isPSelected && (
                  <AppButton
                    theme={ButtonTheme.RemoveGroup}
                    type={'button'}
                    label={props.removeButtonLabel}
                    handler={handleOpenDeleteModal}
                  />
                )}
                {selectedPendingUser?.token === 'expired' && (
                  <AppButton
                    theme={ButtonTheme.reinviteParticipant}
                    type={'button'}
                    label={props.reInviteButtonLabel}
                    handler={handlerReInvite}
                  />
                )}
              </Box>

              <AppButton
                theme={ButtonTheme.addParticipant}
                type={'button'}
                label={props.addButtonLabel}
                handler={() => props.handlerAdd(participants)}
                fullWidth={true}
              />
            </Box>
          )}
          <Box className={style.roleContainer}>
            <Box>
              <p>{props.titleCaption}:</p>
            </Box>
            <Box className={style.role}>
              <img src={professionalSMS} alt={t('professionalSMS')} />
              {t('professionalSMS')}
            </Box>
            <Box className={style.role}>
              <img src={patient} alt={t('patient')} />
              {t('patient')}
            </Box>
            <Box className={style.role}>
              <img src={career} alt={t('carer/Teacher')} />
              {t('carer/Teacher')}
            </Box>
            <Box className={style.role}>
              <img src={family} alt={t('family/Tutor')} />
              {t('family/Tutor')}
            </Box>
            <Box className={style.role}>
              <img src={externProfessional} alt={t('externProfessional')} />
              {t('externProfessional')}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
