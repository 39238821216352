import { useContext } from 'react'
import { CalendarEventsContext } from 'common/utils/calendar-events/CalendarEventsContext'

export const useCalendarEvents = () => {
  const context = useContext(CalendarEventsContext)
  if (context === undefined) {
    throw new Error('useCalendarEvents must be used within a CalendarEventContextProvider')
  }
  return context
}
