import { CircleDTO, emptyCircleDTO } from 'modules/circle/models/CircleDTO'
import React, { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'

const SELECTED_PROF_CIRCLE_KEY = 'selected prof circle'

type ProfCircleContextProviderProps = {
  children: ReactNode
}
export type ProfCircleContextProps = {
  selectedProfCircle?: CircleDTO
  setSelectedProfCircle: Dispatch<SetStateAction<CircleDTO | undefined>>
}

export const ProfCircleContext = React.createContext<ProfCircleContextProps>({
  selectedProfCircle: emptyCircleDTO(),
  setSelectedProfCircle: () => {},
})

export const ProfCircleContextProvider: React.FC<ProfCircleContextProviderProps> = ({
  children,
}) => {
  const [selectedProfCircle, setSelectedProfCircle] = useState<CircleDTO | undefined>(undefined)

  // Store in local to restore when reload
  useEffect(() => {
    loadProfCircle()
  }, [])

  useEffect(() => {
    saveProfCircle()
  }, [selectedProfCircle])

  const saveProfCircle = () => {
    if (selectedProfCircle) {
      localStorage.setItem(SELECTED_PROF_CIRCLE_KEY, JSON.stringify(selectedProfCircle))
    } else {
      localStorage.removeItem(SELECTED_PROF_CIRCLE_KEY)
    }
  }

  const loadProfCircle = () => {
    const local = JSON.parse(localStorage.getItem(SELECTED_PROF_CIRCLE_KEY) ?? 'null') as CircleDTO

    if (local) {
      setSelectedProfCircle(local)
    }
  }

  return (
    <ProfCircleContext.Provider
      value={{
        selectedProfCircle,
        setSelectedProfCircle,
      }}
    >
      {children}
    </ProfCircleContext.Provider>
  )
}

// custom hook creation for the momment, move later on
export const useProfCircle = () => {
  const context = useContext(ProfCircleContext)
  if (context === undefined) {
    throw new Error('useCircleConfiguration must be used within a NewCircleConfigContextProvider')
  }
  return context
}
