import { ChangeEvent, useEffect, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import style from './Searcher.module.css'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

type SearcherProps = {
  handler: (search: string) => void
  reverse: () => void
  handleNewConversation?: () => void
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function Searcher(props: SearcherProps): JSX.Element {
  const { t } = useTranslation()
  const { selectedUserCircle } = useCircleConfiguration()
  const [search, setSearch] = useState<string>('')
  const loggedUser = loggedUserService.get()
  /* const [descToAsc, setDescToAsc] = useState<boolean>(false) */

  const handlerChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearch(event.target.value)

  useEffect(() => {
    props.handler(search)
  }, [search])

  /* const handleFilterChange = () => {
    props.reverse()
    setDescToAsc(!descToAsc)
  } */

  const isProfessional = (): boolean => {
    if (!loggedUser || !selectedUserCircle) return false
    if (
      typeof selectedUserCircle.profSmsID === 'string' &&
      selectedUserCircle.profSmsID === loggedUser.id
    ) {
      return true
    }
    return selectedUserCircle.profSmsID.includes(loggedUser?.id ?? '')
  }

  return (
    <>
      <Box className={style.inputBox}>
        <TextField
          className={style.inputSearcher}
          label={t('filterByConversation')}
          variant={'outlined'}
          type="text"
          value={search}
          onChange={handlerChange}
        />
      </Box>

      <div
        className={style.addNewConversationContainer}
        onClick={props.handleNewConversation}
        style={{
          visibility: !isProfessional() ? 'visible' : 'hidden',
          zIndex: 0,
        }}
      ></div>
    </>
  )
}
