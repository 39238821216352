import { Box } from '@mui/material'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { Link, useNavigate } from 'react-router-dom'
import style from './Configuration.module.css'

type ConfigurationItemProps = {
  route: string
  label: string
  imgSrc: string
  addSeparator?: boolean
  isMobile: boolean
}

export function ConfigurationItem(props: ConfigurationItemProps): JSX.Element {
  const navigate = useNavigate()

  return props.isMobile ? (
    <AppButton
      label={props.label}
      theme={ButtonTheme.NewPrimary}
      handler={() => {
        navigate(props.route)
      }}
      type={'button'}
      fullWidth={true}
    />
  ) : (
    <>
      <Link className={style.configurationLink} to={props.route} id={props.route}>
        <Box className={style.configurationItem} display="flex" alignItems="center">
          <img className={style.icon} src={props.imgSrc} alt={props.imgSrc} />
          <h3 className={style.configurationItemLabel}>{props.label}</h3>
        </Box>
      </Link>
      {props.addSeparator ? <hr className={style.configurationSeparator} /> : null}
    </>
  )
}
