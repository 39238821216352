import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Query, QueryParam } from 'common/api/Query'
import { Notification as N } from 'modules/notifications/models/Notification'
import { TransportType } from 'common/enums/TransportType'
import { getNotificationContainer } from 'container/notification-module'
import { NotificationService } from 'modules/notifications/services/NotificationService'
import { MESSAGES_SERVICE_KEY, NOTIFICATION_SERVICE_KEY } from 'modules/notifications'
import { Message } from 'modules/notifications/models/Message'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { forkJoin, Observable } from 'rxjs'
import { MessageService } from 'modules/notifications/services/MessageService'
import { SourceType } from 'modules/notifications/enums/SourceType'
import trainingIcon from 'assets/dashboard/trainingIcon.svg'
import formIcon from 'assets/dashboard/formIcon.svg'
import resourcesIcon from 'assets/dashboard/resourceIcon.svg'
import calendarIcon from 'assets/dashboard/calendarIcon.svg'
import chatIcon from 'assets/dashboard/chatIcon.svg'
import libraryIcon from 'assets/dashboard/libraryIcon.svg'
import symptomIcon from 'assets/dashboard/symptomIcon.svg'
import treatmentIcon from 'assets/dashboard/treatmentIcon.svg'
import style from './NotificationCard.module.scss'
import { listItemTextStyle } from './NotificationsCardStyleMui'
import { useNavigate } from 'react-router-dom'
import {
  ROUTE_CALENDAR,
  ROUTE_MESSENGER,
  ROUTE_PATIENT_FORMS,
  ROUTE_PATIENT_SYMPTOMS,
  ROUTE_QUERY,
  ROUTE_RESOURCES,
  ROUTE_TREATMENTS,
  ROUTE_ARTICLES,
  ROUTE_ARTICLES_FORM,
  ROUTE_SEGMENTED_NOTIFICATIONS,
  ROUTE_USERS,
  ROUTE_ACTIVE_PLAN_DATA,
} from 'routes/routes-constants'
import { DeliveryStatus } from 'modules/notifications/enums/DeliveryStatus'
import { useProfCircle } from 'common/utils/prof-circle-context/ProfCircleContext'

type NotificationsCardProps = {
  ProfessionalNotifications: boolean
  SetNumberNewResources: (number: number) => void
}
const notificationsPerPage = 7
const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const messageService = notificationContainer.get<MessageService>(MESSAGES_SERVICE_KEY)

export const NotificationsCard: React.FC<NotificationsCardProps> = (props): JSX.Element => {
  const loggedUser = loggedUserService.get()
  const [notifications, setNotifications] = useState<N[]>([])
  const [notificationMessages, setNotificationMessages] = useState<Map<string, Message>>(new Map())
  const { selectedProfCircle } = useProfCircle()
  const navigate = useNavigate()

  const [page] = useState<number>(1)

  const getMessages = (ids: string[]): Observable<Message[]> =>
    forkJoin(ids.map((id) => messageService.getByID(id))) as unknown as Observable<Message[]>

  useEffect(() => {
    if (!loggedUser) return
    notificationService
      .getFilteredList(
        new Query({
          pager: { offset: (page - 1) * notificationsPerPage, limit: notificationsPerPage },
          query: [
            new QueryParam<N>('recipientID', loggedUser?.id),
            new QueryParam<N>('userCircleID', selectedProfCircle?.id ?? ''),
            new QueryParam<N>('transportType', TransportType.App),
          ],
          sort: [{ field: 'sendAt', desc: true }],
        })
      )
      .subscribe((res) => {
        // seteo de numero de notificaciones de nuevos recursos para el numeric stats card de abajo del dashboard
        const newFiles = res.items.filter((n) => n.sourceType === SourceType.FilesNewCreated)
        props.SetNumberNewResources(newFiles.length)
        const aux = res.items.sort(
          (a, b) => new Date(b.lastSendAt).getTime() - new Date(a.lastSendAt).getTime()
        )
        setNotifications(aux)
        const notificationMessagesTmp = new Map()

        getMessages(res.items.map((n) => n.message)).subscribe((ml) => {
          ml.forEach((m, i) => {
            notificationMessagesTmp.set(res.items[i].id, m)
          })
          setNotificationMessages(new Map(notificationMessagesTmp))
        })
      })
  }, [])
  console.log(notificationMessages)

  const handleOnClick = (notification: N) => {
    //  debugger;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const message = notificationMessages.find((obj) => {
      return obj.id === notification.message
    })
    // let message = Object.values(notificationMessages).find(obj => {return obj.id === notification.message})
    if (notification.deliveryStatus !== DeliveryStatus.Viewed) {
      // Actualiza el estado de entrega a "Visto"
      notification.deliveryStatus = DeliveryStatus.Viewed
      // Llama al servicio de notificaciones para actualizar el estado
      notificationService.update(notification).subscribe()
    }
    switch (notification.sourceType) {
      case SourceType.CalendarEventCreated:
      case SourceType.CalendarEventUpdated:
        navigate(ROUTE_CALENDAR)
        break
      case SourceType.FilesNewCreated:
        navigate(ROUTE_RESOURCES)
        break
      case SourceType.ContentNewArticle:
        if (!notification.segmentedID) {
          navigate(`${ROUTE_ARTICLES}/${message.url}`)
        } else {
          navigate(`${ROUTE_SEGMENTED_NOTIFICATIONS}/${notification.segmentedID}`)
        }
        break
      case SourceType.ContentArticleRejected:
        // navigate(ROUTE_MY_POSTS_CONTENT)
        navigate(`${ROUTE_ARTICLES_FORM}/${message.url}`)
        break
      case SourceType.ContentArticleValidated:
        // navigate(ROUTE_MY_POSTS_CONTENT)
        navigate(`${ROUTE_ARTICLES}/${message.url}`)
        break
      case SourceType.FormsNewUserForm:
        navigate(ROUTE_PATIENT_FORMS)
        break
      case SourceType.FormsUserFormFilled:
        navigate(ROUTE_PATIENT_FORMS)
        break
      case SourceType.PatientSymptomCreated:
        navigate(ROUTE_PATIENT_SYMPTOMS)
        break
      case SourceType.TreatmentCreated:
        navigate(ROUTE_TREATMENTS)
        break
      case SourceType.TreatmentReminder:
        navigate(ROUTE_TREATMENTS)
        break
      case SourceType.NewRegisteredUser:
        navigate(ROUTE_USERS)
        break
      case SourceType.NewReport:
        navigate(ROUTE_ACTIVE_PLAN_DATA)
        break
      case SourceType.QueryMessageCreated:
        navigate(ROUTE_QUERY)
        break
      /* case SourceType.ActivePlanCreated:
    let ROUTE_ACTIVE_PLAN;
    navigate(ROUTE_ACTIVE_PLAN)
    break */
      case SourceType.MessengerMessageCreated:
        navigate(ROUTE_MESSENGER)
        break
      case SourceType.NewSegmentedNotification:
        navigate(ROUTE_SEGMENTED_NOTIFICATIONS)
        break
    }
  }

  console.log('notificationMessages', notificationMessages)
  console.log('notifications', notifications)
  const mappedNotifications = () =>
    notifications.map((n) => {
      if (!n.id) {
        return null
      }

      const message = notificationMessages.get(n.id)

      if (!message || message.sourceType === SourceType.MessengerMessageCreated) {
        return null
      }
      const handleClick = () => {
        handleOnClick(n)
      }

      return (
        <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }} key={message.id} onClick={handleClick}>
          <Box width={'100%'}>
            <Box display={'flex'} mr={1} ml={1}>
              <ListItemIcon>{getIcon(message.sourceType, message.title)}</ListItemIcon>
              <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                {message.title}
              </ListItemText>
            </Box>
            <Divider className={style.divider} />
          </Box>
        </ListItem>
      )
    })

  const getIcon = (type: SourceType, id: string): JSX.Element => {
    switch (type) {
      case SourceType.CalendarEventCreated:
      case SourceType.CalendarEventUpdated:
        return <img className={style.icon} src={calendarIcon} alt={calendarIcon} />
      case SourceType.FilesNewCreated:
        return <img className={style.icon} src={resourcesIcon} alt={resourcesIcon} />
      case SourceType.ContentNewArticle:
        if (!id.includes('Una nueva notificación')) {
          return <img className={style.icon} src={libraryIcon} alt={libraryIcon} />
        } else {
          return <img className={style.icon} src={chatIcon} alt={chatIcon} />
        }
      case SourceType.ContentArticleRejected:
        return <img className={style.icon} src={libraryIcon} alt={libraryIcon} />
      case SourceType.ContentArticleValidated:
        return <img className={style.icon} src={libraryIcon} alt={libraryIcon} />
      case SourceType.FormsNewUserForm:
        return <img className={style.icon} src={formIcon} alt={formIcon} />
      case SourceType.FormsUserFormFilled:
        return <img className={style.icon} src={formIcon} alt={formIcon} />
      case SourceType.PatientSymptomCreated:
        return <img className={style.icon} src={symptomIcon} alt={symptomIcon} />
      case SourceType.TreatmentCreated:
        return <img className={style.icon} src={treatmentIcon} alt={treatmentIcon} />
      case SourceType.TreatmentReminder:
        return <img className={style.icon} src={treatmentIcon} alt={treatmentIcon} />
      case SourceType.NewRegisteredUser:
        return <img className={style.icon} src={libraryIcon} alt={libraryIcon} />
      case SourceType.QueryMessageCreated:
        return <img className={style.icon} src={chatIcon} alt={chatIcon} />
      case SourceType.NewSegmentedNotification:
        return <img className={style.icon} src={chatIcon} alt={chatIcon} />
      case SourceType.ActivePlanCreated:
        return <img className={style.icon} src={trainingIcon} alt={trainingIcon} />
      case SourceType.NewReport:
        return <img className={style.icon} src={trainingIcon} alt={trainingIcon} />
    }
    return <></>
  }

  return (
    <DashboardSectionCard label={'notifications'}>
      <List className={style.containerList}>
        {notifications.length > 0 && mappedNotifications()}
      </List>
    </DashboardSectionCard>
  )
}
