import { TransportType } from 'common/enums/TransportType'
import { PriorityLevel } from '../enums/PriorityLevel'
import { DeliveryStatus } from '../enums/DeliveryStatus'
import { SourceType } from '../enums/SourceType'
import { NotificationWithMessage } from './NotificationWithMessage'
import { Message } from './Message'

export interface NotificationWithMessageDTO {
  id: string | undefined
  segmentedID: string | undefined
  senderID: string
  recipientID: string
  userCircleID: string
  transportType: TransportType
  sourceType: SourceType
  message: Message
  createdAt: Date
  sendAt: Date
  priorityLevel: PriorityLevel
  deliveryStatus: DeliveryStatus
  endDate: Date
  frequency: number
  lastSendAt: Date
}

export function fromModel(n: NotificationWithMessage): NotificationWithMessageDTO {
  return {
    id: n.id,
    segmentedID: n.segmentedID,
    senderID: n.senderID,
    recipientID: n.recipientID,
    userCircleID: n.userCircleID,
    transportType: n.transportType,
    sourceType: n.sourceType,
    message: n.message,
    createdAt: n.createdAt,
    sendAt: n.sendAt,
    priorityLevel: n.priorityLevel,
    deliveryStatus: n.deliveryStatus,
    endDate: n.endDate,
    frequency: n.frequency,
    lastSendAt: n.lastSendAt,
  }
}

export function toModel(d: NotificationWithMessageDTO): NotificationWithMessage {
  return new NotificationWithMessage({
    userCircleID: d.userCircleID,
    createdAt: new Date(d.createdAt),
    deliveryStatus: d.deliveryStatus,
    id: d.id,
    segmentedID: d.segmentedID,
    message: d.message,
    priorityLevel: d.priorityLevel,
    recipientID: d.recipientID,
    sendAt: new Date(d.sendAt),
    senderID: d.senderID,
    transportType: d.transportType,
    sourceType: d.sourceType,
    endDate: d.endDate,
    frequency: d.frequency,
    lastSendAt: d.lastSendAt,
  })
}
