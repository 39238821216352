/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Query, QueryParam } from '../../common/api/Query'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Card, Typography } from '@mui/material'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { File, FileQuery } from 'modules/files/models/File'
import { downloadFile, viewImageFile } from 'common/utils/file'
import style from './Search.module.css'
import { Article } from '../../modules/content/models/Article'
import { ArticleStatus } from '../../modules/content/enums/ArticleStatus'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { VisualizationService } from '../../modules/content/services/VisualizationService'
import { VISUALIZATIONS_SERVICE_KEY } from '../../modules/content/container'
import { Pager } from '../../components/table_type/types'
import { getContentContainer } from '../../container/content-module'
import esferaLogo from '../../assets/brand_logo/smsBlue.svg'
import TextField from '@mui/material/TextField'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'

const contentContainer = getContentContainer()
const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const visualizationsService = contentContainer.get<VisualizationService>(VISUALIZATIONS_SERVICE_KEY)

type TutorialProps = {
  search?: string
  mostViewed?: boolean
  categoryList: string[]
  circles: number[]
}

export enum CircleType {
  'Porfiria' = 1234,
  'Diabetes' = 5678,
  'Tensión' = 9123,
  'Niño sano' = 1999,
  'Epilepsia' = 1111,
  'Neurologia' = 1112,
  'Oncología' = 2112,
  'Esguince de tobillo' = 2212,
  'Esguince de rodilla' = 2222,
  'Plan Activa' = 1113,
  'Medicina General' = 1114,
  'Cardiología' = 1115,
  'Cirugía' = 1116,
  'Rehabilitación' = 1117,
}

const imageList = new Map()

export function TableGrid(props: TutorialProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [tutorials, setTutorials] = useState<ItemList<File>>(emptyList<File>())
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [articlesInit, setInitArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [articlesPerPage, setArticlesPerPage] = useState<number>(50)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
  }, [props.categoryList])

  useEffect(() => {
    const articlesQuery = [
      new QueryParam<Article>('type', 'tutorial')
    ]
    if (props.categoryList.length > 0) {
      const aux: [] = []
      // @ts-expect-error
      props.categoryList.forEach((obj) => aux.push(CircleType[obj]))

      articlesQuery.push(new QueryParam<Article>('categories', aux))
    }else{
      articlesQuery.push(new QueryParam<Article>('categories', props.circles))
    }
    articlesService
      .getFilteredList(
        new Query({
          query: articlesQuery,
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        const articleList = emptyList<Article>()

        articleList.count = 0
        for (let i = res.items.length - 1; i >= 0; i--) {
          const articleItem = res.items[i]
          if (props.search !== undefined)
            if (articleItem.title.toLowerCase().includes(props.search.toLowerCase())) {
              articleList.items.push(articleItem)
              articleList.count++
            }

          if (props.mostViewed) {
            new Promise((resolve) => {
              visualizationsService
                .getVisualizationsByArticleID(articleItem.id)
                .subscribe((response) => {
                  if (response && response.count >= 0) {
                    articleItem.visualizations = response.count
                  }
                  if (i === 0) {
                    resolve(true)
                  }
                })
            }).then((resolve) => {
              articleList.items = articleList.items.sort(
                (a, b) => b.visualizations - a.visualizations
              )
              setArticles(articleList)
              setInitArticles(articleList)
              setCount(res.count)
            })
          }

          if (articleItem.slug !== 'slug') {
            // Download the image if exists
            filesService.downloadByID(articleItem.slug).subscribe(async (res) => {
              if (res) {
                const image = await viewImageFile(res)
                imageList.set(articleItem.slug, image)
              }
            })
          }
        }

        if (!props.mostViewed) {
          setArticles(articleList)
          setInitArticles(articleList)
          setCount(res.count)
        }
      })
  }, [props.search, props.categoryList])

  useEffect(() => {
    if (!isLoading) {
      return
    }
    const articlesQuery = [
      new QueryParam<Article>('type', 'tutorial'),
      new QueryParam<Article>('categories', props.circles),
    ]
    if (props.categoryList.length > 0) {
      articlesQuery.push(new QueryParam<Article>('categories', props.categoryList))
    }

    articlesService
      .getFilteredList(
        new Query({
          query: articlesQuery,
          sort: [{ field: 'title' }],
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        const articleList = emptyList<Article>()

        articleList.count = 0
        for (let i = res.items.length - 1; i >= 0; i--) {
          const articleItem = res.items[i]
          debugger;
          if (props.search !== undefined)
            if (articleItem.title.toLowerCase().includes(props.search.toLowerCase())) {
              articleList.items.push(articleItem)
              articleList.count++
            }

          if (props.mostViewed) {
            new Promise((resolve) => {
              visualizationsService
                .getVisualizationsByArticleID(articleItem.id)
                .subscribe((response) => {
                  if (response && response.count >= 0) {
                    articleItem.visualizations = response.count
                  }
                  if (i === 0) {
                    resolve(true)
                  }
                })
            }).then((resolve) => {
              articleList.items = articleList.items.sort(
                (a, b) => b.visualizations - a.visualizations
              )
              setArticles(articleList)
              setInitArticles(articleList)
              setCount(res.count)
            })
          }

          if (articleItem.slug !== 'slug') {
            // Download the image if exists
            filesService.downloadByID(articleItem.slug).subscribe(async (res) => {
              if (res) {
                const image = await viewImageFile(res)
                imageList.set(articleItem.slug, image)
              }
            })
          }
        }
        if (!props.mostViewed) {
          setArticles(articleList)
          setInitArticles(articleList)
          setCount(res.count)
        }
      })
  }, [])

  const handleSee = (event: any, article: Article) => {
    if (
      !event.target.className.includes('like_button') &&
      !event.target.className.includes('comment_button')
    ) {
      navigate(`${ROUTE_ARTICLES}/${article.id}`)
    }
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {/*  eslint-disable-next-line array-callback-return */}
        {articles.items.map((item, index) => {
          let articleImage = null
          if (item.slug !== 'slug') {
            articleImage = imageList.get(item.slug)
          }
          if ((props.mostViewed && index < 4) || !props.mostViewed) {
            return (
              <Grid item xs={12} sm={6} md={3} key={item.id}>
                <Card
                  className={style.card}
                  sx={{ borderRadius: 5, borderColor: '#68b3e0' }}
                  variant="outlined"
                  onClick={(event) => handleSee(event, item)}
                >
                  <div className={style.imageContainer}>
                    <img
                      className={style.thumbnail}
                      src={articleImage === null ? esferaLogo : articleImage}
                    />
                    <div>
                      <Typography variant="h5" color="silver" className={style.title}>
                        {t('tutorial')}
                      </Typography>
                      <Typography variant="h6" color="textPrimary" className={style.title}>
                        {item.title}
                      </Typography>
                    </div>
                  </div>
                </Card>
              </Grid>
            )
          }
        })}
      </Grid>
    </Box>
  )
}
